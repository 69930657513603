import React, { useEffect, useState } from 'react';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import OtpField from '../../components/otp/otp';
import {
    TextField,
    Button,
    InputLabel,
    Select,
    MenuItem,
    FormControl,
    Checkbox,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormLabel,
} from '@mui/material';
import { PaymentGatewayService, LocalService } from '../../core/services';
import { FormValidation } from '../../core/utility';
import { useDispatch, connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClanShopCreateOrder } from 'custom-type';
import { ClanShopService } from '../../core/services/clan.shop.service';
import './user-details.scss';
import EmailIcon from '@mui/icons-material/Email';
import { PublicService } from '../../core/services/public.service';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Methods } from '../../core/utility';
import {
    setGuestUser,
    setGuestUserId,
    setGuestUserEmail,
    updateQuantity,
    setCart,
} from '../../redux/action';
import { SET_CART } from '../../redux/types';
import { useCart } from '../../hooks/useCart';
import { MenuPropsSelect } from '../../core/utility/style';
import * as gtUtil from "../../core/utility"

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const paymentGatewayService = new PaymentGatewayService();
const publicService = new PublicService();
const localService = new LocalService();
const methods = new Methods();
interface Props {
    UserReducer: any;
    loading?: (data: any) => void;
    setGuestUser?: (data: any) => void;
    setGuestUserId?: (data: any) => void;
    setGuestUserEmail?: (data: any) => void;
    isguest_user: any;
    CartReducer: any;
    setCart?: (data: any) => void;
}
interface ErrorTypes {
    status: boolean;
    message: string;
}

const UserDetails: React.FC<Props> = (props: Props) => {
    useCart();
    const formValidation = new FormValidation();
    const [selectedTab, setSelectedTab] = useState(0);
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();
    const [showotpBox, setOtpBox] = useState(false);
    const [showEmailotpBox, setEmailOtpBox] = useState(false);
    const [disableWhatsappfield, setDisableWhatsAppField] = useState(false);
    const [disableEmailfield, setDisableEmailfield] = useState(false);
    const [whatsappnumber, setWhatsAppNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const [addressType, setAddressType] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [pincode, setPincode] = useState('');
    const [pincodeData, setPincodeData] = useState<any>([]);
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [district, setDistrict] = useState('');
    const [postOffice, setPostOffice] = useState('');
    const location = useLocation();
    const [userExsist, setUserExsist] = useState(true);
    const [exsistingUserData, setExsistingUserData] = useState<any>([]);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [addNewAddress, setAddNewAddress] = useState(true);

    //error
    const [errorWhatsApp, setErrorWhatsApp] = useState<ErrorTypes>();
    const [errorName, setErrorName] = useState<ErrorTypes>();
    const [errorEmail, setErrorEmail] = useState<ErrorTypes>();
    const [errorHouseNo, setErrorHouseNo] = useState<ErrorTypes>();
    const [errorArea, setErrorArea] = useState<ErrorTypes>();
    const [errorAddressTitle, setErrorAddressTitle] = useState<ErrorTypes>();
    const [errorAddressType, setErrorAddressType] = useState<ErrorTypes>();
    const [errorPincode, setErrorPincode] = useState<ErrorTypes>();
    const [errorCity, setErrorCity] = useState<ErrorTypes>();
    const [errorPostOffice, setErrorPostOffice] = useState<ErrorTypes>();

    const searchParams = new URLSearchParams(location.search);

    const shop_id = searchParams.get('shop_id');
    const product_id = searchParams.get('product_id');
    const cart = searchParams.get('cart');
    const [cartData, setCartData] = useState<any>([]);
    // const cartData: any = '';
    useEffect(() => {
        const guest_user_login: any =
            localService.getStorageItem('guest_user_login');
        const user_id: any = localService.getStorageItem('user_id');
        const user_email_id: any = localService.getStorageItem('user_email_id');
        // const savedCart = localService.getStorageItem('cart');

        if (guest_user_login && user_id) {
            dispatch(setGuestUser(true));
            dispatch(setGuestUserEmail(user_email_id));
            setUserExsist(false);
            ClanShopService.checkUserExsist(user_id)
                .then((res) => {
                    setExsistingUserData(res?.data?.rows);
                })
                .catch((error) => {
                    setExsistingUserData([]);
                });
        }
    }, [pincodeData, props?.UserReducer]);
    async function createOrder(orderDetail: ClanShopCreateOrder) {
        try {
            const response: any =
                await paymentGatewayService.clanShopCreateOrder(orderDetail);
            return response;
        } catch (error) {
            throw new Error(`CreaterOrder`);
        }
    }

    const handleSendOtpButton = () => {
        if (`${whatsappnumber}`.length === 10) {
            PublicService.clanShopSendOtp({ mobile: +whatsappnumber })
                .then((res: any) => {
                    if (res?.data?.rows?.status) {
                        setOtpBox(res?.data?.rows?.status);
                        setDisableWhatsAppField(res?.data?.rows?.status);
                        toast.success(res?.data?.rows?.message);
                    } else {
                        toast.error(res?.data?.rows?.message);
                    }
                })
                .catch((error: any) => {
                    console.warn(`Otp Response Error :: ${error}`);
                    if (props.loading) {
                        dispatch(props.loading(false));
                    }
                });
        }
    };

    const handleWhatsAppOtp = (otp: any) => {
        if (otp.length === 4) {
            PublicService.verifyOtp({ mobile: +whatsappnumber, otp: otp })
                .then((res) => {
                    if (res.status === gtUtil.Constants.SUCCESS) {
                        setOtpBox(false);
                        setDisableWhatsAppField(true);
                        toast.success(res.info);
                        ClanShopService.checkUserExsist(+whatsappnumber)
                            .then((res) => {
                                if (res?.data?.rows.length > 0) {
                                    const userID = res.data.rows[0]?.id;
                                    const updatedCartData =
                                        props?.CartReducer?.cartList.map(
                                            (item: any) => ({
                                                user_id: res.data.rows[0]?.id,
                                                ...item,
                                            })
                                        );
                                    localService.setStorageItem(
                                        'cart',
                                        JSON.stringify(updatedCartData)
                                    );
                                    ClanShopService.saveCartProduct(
                                        updatedCartData
                                    )
                                        .then((res: any) => {
                                            if (res?.data?.status) {
                                                ClanShopService.getUserCartDetails(
                                                    userID
                                                )
                                                    .then((res) => {
                                                        // console.log(
                                                        //     'USER CART DETAILS',
                                                        //     res
                                                        // );
                                                    })
                                                    .catch((error: any) => {
                                                        console.warn(
                                                            `cart_response :: ${error}`
                                                        );
                                                    });
                                            }
                                        })
                                        .catch((error: any) => {
                                            console.warn(
                                                `cart_response :: ${error}`
                                            );
                                        });
                                    setUserExsist(false);
                                    dispatch(setGuestUser(true));
                                    localService.setStorageItem(
                                        'guest_user_login',
                                        JSON.stringify(true)
                                    );

                                    dispatch(
                                        setGuestUserId(res.data.rows[0]?.id)
                                    );
                                    localService.setStorageItem(
                                        'user_id',
                                        JSON.stringify(res.data.rows[0]?.id)
                                    );
                                    dispatch(
                                        setGuestUserEmail(
                                            res.data.rows[0]?.email
                                        )
                                    );
                                    localService.setStorageItem(
                                        'user_email_id',
                                        JSON.stringify(res.data.rows[0]?.email)
                                    );
                                    setExsistingUserData(res.data.rows);
                                } else {
                                    setUserExsist(true);
                                }
                            })
                            .catch((error) => {
                                setExsistingUserData([]);
                            });
                    } else {
                        toast.error(res.info);
                    }
                    // if (res.status === gtUtil.Constants.SUCCESS) {
                    //     setOtpBox(false);
                    //     setDisableWhatsAppField(true);
                    //     toast.success(res.info)
                    // } else {
                    //     toast.error(res.info);
                    // }
                })
                .catch((error) => {
                    throw new Error('VerifyOtp Error ::: ' + error);
                });
        }
    };

    // Email otp handle
    const handleSendEmailOtpButton = async () => {
        const email_status: any = formValidation.GetEmailControlIsValid(
            `${userEmail}`
        );
        setErrorEmail(email_status);
        if (email_status?.status) {
            PublicService.emailSendOtp({ email: userEmail })
                .then((res: any) => {
                    if (res.status === gtUtil.Constants.SUCCESS) {
                        setEmailOtpBox(res.status === gtUtil.Constants.SUCCESS);
                        setDisableEmailfield(res.status === gtUtil.Constants.SUCCESS);
                        toast.success(res.info);
                    } else {
                        toast.error(res.info);
                    }
                })
                .catch((error: any) => {
                    console.warn(`Otp Response Error :: ${error}`);
                    if (props.loading) {
                        dispatch(props.loading(false));
                    }
                });
        }
    };

    const handleEmailOtp = (otp: any) => {
        if (otp.length === 4) {
            PublicService.emailVerifyOtp({ email: userEmail, otp: otp })
                .then((res) => {
                    if (res.status === gtUtil.Constants.SUCCESS) {
                        setEmailOtpBox(false);
                        setDisableEmailfield(true);
                        toast.success(res.info);
                    } else {
                        toast.error(res.info);
                    }
                    // if (res.status === gtUtil.Constants.SUCCESS) {
                    //     setOtpBox(false);
                    //     setDisableWhatsAppField(true);
                    //     toast.success(res.info)
                    // } else {
                    //     toast.error("Verify Failed.");
                    // }
                })
                .catch((error) => {
                    throw new Error('VerifyOtp Error ::: ' + error);
                });
        }
    };

    const handleWhatsApp = (e: any) => {
        setErrorWhatsApp(
            formValidation.GetMobileControlIsValid(e.target.value)
        );
    };

    const handlePincode = (e: any) => {
        setPincode(e.target.value);
        if (e.target.value.length === 6) {
            publicService
                .getPinCodeDetail(e.target.value)
                .then((res) => {
                    if (res?.status === 200) {
                        const data = res?.data[0]?.PostOffice;
                        setPincodeData(data);
                        setDistrict(res?.data[0]?.PostOffice[0]?.District);
                        setState(res?.data[0]?.PostOffice[0]?.State);
                    }
                })
                .catch((error) => {
                    setPincodeData([]);
                });
        }
    };

    const handleSubmit = (e: any) => {
        if (userExsist) {
            e.preventDefault();

            const form = new FormData(e.target);
            const data = Object.fromEntries(form);
            const storeData: any = { ...data };
            let {
                name,
                whatsapp_number,
                email_id,
                house_no,
                area,
                address_title,
                address_type,
                pincode,
                city,
                post_office,
            } = storeData;
            if (!addNewAddress) {
                whatsapp_number = exsistingUserData[0]?.mobile;
                email_id = exsistingUserData[0]?.email;
                storeData['user_id'] = exsistingUserData[0]?.id;
            }
            storeData['state'] = state;
            storeData['district'] = district;
            storeData['user_exsist'] = userExsist ? false : true;
            const start_time_string = storeData['preferred_time'].split(' to ');
            storeData['preferred_start_time'] = methods.convertTimeToHHMMSS(
                start_time_string[0]
            );
            storeData['preferred_end_time'] = methods.convertTimeToHHMMSS(
                start_time_string[1]
            );
            storeData['new_address_added'] = addNewAddress;

            setErrorName(formValidation.GetNameValidate(`${name}`));
            setErrorWhatsApp(
                formValidation.GetMobileControlIsValid(`${whatsapp_number}`)
            );
            setErrorEmail(formValidation.GetEmailControlIsValid(`${email_id}`));
            setErrorHouseNo(
                formValidation.GetClanShopAddressValid(
                    `${house_no}`,
                    'Flat/house no.'
                )
            );
            setErrorArea(
                formValidation.GetClanShopAddressValid(`${area}`, 'area')
            );
            setErrorAddressTitle(
                formValidation.GetClanShopAddressValid(
                    `${address_title}`,
                    'address title'
                )
            );
            setErrorAddressType(
                formValidation.GetClanShopAddressValid(
                    `${address_type}`,
                    'address type'
                )
            );
            setErrorPincode(formValidation.GetPincodeValid(`${pincode}`));
            setErrorCity(
                formValidation.GetClanShopAddressValid(`${city}`, 'city')
            );
            setErrorPostOffice(
                formValidation.GetClanShopAddressValid(
                    `${post_office}`,
                    'post office'
                )
            );

            if (
                name &&
                whatsapp_number &&
                email_id &&
                name &&
                whatsapp_number &&
                email_id &&
                house_no &&
                area &&
                address_title &&
                address_type &&
                pincode &&
                city &&
                post_office
            ) {
                storeData['mobile_verified'] = 'Yes';
                storeData['email_verified'] = 'Yes';
                ClanShopService.createShopBuyer(storeData)
                    .then((res) => {
                        if (res?.data?.status?.status) {
                            const updatedCartData =
                                props?.CartReducer?.cartList.map(
                                    (item: any) => ({
                                        user_id: res.data.status?.user_id,
                                        ...item,
                                    })
                                );
                            ClanShopService.saveCartProduct(updatedCartData)
                                .then((res: any) => {
                                    // console.log('cart_response', res);
                                })
                                .catch((error: any) => {
                                    console.warn(`cart_response :: ${error}`);
                                });
                            dispatch(setGuestUser(true));
                            localService.setStorageItem(
                                'guest_user_login',
                                JSON.stringify(true)
                            );
                            dispatch(setGuestUserId(res.data.rows[0]?.id));
                            localService.setStorageItem(
                                'user_id',
                                JSON.stringify(res?.data?.status?.user_id)
                            );
                            dispatch(setGuestUserEmail(email_id));
                            localService.setStorageItem(
                                'user_email_id',
                                JSON.stringify(email_id)
                            );
                            // ClanShopService.CreateOrder
                            if (cart) {
                                navigate(
                                    `/clanshop/product-details/place-order?cart=${true}&user_id=${
                                        res?.data?.status?.user_id
                                    }&address_id=${
                                        res?.data?.status?.address_id
                                    }`
                                );
                            } else {
                                navigate(
                                    `/clanshop/product-details/place-order?shop_id=${shop_id}&product_id=${product_id}&user_id=${res?.data?.status?.user_id}&address_id=${res?.data?.status?.address_id}`
                                );
                            }
                        }
                    })
                    .catch((e: any) => console.log(e));
            }
        } else {
            e.preventDefault();
            if (selectedAddress) {
                if (cart) {
                    navigate(
                        `/clanshop/product-details/place-order?cart=${true}&user_id=${
                            exsistingUserData[0]?.id
                        }&address_id=${selectedAddress}`
                    );
                } else {
                    navigate(
                        `/clanshop/product-details/place-order?shop_id=${shop_id}&product_id=${product_id}&user_id=${exsistingUserData[0]?.id}&address_id=${selectedAddress}`
                    );
                }
            } else {
                toast.error('please select any one address before submit');
            }
        }
    };
    const handleEmail = (e: any) => {
        const email = e.target.value;
        // setErrorEmail(formValidation.GetEmailControlIsValid(`${email}`));
        setUserEmail(email);
    };

    const handlePostOffice = (e: any) => {
        setPostOffice(e.target.value);
    };
    const handleSelectedAddress = (address_id: any) => {
        // e.target.value = address_id
        setSelectedAddress(address_id);
    };

    const handleAddNewAddress = () => {
        setUserExsist(true);
        setAddNewAddress(false);
    };
    return (
        <div className='clanshop-user-details'>
            <form onSubmit={handleSubmit}>
                {userExsist ? (
                    <>
                        <h3 className='mb-3'>Add Address and details</h3>

                        <div className='col-lg-12 mb-3'>
                            <TextField
                                size='small'
                                fullWidth
                                className='influencer_personal_info'
                                name='name'
                                type='text'
                                id='outlined-basic'
                                label='Full name'
                                variant='outlined'
                            />
                            <span className='error'>{errorName?.message}</span>
                        </div>
                        {addNewAddress && (
                            <>
                                <div className='col-lg-12 '>
                                    <div className='__wa_column clanshop_wa'>
                                        <TextField
                                            className='w-100 input-style'
                                            type='number'
                                            size='small'
                                            color='primary'
                                            InputProps={{
                                                readOnly: disableWhatsappfield,
                                            }}
                                            onBlur={handleWhatsApp}
                                            name='whatsapp_number'
                                            value={whatsappnumber}
                                            onChange={(e) =>
                                                e.target.value.length <= 10
                                                    ? setWhatsAppNumber(
                                                          e.target.value
                                                      )
                                                    : whatsappnumber
                                            }
                                            id='whatsapp_number_id'
                                            label='WhatsApp No.'
                                            variant='outlined'
                                        />
                                        <span className='error'>
                                            {errorWhatsApp?.message}
                                        </span>
                                        <Button
                                            variant='contained'
                                            onClick={handleSendOtpButton}
                                            disabled={disableWhatsappfield}
                                            className='__button __button-influender clanshopwa_send_otp'
                                            color='success'
                                            startIcon={<WhatsAppIcon />}
                                        >
                                            {' '}
                                            Send OTP{' '}
                                        </Button>
                                        {/* {!dynamicOtp && <span className='error'>Please Verify Mobile No. *</span>} */}
                                        {/* {showotpBox && <span className='countdown'>{countDown}</span>} */}
                                    </div>
                                </div>
                                {showotpBox && (
                                    <OtpField handleOtp={handleWhatsAppOtp} />
                                )}

                                <div className='col-lg-12 mt-3'>
                                    <div className='clanshop_wa'>
                                        <TextField
                                            size='small'
                                            fullWidth
                                            className='influencer_personal_info'
                                            name='email_id'
                                            type='text'
                                            InputProps={{
                                                readOnly: disableEmailfield,
                                            }}
                                            id='outlined-basic'
                                            label='Email'
                                            value={userEmail}
                                            onChange={handleEmail}
                                            variant='outlined'
                                        />
                                        <span className='error'>
                                            {errorEmail?.message}
                                        </span>
                                        <Button
                                            variant='contained'
                                            onClick={handleSendEmailOtpButton}
                                            disabled={disableEmailfield}
                                            className='__button __button-influender clanshopwa_send_otp'
                                            color='success'
                                            startIcon={<EmailIcon />}
                                        >
                                            {' '}
                                            Send OTP{' '}
                                        </Button>
                                        {/* {!dynamicOtp && <span className='error'>Please Verify Mobile No. *</span>} */}
                                        {/* {showotpBox && <span className='countdown'>{countDown}</span>} */}
                                    </div>
                                </div>
                                {showEmailotpBox && (
                                    <OtpField handleOtp={handleEmailOtp} />
                                )}
                            </>
                        )}
                        <div className='col-lg-12 mt-3'>
                            <TextField
                                size='small'
                                fullWidth
                                className='influencer_personal_info'
                                name='house_no'
                                type='text'
                                id='outlined-basic'
                                label='Flat, House no., Building, Company, Apartment'
                                variant='outlined'
                            />
                            <span className='error'>
                                {errorHouseNo?.message}
                            </span>
                        </div>
                        <div className='col-lg-12 mt-3 mb-3'>
                            <TextField
                                size='small'
                                fullWidth
                                className='influencer_personal_info'
                                name='area'
                                type='text'
                                id='outlined-basic'
                                label='Area, Street, Sector, Village'
                                variant='outlined'
                            />
                            <span className='error'>{errorArea?.message}</span>
                        </div>
                        <div className='row'>
                            <div className='col-lg-6 mb-3'>
                                <TextField
                                    size='small'
                                    fullWidth
                                    className='influencer_personal_info'
                                    name='address_title'
                                    type='text'
                                    id='outlined-basic'
                                    label='Address Title'
                                    variant='outlined'
                                />
                                <span className='error'>
                                    {errorAddressTitle?.message}
                                </span>
                            </div>
                            <div className='col-lg-6 mb-3'>
                                <FormControl
                                    size='small'
                                    className='w-100 input-style'
                                >
                                    <InputLabel id='demo-multiple-name-label'>
                                        Address Type
                                    </InputLabel>
                                    <Select
                                        labelId='demo-simple-select-label'
                                        className='input-style'
                                        id='demo-simple-select'
                                        value={addressType}
                                        label='Address Type'
                                        name='address_type'
                                        onChange={(e: any) =>
                                            setAddressType(e.target.value)
                                        }
                                        MenuProps={MenuPropsSelect}
                                    >
                                        <MenuItem value='Home'>Home</MenuItem>
                                        <MenuItem value='Office'>
                                            Office
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                                <span className='error'>
                                    {errorAddressType?.message}
                                </span>
                            </div>
                        </div>
                        <div className='row '>
                            <div className='col-lg-6 mb-3'>
                                <TextField
                                    size='small'
                                    fullWidth
                                    className='influencer_personal_info'
                                    name='landmark'
                                    type='text'
                                    id='outlined-basic'
                                    label='Landmark'
                                    variant='outlined'
                                />
                                <span className='error'>
                                    {errorName?.message}
                                </span>
                            </div>
                            <div className='col-lg-6 mb-3'>
                                <TextField
                                    size='small'
                                    fullWidth
                                    className='influencer_personal_info'
                                    name='pincode'
                                    type='text'
                                    id='outlined-basic'
                                    label='Pincode'
                                    variant='outlined'
                                    value={pincode}
                                    onChange={handlePincode}
                                />
                                <span className='error'>
                                    {errorPincode?.message}
                                </span>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-6 mb-3'>
                                <TextField
                                    size='small'
                                    fullWidth
                                    className='influencer_personal_info'
                                    name='state'
                                    type='text'
                                    id='outlined-basic'
                                    label='State'
                                    variant='outlined'
                                    value={state}
                                    disabled
                                />
                                <span className='error'>
                                    {errorName?.message}
                                </span>
                            </div>
                            <div className='col-lg-6 mb-3'>
                                <TextField
                                    size='small'
                                    fullWidth
                                    className='influencer_personal_info'
                                    name='city'
                                    type='text'
                                    id='outlined-basic'
                                    label='Town/City'
                                    variant='outlined'
                                    //   disabled
                                />
                                <span className='error'>
                                    {errorCity?.message}
                                </span>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-lg-6 mb-3'>
                                <TextField
                                    size='small'
                                    fullWidth
                                    className='influencer_personal_info'
                                    name='district'
                                    type='text'
                                    id='outlined-basic'
                                    label='District'
                                    variant='outlined'
                                    value={district}
                                    disabled
                                />
                                <span className='error'>
                                    {errorName?.message}
                                </span>
                            </div>
                            <div className='col-lg-6 mb-3'>
                                <FormControl fullWidth size='small'>
                                    <InputLabel id='demo-multiple-name-label'>
                                        Post Office
                                    </InputLabel>
                                    <Select
                                        className='custom_category'
                                        labelId='demo-multiple-name-label'
                                        id='demo-multiple-name'
                                        name='post_office'
                                        // multiple
                                        value={postOffice}
                                        onChange={handlePostOffice}
                                        input={
                                            <OutlinedInput label='Post Office' />
                                        }
                                        MenuProps={MenuPropsSelect}
                                    >
                                        {pincodeData?.map((data: any) => {
                                            return (
                                                <MenuItem
                                                    key={data.Name}
                                                    value={data.Name}
                                                >
                                                    {data.Name}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                                <span className='error'>
                                    {errorPostOffice?.message}
                                </span>
                            </div>
                        </div>
                        <p>
                            <Checkbox
                                {...label}
                                defaultChecked
                                name='default_address'
                            />{' '}
                            Make this your default address
                        </p>

                        {/* <p className="mt-3">Preffered Time for delivery </p> */}
                        <FormControl>
                            <FormLabel id='demo-row-radio-buttons-group-label'>
                                Preffered Time for delivery
                            </FormLabel>
                            <RadioGroup
                                defaultValue='9:00 AM to 12:00 PM'
                                row
                                aria-labelledby='demo-row-radio-buttons-group-label'
                                name='preferred_time'
                            >
                                <FormControlLabel
                                    className='preferred-time-label'
                                    value='9:00 AM to 12:00 PM'
                                    control={<Radio />}
                                    label='9:00 AM to 12:00 PM'
                                />
                                <FormControlLabel
                                    className='preferred-time-label'
                                    value='12:00 PM to 4:00 PM'
                                    control={<Radio />}
                                    label='12:00 PM to 4:00 PM'
                                />
                                <FormControlLabel
                                    className='preferred-time-label'
                                    value='4:00 PM to 9:00 PM'
                                    control={<Radio />}
                                    label='4:00 PM to 9:00 PM'
                                />
                            </RadioGroup>
                        </FormControl>
                        {/* <div className="tab-buttons">
          <Button
            className={selectedTab === 0 ? "active" : ""}
            onClick={() => handleTabClick("9:00 AM to 12:00 PM")}
            variant="outlined"
          >
            9:00 AM to 12:00 PM
          </Button>
          <Button
            className={selectedTab === 1 ? "active" : ""}
            onClick={() => handleTabClick("12:00 PM to 4:00 PM")}
            variant="outlined"
          >
            12:00 PM to 4:00 PM
          </Button>
          <Button
            className={selectedTab === 2 ? "active" : ""}
            onClick={() => handleTabClick("4:00 PM to 9:00 PM")}
            variant="outlined"
          >
            4:00 PM to 9:00 PM
          </Button>
        </div> */}
                    </>
                ) : (
                    <>
                        <h3>Choose Address</h3>
                        {exsistingUserData?.map((data: any) => (
                            <div
                                className={
                                    selectedAddress === data.address_id
                                        ? 'selected'
                                        : 'address-card'
                                }
                            >
                                {/* <FormControlLabel
              className="preferred-time-label"
              key={data.address_id}
              value={selectedAddress}
              control={<Radio />}
              label={data.address_title}
            //   checked={selectedAddress}
              onChange={(e:any)=>handleSelectedAddress(data.address_id)}
            />
            </RadioGroup>
            </FormControl> */}
                                <h5>{data.address_title}</h5>
                                <p>
                                    <strong>Name : </strong>
                                    {data?.user_name}
                                </p>
                                <p>
                                    <strong>Address : </strong>
                                    {data?.house_no}, {data?.area},{' '}
                                    {data?.post_office}, {data?.city},{' '}
                                    {data?.state}
                                </p>
                                <p>
                                    <strong>pincode - </strong>
                                    {data?.pincode}
                                </p>
                                <Button
                                    key={data.address_id}
                                    onClick={() =>
                                        handleSelectedAddress(data.address_id)
                                    }
                                    variant='outlined'
                                    //   className={selectedButton === button.id ? "selected" : "unselected"}
                                >
                                    confirm
                                </Button>
                            </div>
                        ))}
                        <Button
                            className='mt-3'
                            variant='outlined'
                            onClick={handleAddNewAddress}
                        >
                            Add New Address
                        </Button>
                    </>
                )}
                <div className='d-flex justify-content-end'>
                    <Button type='submit' variant='outlined' className='mt-3'>
                        Submit
                    </Button>
                </div>
            </form>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        UserReducer: state.UserReducer.UserData,
        isguest_user: state.guest_user,
        CartReducer: state.CartReducer,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        setGuestUser: (data: any) => dispatch(setGuestUser(data)),
        setGuestUserId: (data: any) => dispatch(setGuestUserId(data)),
        setGuestUserEmail: (data: any) => dispatch(setGuestUserEmail(data)),
        setCart: (data: any) => dispatch(setCart(data)),
    };
};

const connectToRedux = connect(
    mapStateToProps,
    mapDispatchToProps
)(UserDetails);

export { connectToRedux as UserDetails };
