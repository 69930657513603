import React from "react";
import { useState, useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { AdminDashboardService } from "../../../../../../core/services/admin-dashboard.service";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { Skeleton, TablePagination } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import '../userbase.scss';
import dayjs from "dayjs";
import { connect } from "react-redux";
import { Loading } from "../../../../../../redux/action";
import { LoadingButton } from "@mui/lab";
import { NavLink } from 'react-router-dom';
import { Grid } from "@mui/material";
import { RolesService } from "../../../../../../core/services/roles.service";
import { LocalService } from "../../../../../../core/services";
import { useNavigate } from 'react-router-dom';
import HeadingStyle from "../../../../../../components/heading-style/heading-style";

const adminDashboardService = new AdminDashboardService();
const rolesService = new RolesService();
const localService: LocalService = new LocalService();

interface Props {
  UserReducer: any;
  UserID: any;
  loading: (data: any) => void;
}

const UserBrief: React.FC<Props> = (props: Props) => {

  const [userBaseData, setUserBaseData] = useState([]);
  const [formData, setFormData] = useState<any>({
    start_date: dayjs().startOf('month').format('YYYY-MM-DD'),
    end_date: dayjs(new Date()).format('YYYY-MM-DD'),
  });

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [dateError, setDateError] = useState("");
  const dateFormat = "YYYY-MM-DD";


  useEffect(() => {
    // CHECK IF USER ALLOWED  ACCESS 
    checkAccessFunction();
  }, []);

  const checkAccessFunction = async () => {
    try {
      const res = await rolesService.checkAccess({ access_roles: ["view_influencer_db", "view_admin_dashboard"], user_role_id: props.UserReducer.user_role_id })
      if (res.data.rows.length > 0) {
        getAdminDashUserbaseBrief(formData);
      }
      else {
        localService.toastify("You are not allowed to access", "error", 1000);

        navigate(`/brand/dashboard`)
      }
    }
    catch (error) {
      console.error('Error fetching getAdminDashData:', error);

    }
  }

  async function getAdminDashUserbaseBrief(formData: any) {
    try {
      setLoading(true);
      const res = await adminDashboardService.getAdminDashUserbaseBrief(formData);
      if (res?.data?.status) {
        setUserBaseData(res.data.rows[0]);
      }
    } catch (error) {
      console.error('Error fetching getAdminDashUserbaseBrief:', error);
    } finally {
      setLoading(false);
    }
  }

  async function handleSearch() {
    try {
      if (formData.start_date > formData.end_date || dayjs(formData.end_date).diff(formData.start_date, 'days') > 365) {
        setDateError("Start date should be less than end date and not more than 1 year gap");
        return;
      } else {
        setDateError("");
        getAdminDashUserbaseBrief(formData);
      }
    }
    catch (error) {
      console.error('Error fetching handleSearch:', error);
    } finally {
    }
  }
  const [page, setPage] = useState(0); // Current page
  const [rowsPerPage, setRowsPerPage] = useState(30); // Rows per page

  // Handle page change
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to first page
  };

  // Calculate the rows to display for the current page
  const rowsToDisplay = userBaseData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <section className="userbrief-main">
      <HeadingStyle
        title='Admin Dashboard'
        className=' px-3 d-block'
      />
      <div className='col-auto nav sub-tabs mb-3'>
        <ul>
          <li className='tab-underline-menu'>
            <NavLink
              className='nav-link campaign-verification-tab'
              to={'/brand/admin-dashboard/userbase'}
            >
              Userbase
            </NavLink>
          </li>
          <li className='tab-underline-menu'>
            <NavLink
              className='nav-link talent-verification-tab'
              to={'/brand/admin-dashboard/userbrief'}
            >
              User Signups with briefs
            </NavLink>
          </li>
        </ul>
      </div>
      {dateError && <span className="error">{dateError}</span>}
      <div className="date_range_search">
        <Grid container spacing={2}>
          <Grid item sm={5} md={5} xs={12} xl={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["StartDate"]}>
                <MobileDatePicker
                  className="w-100"
                  label="start date"
                  format="DD-MM-YYYY"
                  maxDate={dayjs(new Date())}
                  defaultValue={dayjs(formData.start_date)}
                  onChange={(e: any) =>
                    setFormData({
                      ...formData,
                      start_date: e?.format(dateFormat),
                    })
                  }
                  slotProps={{
                    textField: {
                      size: "small",
                    },
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Grid item sm={5} md={5} xs={12} xl={4}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["EndDate"]}>
                <MobileDatePicker
                  className="w-100"
                  format="DD-MM-YYYY"
                  label="end date"
                  maxDate={dayjs(new Date())}
                  defaultValue={dayjs(formData.end_date)}
                  onChange={(e: any) =>
                    setFormData({
                      ...formData,
                      end_date: e?.format(dateFormat),
                    })
                  }

                  slotProps={{
                    textField: {
                      size: "small",
                    },
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>
          <Grid item sm={2} md={2} xs={12} xl={4} className="btn-div">
            <LoadingButton className="btn btn-primary btn-mui" onClick={() => handleSearch()}>Search</LoadingButton>
          </Grid>
        </Grid>
      </div>
      <div className="date_range_data">

        <TableContainer className="date_range_data_table_container" sx={{ maxHeight: 480 }}>
          <Table stickyHeader sx={{ minWidth: 650 }} className="common-table">
            <TableHead className="common-table-header">
              <TableRow className="brief-tr">
                <TableCell rowSpan={2} align="center" style={{ minWidth: '120px', maxWidth: '120px' }}>Date</TableCell>
                <TableCell rowSpan={2} align="center" style={{ minWidth: '100px', maxWidth: '100px' }}>Brands Signup</TableCell>
                <TableCell rowSpan={2} align="center" style={{ minWidth: '100px', maxWidth: '100px' }}>Agencies Signup</TableCell>
                <TableCell rowSpan={2} align="center" style={{ minWidth: '100px', maxWidth: '100px' }}>Influencers Signup</TableCell>
                <TableCell rowSpan={2} align="center" style={{ minWidth: '80px', maxWidth: '90px' }}>Talent Partners Signup</TableCell>
                <TableCell rowSpan={2} align="center" style={{ minWidth: '80px', maxWidth: '80px' }}>Total Signup</TableCell>
                <TableCell rowSpan={2} align="center" style={{ minWidth: '80px', maxWidth: '80px' }}>Briefs Posted</TableCell>
                <TableCell rowSpan={2} align="center" style={{ minWidth: '100px', maxWidth: '100px' }}>Reverts from Influencers on Brief posted on</TableCell>
                <TableCell rowSpan={2} align="center" style={{ minWidth: '100px', maxWidth: '100px' }}>Reverts from Influencers (All Campaigns)</TableCell>
                <TableCell className="border-right-white" colSpan={2} align="center" style={{ minWidth: '180px', minHeight: '54px', height: '54px' }}>Influencer</TableCell>
                <TableCell colSpan={3} align="center" style={{ minWidth: '180px', minHeight: '54px', height: '54px' }}>Brand</TableCell>
                <TableCell rowSpan={2} align="center" style={{ minWidth: '100px', maxWidth: '100px' }}>Total Subscription</TableCell>
                <TableCell rowSpan={2} align="center" style={{ minWidth: '100px', maxWidth: '100px' }}>Total revenue</TableCell>
              </TableRow>
              <TableRow className="brief-tr-inner">
                {/* <TableCell align="center" style={{minWidth: '80px',}}>Monthly</TableCell> */}
                <TableCell align="center" style={{ minWidth: '100px', top: 54 }}>Half-Yearly</TableCell>
                <TableCell className="border-right-white" align="center" style={{ minWidth: '80px', top: 54 }}>Yearly</TableCell>
                <TableCell align="center" style={{ minWidth: '80px', top: 54 }}>Monthly</TableCell>
                <TableCell align="center" style={{ minWidth: '90px', top: 54 }}>Quarterly</TableCell>
                <TableCell align="center" style={{ minWidth: '80px', top: 54 }}>Yearly</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="common-table-body">
              {userBaseData.slice(0, 1).map((data: any, index: number) => (
                <TableRow
                  key={index}
                  hover
                  role="checkbox"
                  className={index === 0 ? 'userbrief-highlighted-row userbrief-fixed-row' : ''}
                  sx={index === 0 ? { position: 'sticky', top: 109, zIndex: 1, backgroundColor: 'white' } : {}}
                >
                  <TableCell align="center">{loading ? <Skeleton animation="wave" /> : dayjs(data.dates).format("DD MMM, YYYY") == 'Invalid Date' ? data.dates : dayjs(data.dates).format("DD MMM, YYYY")}</TableCell>
                  <TableCell align="center">{loading ? <Skeleton animation="wave" /> : data.brand_signup}</TableCell>
                  <TableCell align="center">{loading ? <Skeleton animation="wave" /> : data.agency_signup}</TableCell>
                  <TableCell align="center">{loading ? <Skeleton animation="wave" /> : data.influencer_signup}</TableCell>
                  <TableCell align="center">{loading ? <Skeleton animation="wave" /> : data.talent_signup}</TableCell>
                  <TableCell align="center">{loading ? <Skeleton animation="wave" /> : data.total_signup}</TableCell>
                  <TableCell align="center">{loading ? <Skeleton animation="wave" /> : data.briefs_posted}</TableCell>
                  <TableCell align="center">{loading ? <Skeleton animation="wave" /> : data.total_reverts_on_Brief_posted_on}</TableCell>
                  <TableCell align="center">{loading ? <Skeleton animation="wave" /> : data.total_reverts_of_the_day}</TableCell>
                  {/* <TableCell align="center">{loading ? <Skeleton animation="wave" /> : data.influencer_monthly}</TableCell> */}
                  <TableCell align="center">{loading ? <Skeleton animation="wave" /> : data.influencer_half_yearly}</TableCell>
                  <TableCell align="center">{loading ? <Skeleton animation="wave" /> : data.influencer_annually}</TableCell>
                  <TableCell align="center">{loading ? <Skeleton animation="wave" /> : data.brand_monthly}</TableCell>
                  <TableCell align="center">{loading ? <Skeleton animation="wave" /> : data.brand_quarterly}</TableCell>
                  <TableCell align="center">{loading ? <Skeleton animation="wave" /> : data.brand_annually}</TableCell>
                  <TableCell align="center">{loading ? <Skeleton animation="wave" /> : data.total_subscription}</TableCell>
                  <TableCell align="center">{loading ? <Skeleton animation="wave" /> : data.total_revenue}</TableCell>
                </TableRow>
              ))}
              {rowsToDisplay.slice(1).map((data: any, index: number) => (
                <TableRow
                  key={index}
                  hover
                  role="checkbox"
                  className={index === 0 ? '' : ''}
                >
                  <TableCell align="center">{loading ? <Skeleton animation="wave" /> : dayjs(data.dates).format("DD MMM, YYYY") == 'Invalid Date' ? data.dates : dayjs(data.dates).format("DD MMM, YYYY")}</TableCell>
                  <TableCell align="center">{loading ? <Skeleton animation="wave" /> : data.brand_signup}</TableCell>
                  <TableCell align="center">{loading ? <Skeleton animation="wave" /> : data.agency_signup}</TableCell>
                  <TableCell align="center">{loading ? <Skeleton animation="wave" /> : data.influencer_signup}</TableCell>
                  <TableCell align="center">{loading ? <Skeleton animation="wave" /> : data.talent_signup}</TableCell>
                  <TableCell align="center">{loading ? <Skeleton animation="wave" /> : data.total_signup}</TableCell>
                  <TableCell align="center">{loading ? <Skeleton animation="wave" /> : data.briefs_posted}</TableCell>
                  <TableCell align="center">{loading ? <Skeleton animation="wave" /> : data.total_reverts_on_Brief_posted_on}</TableCell>
                  <TableCell align="center">{loading ? <Skeleton animation="wave" /> : data.total_reverts_of_the_day}</TableCell>
                  {/* <TableCell align="center">{loading ? <Skeleton animation="wave" /> : data.influencer_monthly}</TableCell> */}
                  <TableCell align="center">{loading ? <Skeleton animation="wave" /> : data.influencer_half_yearly}</TableCell>
                  <TableCell align="center">{loading ? <Skeleton animation="wave" /> : data.influencer_annually}</TableCell>
                  <TableCell align="center">{loading ? <Skeleton animation="wave" /> : data.brand_monthly}</TableCell>
                  <TableCell align="center">{loading ? <Skeleton animation="wave" /> : data.brand_quarterly}</TableCell>
                  <TableCell align="center">{loading ? <Skeleton animation="wave" /> : data.brand_annually}</TableCell>
                  <TableCell align="center">{loading ? <Skeleton animation="wave" /> : data.total_subscription}</TableCell>
                  <TableCell align="center">{loading ? <Skeleton animation="wave" /> : data.total_revenue}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30, 50, 100]}
          component="div"
          count={userBaseData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </section>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
    UserID: state.UserID.userID,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserBrief);

export { connectToRedux as UserBrief };