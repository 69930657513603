import { HttpService } from "./https.service";
import { BASE_URL } from "../../config/config";

const http = new HttpService();

export class InstagramDMAutomation {
    async getInfluencerAccounts(data: any) {
        try {
            const path = BASE_URL + `/influencer/instagram/accounts`;
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`getInfluencerAccounts :: ${error}`);
        }
    }

    async connectInstagram(data: any) {
        try {
            const path = BASE_URL + `/influencer/instagram/connect`;
            const response = await http.postRequest(path, data, null);
            return response;
        } catch (error) {
            throw new Error(`getInfluencerAccounts :: ${error}`);
        }
    }
}