import { HttpService } from "./https.service";
import { BASE_URL } from "../../config/config";
import { ResponseType, IsApproved, GenerateInvoice, BrandGetInvoice } from "custom-type";

const http = new HttpService();

export class InvoiceService {
  //----------------------------------------- Influencer Invoice ---------------------------------------------//

  async allowToGenrateInvoice(data: IsApproved): Promise<ResponseType> {
    const path = BASE_URL + "/influencer/approved/creative";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Create Plan Service :: ${error}`);
    }
  }

  async generateInvoice(data: GenerateInvoice): Promise<ResponseType> {
    const path =
      BASE_URL + "/influencer/campaign/includes/paid/invoice/generate";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Create Plan Service :: ${error}`);
    }
  }

  async getGeneratedInvoice(data: any): Promise<ResponseType> {
    const path = BASE_URL + `/influencer/get/invoice?id=${data}`;
    try {
      const response: any = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`Create Plan Service :: ${error}`);
    }
  }

  //Update the invoice (of generateInvoice)
  async updateInvoice(data: any): Promise<ResponseType> {
    const path =
      BASE_URL + "/influencer/campaign/includes/paid/invoice/update";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Update Invoice Error :: ${error}`);
    }
  }

  async sendInvoice(data: any): Promise<ResponseType> {
    const path = BASE_URL + "/influencer/send/invoice";
    try {
      const response = await http.postRequest(path, data, 'file');
      return response;
    } catch (error) {
      throw new Error(`Send Invoice Service  :: ${error}`);
    }
  }


  //----------------------------------------- Brand Payment Details ---------------------------------------------//

  async getAcceptedProposalPaymentDetails(data: BrandGetInvoice): Promise<ResponseType> {
    const path = BASE_URL + `/brand/get/accepted-proposal-payment-details`;
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Create Plan Service :: ${error}`);
    }
  }


  //----------------------------------------- Upload Influencer campaign invoice ---------------------------------------------//

  async uploadCampaignInvoice(data: any): Promise<ResponseType> {
    const path = BASE_URL + '/influencer/campaign-invoice/insert';
    try {
      const response = await http.postRequest(path, data, 'file');
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  //----------------------------------------- Upload Brand proposal receipt ---------------------------------------------//

  async uploadProposalReceipt(data: any): Promise<ResponseType> {
    const path = BASE_URL + '/brand/proposal-receipt/insert';
    try {
      const response = await http.postRequest(path, data, 'file');
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }

  //----------------------------------------- Get Brand proposal receipts ---------------------------------------------//

  async getProposalReceipts(data: { invoice_id: number | string,proposal_id: number | string }): Promise<ResponseType> {
    const path = BASE_URL + `/proposal/get/receipts?proposal_id=${data.proposal_id}&invoice_id=${data.invoice_id}`;
    try {
      const response: any = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`Create Plan Service :: ${error}`);
    }
  }

  //----------------------------------------- Update submit Proposal Receipt Acknowledgement ---------------------------------------------//

  async submitProposalReceiptAck(data: any): Promise<ResponseType> {
    const path = BASE_URL + '/influencer/acknowledge/proposal-receipt';
    try {
      const response = await http.postRequest(path, data, 'file');
      return response;
    } catch (error) {
      throw new Error(`Apply Campaign (proposal) :: ${error}`);
    }
  }
}
