import React, { Fragment, useEffect, useState } from "react";
import MaterialSymbol from "../../../../../../components/icons/material-symbol";

// interface Proposal {
//   name: string;
//   total_price: number;
//   payment_status: string;
//   account_type?: string;
//   account_holder_name?: string;
//   account_number?: string;
//   ifsc_code?: string;
//   address?: string;
//   influencer_id: number;
//   proposal_id: number;
//   file_urls: string;
// }
interface Invoice {
  file_url: string;
  file_name: string;
  total_amount: number;// invoice total amount.
}

interface Proposal {
  total_price: number;
  name: string;
  influencer_id: number;
  proposal_id: number;
  payment_status: string;
  account_type?: string;
  bank_name?: string;
  ifsc_code?: string;
  account_holder_name?: string;
  account_number?: string;
  address?: string;
  micr_code?: string;
  // file_urls: string;
  invoices: Invoice[];
}

// For bank details section
const AccountDetails: React.FC<{ account: Proposal }> = ({ account }) => {
  const [showBankDetails, setShowBankDetails] = useState(false);

  const toggleBankDetails = () => {
    setShowBankDetails((prevState) => !prevState);
  };

  return (
    <Fragment>
      <span
        className="btn-account-det-toggle d-flex align-items-center p-0"
        style={{ cursor: 'pointer', marginBottom: '6px', display: 'block' }}
        onClick={toggleBankDetails}
      >
        Account Type: {account.account_type}
        {/* <i className=`}></i> */}
        <MaterialSymbol className="user-select-none" fontweight="200" iconName={`keyboard_arrow_${showBankDetails ? 'up' : 'down'}`} />
      </span>
      {showBankDetails && (
        <div className="account-det ps-0">
          <span>Account Holder Name: {account.account_holder_name}</span>
          <br />
          <span>Account Number: {account.account_number}</span>
          <br />
          <span>IFSC: {account.ifsc_code}</span>
          <br />
          <span>Bank Address: {account.address}</span>
        </div>
      )}
    </Fragment>
  );
};

export default AccountDetails;