import { HttpService } from "./https.service";
import { BASE_URL } from "../../config/config";
const http = new HttpService();

export class HandlesService {

    async facebookHandles(data:any): Promise<ResponseType> {
        const path = BASE_URL + "/handles/facebook";
        try {
          const response: ResponseType = await http.postRequest(path, data, null);
          return response;
        } catch (error) {
          throw new Error(`facebookHandles Error :: ${error}`);
        }
      }

      async setInstagramPrimaryAccount(data:any): Promise<ResponseType> {
        const path = BASE_URL + "/handles/instagram-set-account";
        try {
          const response: ResponseType = await http.postRequest(path, data, null);
          return response;
        } catch (error) {
          throw new Error(`setInstagramPrimaryAccount Error :: ${error}`);
        }
      }

      async getConnectedAccounts(params:any,account_type?:any): Promise<ResponseType> {
        const path = BASE_URL + `/handles/get-connected-accounts/${params}/${account_type}`;
        try {
          const response: any = await http.getRequest(path, null);
          return response;
        } catch (error) {
          throw new Error(`getConnectedAccounts Error :: ${error}`);
        }
      }

      async disconnectFacebookAccount(data:any): Promise<ResponseType> {
        const path = BASE_URL + `/handles/disconnect-facebook-accounts`;
        try {
          const response: ResponseType = await http.postRequest(path, data, null);
          return response;
        } catch (error) {
          throw new Error(`disconnectFacebookAccount Error :: ${error}`);
        }
      }

      async getConnectedInstagramAccounts(params:any,account_type?:any): Promise<ResponseType> {
        const path = BASE_URL + `/handles/get-connected-instagram-accounts/${params}/${account_type}`;
        try {
          const response: any = await http.getRequest(path, null);
          return response;
        } catch (error) {
          throw new Error(`getConnectedInstagramAccounts Error :: ${error}`);
        }
      }

      async disconnectInstagramAccount(data:any): Promise<ResponseType> {
        const path = BASE_URL + `/handles/disconnect-instagram-accounts`;
        try {
          const response: ResponseType = await http.postRequest(path, data, null);
          return response;
        } catch (error) {
          throw new Error(`disconnectInstagramAccount Error :: ${error}`);
        }
      }

      async googleAuth(data:any): Promise<ResponseType> {
        const path = BASE_URL + `/handles/google-auth`;
        try {
          const response: ResponseType = await http.postRequest(path, data, null);
          return response;
        } catch (error) {
          throw new Error(`googleAuth Error :: ${error}`);
        }
      }

      async signupGoogleAuth(data:any): Promise<ResponseType> {
        const path = BASE_URL + `/handles/signup/google-auth`;
        try {
          const response: ResponseType = await http.postRequest(path, data, null);
          return response;
        } catch (error) {
          throw new Error(`googleAuth Error :: ${error}`);
        }
      }

      async instagramSignupConnect(data:any): Promise<ResponseType>{
        const path = BASE_URL + `/handles/signup/instagram`;
        try {
          const response: ResponseType = await http.postRequest(path, data, null);
          return response;
        } catch (error) {
          throw new Error(`instagramSignupConnect Error :: ${error}`);
        }
      }

      async handleContinueGoogle(data:any) {
        const path = BASE_URL + `/handles/continue-google-auth`;
        try {
          const response = await http.postRequest(path, data, null);
          return response;
        } catch (error) {
          throw new Error(`googleAuth Error :: ${error}`);
        }
      }

      async checkIgHandle(data:any) {
        const path = BASE_URL + `/handles/check-ig-handle`;
        try {
          const response = await http.postRequest(path, data, null);
          return response;
        } catch (error) {
          throw new Error(`googleAuth Error :: ${error}`);
        }
      }

      async getGoogleConnectedAccounts(params:any,account_type?:any): Promise<ResponseType> {
        const path = BASE_URL + `/handles/get-connected-google-accounts/${params}/${account_type}`;
        try {
          const response: any = await http.getRequest(path, null);
          return response;
        } catch (error) {
          throw new Error(`getGoogleConnectedAccounts Error :: ${error}`);
        }
      }

      async disconnectGoogleAccount(data:any): Promise<ResponseType> {
        const path = BASE_URL + `/handles/disconnect-google-accounts`;
        try {
          const response: ResponseType = await http.postRequest(path, data, null);
          return response;
        } catch (error) {
          throw new Error(`disconnectGoogleAccount Error :: ${error}`);
        }
      }
      
      async updateIgPermissions(data:any) {
        const path = BASE_URL + `/handles/ig/updatePermissions`;
        try {
          const response = await http.postRequest(path, data, null);
          return response;
        } catch (error) {
          throw new Error(`updateIgPermissions Error :: ${error}`);
        }
      }

      async updateYtPermissions(data:any) {
        const path = BASE_URL + `/handles/yt/updatePermissions`;
        try {
          const response = await http.postRequest(path, data, null);
          return response;
        } catch (error) {
          throw new Error(`updateYtPermissions Error :: ${error}`);
        }
      }
}