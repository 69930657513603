import React from 'react'
import { Loading } from '../../../redux/action';
import { connect } from 'react-redux';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import Grid from '@mui/material/Grid';
interface Props{
    UserReducer: any;
    loading: (data: any) => void;
    subsGrowthStockChartData:any;
}
const ContentTypeChart:React.FC<Props> = (props: Props) => {
    const processedData = props.subsGrowthStockChartData.map((item:any) => {
      if (item.media_type === 'CAROUSEL_ALBUM') {
        return {
          ...item,
          media_type: 'IMAGE',
        };
      }
      return item;
    });
    
    // Prepare categories (months)
    const months = [...new Set(processedData.map((item:any) => item.month_year))];
    
    // Prepare series data
    const seriesData:any= {
      IMAGE: { counts: [], ers: [] },
      VIDEO: { counts: [], ers: [] },
    };
    
    // Populate series data
    months.forEach(month => {
      const filteredData = processedData.filter((item:any) => item.month_year === month);
      // Initialize counts and ER values
      Object.keys(seriesData).forEach(key => {
        const typeData = filteredData.find((item:any) => item.media_type === key) || { media_type_count: 0, total_er_val: 0 };
        seriesData[key].counts.push(typeData.media_type_count);
        seriesData[key].ers.push(typeData.total_er_val);
      });
    });
    
    // Highcharts options
    const options = {
      chart: {
        type: 'bar',
      },
      title: {
        text: 'Content Type',
        align:'left'

      },
      xAxis: {
        categories: months, // ['Aug 2024', 'Nov 2024', 'Dec 2024']
      },
      yAxis: {
        title: {
          text: 'Count / ER%',
        },
      },
      series: [
        {
          name: 'IMAGE',
          data: seriesData.IMAGE.counts,
        },
        {
          name: 'IMAGE ER%',
          data: seriesData.IMAGE.ers,
        },
        {
          name: 'VIDEO',
          data: seriesData.VIDEO.counts,
        },
        {
          name: 'VIDEO ER%',
          data: seriesData.VIDEO.ers,
        },
      ],
    };
    
  return (
    <>
                  <Grid item xs={12} sm={12} md={12}>
              <div className="run-camp-card mt-5 mb-5">
                <div
                  className="section-header brand-chart sml"
                  style={{ paddingTop: 10, margin: 0 }}
                >
                </div>
                <div className="col-md-12">
                <HighchartsReact
                  highcharts={Highcharts}
                  options={options}
              />
                </div>
              </div>
            </Grid>
    </>
  )
}

const mapStateToProps = (state: any) => {
    return {
      UserReducer: state.UserReducer.UserData,
    };
  };
  
  const mapDispatchToProps = (dispatch: any) => {
    return {
      loading: (data: any) => dispatch(Loading(data)),
    };
  };
  
  const connectToRedux = connect(mapStateToProps, mapDispatchToProps)(ContentTypeChart);
  
  export { connectToRedux as  ContentTypeChart };
  
  