import React, {useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { ChatService, SubscriptionService } from '../../../../../../core/services';
import { ChatToggle, InfluencerList, InfluencerChatComponent } from '../../../../../../components';
import * as Util from '../../../../../../core/utility';
import { CHATINTERVAL } from '../../../../../../config/config';
import '../../../../../../components/chat/scss/_dir.scss';
import { Slide } from '@mui/material';
// important common popup component
import CommonPopUp from '../../../../../../components/popup/common-popup';
// For brand foc campaign payment
import useRazorpay from 'react-razorpay';
import { makeRazorpayPayment } from '../../../../../../core/services/PaymentService';
import UpgradeBrandPopUp from '../../../../../../components/popup/upgrade-brand-popup';
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { BASE_URL_WEB } from '../../../../../../config/config';
import * as gtUtil from "../../../../../../core/utility";

/**
 * ******************************About of Chat Component*********************************
 * This Component Required 
 *  campaign_detail: any; (all Active Campaign Detail Pass in this props)
    infDetails: influencerDetail | null; (Influencer Details Fetch Dynamic according to provide campaign Details)
    userData: any (Get User all User Information in Redux  )
 */

const chatService: ChatService = new ChatService();
const methods: Util.Methods = new Util.Methods();
const subscriptionService: SubscriptionService = new SubscriptionService();

export type InfluencerListType = {
    influencer_id: number;
    sm_id: number;
    account_id: number;
    campaign_id: number;
    proposal_id: number;
    account_name: string;
    platform_type: string;
    profile_image_url: string;
    gcp_profile_image_url: string;
    username: string;
    status: string;
    message_count: number;
};

type influencerDetail = {
    influencer_id: number;
    sm_id: number;
    proposal_id: number;
}

type ChatProps = {
    campaign_detail: any;
    infDetails: influencerDetail | null;
    userData: any
    sm_id?: number | null
    set_sm_id?: () => void;
    getSubscriptionDetail: any;
}

const BrandProposalChat: React.FC<ChatProps> = ({ sm_id = null, set_sm_id, campaign_detail, infDetails, getSubscriptionDetail, ...arg }: ChatProps) => {
    const [influencerList, setInfluencerList] = useState<InfluencerListType[]>([]);
    const [activeInfluencer, setActiveInfluencer] = useState<InfluencerListType | null>(null);
    const [activeInfluencerChat, setActiveInfluencerChats] = useState({});
    const [showActiveInfluencerChat, setShowActiveInfluencerChat] = useState(false);
    const [showInfluencerList, setShowInfluencerList] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [unreadMessagesCount, setUnreadMessageCount] = useState(0);
    const [brandChatintervals, setBrandChatInterval] = useState<any>();

    const [razorpay] = useRazorpay();

    // For foc popup terms and condition checkbox
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isChecked, setChecked] = useState(true);
    const [isTcErrorVisible, setTcErrorVisible] = useState(false);
    // set foc popup and gstin popup state
    const [popUps, setPopUps] = useState({
        openFocModal: false,
        openUpgradeBrandModal: false
    });

    // To handle Foc modal popup open and close
    const handleOpenFocModal = () => setPopUps({ ...popUps, openFocModal: true });

    const [availablePlan, setAvailablePlan] = useState<any>(null);

    // enum set for modal content
    const ModalContent = {
        UPGRADE_BARTER_PROPOSAL_LIMIT: ''
    };

    // enum set for modal title
    const ModalTitle = {
        UPGRADE_BARTER_PROPOSAL_LIMIT: 'Update Proposal Limit'
    };

    // Common modal content and title useState
    const [modalContent, setModalContent] = useState(ModalContent.UPGRADE_BARTER_PROPOSAL_LIMIT);
    const [modalTitle, setModalTitle] = useState(ModalTitle.UPGRADE_BARTER_PROPOSAL_LIMIT);

    // To handle Foc terms and condition checkbox
    const handleCheckboxChange = () => {
        setChecked(!isChecked);
        setTcErrorVisible(false); // Reset error message when the checkbox state changes
    };

    const containerRef = React.useRef<HTMLDivElement>(null);



    useEffect(() => {
        getUnreadMessage();
        // getActiveChat();
        setInterval(() => {
            getUnreadMessage();
        }, (1000 * 60))
    }, []);

    useEffect(() => {
        setActiveInfluencerChats({});
        getActiveChat();
        if (activeInfluencer) {
            setBrandChatInterval(setInterval(() => {
                getActiveChat();
            }, CHATINTERVAL))
            setShowActiveInfluencerChat(true)
        } else if (!activeInfluencer && brandChatintervals) {
            clearInterval(brandChatintervals)
            setShowActiveInfluencerChat(false)
        }
        // Checking for FOC Republish campaign
        if (['barter', 'Barter'].includes(campaign_detail.campaign_type)) {
            getFocCampaignPlanDetails('Upgrade Barter Proposal Limit');
            setModalContent(ModalContent.UPGRADE_BARTER_PROPOSAL_LIMIT);
            setModalTitle(ModalTitle.UPGRADE_BARTER_PROPOSAL_LIMIT);
        }
    }, [activeInfluencer]);


    useEffect(() => {
        if (toggle && activeInfluencer === null) {
            chatService.getChatInfluencerList({ platfrom_type: campaign_detail.campaign_platform, campaignID: campaign_detail.campaign_id, brandName: arg.userData.account_name, accountId: arg.userData.account_id }).then((res) => {
                if (res.data.status === 1) {
                    if (sm_id === null) {
                        setInfluencerList(res.data.rows);
                        setShowInfluencerList(true)
                    } else {
                        const influencer = res.data.rows.find((data: any) => data.sm_id === sm_id);
                        setInfluencerList(res.data.rows);
                        setActiveInfluencer(influencer);
                    }
                } else {
                    setInfluencerList([]);
                    setShowInfluencerList(false)
                }
            })
        } else {
            setInfluencerList([]);
            setShowInfluencerList(false)
        }
    }, [toggle, activeInfluencer]);


    useEffect(() => {
        if (sm_id !== null) {
            handleToggle(true);
        }
    }, [sm_id])


    function getUnreadMessage() {
        chatService.getUnReadMessage({ campaign_id: campaign_detail.campaign_id, influencer_id: arg.userData.influencer_id }).then(res => {
            if (res.status === gtUtil.Constants.SUCCESS) {
                setUnreadMessageCount(res.data.rows[0].unread_message_count);
            } else {
                setUnreadMessageCount(0);
            }
        }).catch((error) => console.log(`message count fetch proposal :: ${error}`))
    }


    function getActiveChat() {
        if (activeInfluencer) {
            chatService.getInfluencerConversion({ proposal_id: activeInfluencer.proposal_id, from: arg.userData.account_name, user_id: arg.userData.user_id, account_id: arg.userData.account_id, influencer_id: arg.userData.influencer_id, sm_id: 0 }).then(res => {

                if (res.status === gtUtil.Constants.SUCCESS) {
                    setActiveInfluencerChats(methods.alignChat(res.data.rows))
                }
            }).catch((err) => console.log(`active influencer chat error in useEffect :: ${err}`))
        }
    }


    function handleToggle(value: boolean) {
        if (!value) {
            setActiveInfluencer(null);
            setActiveInfluencerChats({});
            set_sm_id && set_sm_id();
        };

        setToggle(value)
    };

    function handleSubmitChat(event: any) {
        try {
            event.preventDefault();
            let target: any = event.target;
            let values = Object.fromEntries(new FormData(target)) as { message: string };
            if ((values.message.replace(/\s+/g, '').length > 0)) {
                chatService.insertProposalMessage({
                    proposal_id: activeInfluencer?.proposal_id,
                    comments: values.message,
                    from: arg.userData.brand_name,
                    user_id: arg.userData?.user_id ?? 0,
                    account_id: arg.userData?.account_id ?? 0,
                    influencer_id: arg.userData?.influencer_id ?? 0,
                    sm_id: 0,
                    receiver_influencer_id: activeInfluencer?.influencer_id,
                    // receiver_account_id: activeInfluencer?.account_id
                })
                    .then(res => {
                        getActiveChat();
                        event.target?.reset();
                    })
                    .catch(error => console.log(error))
                    .finally(() => {
                    })
            }

        } catch (err) {
            throw new Error(`handleSubmitChat`)
        }
    }

    const handleBackButton = () => {
        set_sm_id && set_sm_id();
        setActiveInfluencer(null);
    }

    const handleOpenUpgradeBrandModal = (value: boolean) => {
        setPopUps({ ...popUps, openUpgradeBrandModal: true });
    };

    function handleClosePopUps() {
        setPopUps({
            openFocModal: false,
            openUpgradeBrandModal: false
        })
    }

    const hanleSetActiveInfluencer = (value: any) => {
        if (value.foc === 'true' && campaign_detail.campaign_type.toLowerCase().trim() != 'barter') {
            handleOpenUpgradeBrandModal(true);
        }
        else if (value.foc === "true" && campaign_detail.campaign_type.toLowerCase().trim() == 'barter') {
            handleOpenFocModal();
        } else {
            setActiveInfluencer(value)
        }
    }

    // For foc new campaign plan
    async function getFocCampaignPlanDetails(plan_name: string) {
        const { user_id, account_id, account_type, influencer_id } = arg.userData;
        const response = await subscriptionService.getFocCampaignPlanDetail({
            user_id: user_id,
            account_id: account_id,
            user_type: account_type,
            influencer_id: influencer_id,
            plan_name: plan_name
        });
        if (response.data.status.status) {
            //return Only Active Plan Info
            setAvailablePlan(response.data.rows);
        } else {
            // localService.toastify(response.data.status.message, 'info', 1000);
            setAvailablePlan(null);
        }
    }

    // For FOC campaign payment
    async function makePayment(data: any) {
        // let paymantStatus = {};
        setLoading(true);
        try {
            if (!isChecked) {
                setTcErrorVisible(true); // Show error if the checkbox is not checked
            }
            else {
                const paymentResponse = await makeRazorpayPayment(data, { UserReducer: arg.userData, selectedCampaign: campaign_detail }, razorpay);
                if (paymentResponse?.status) {
                    // Payment successful, perform necessary actions
                    // getInfluencerList();
                    // getDeliverableHeader();
                    navigate(`/brand/orders/confirmed?payment_id=${paymentResponse?.paymentId}&campaign_id=${campaign_detail.campaign_id}&campaign_planId=${campaign_detail.plan_id}`);
                    handleClosePopUps();
                } else {
                    // Payment failed, handle it
                    console.log("Payment failed");
                    // localService.toastify(paymentResponse?.message, "error", 1000);
                }
            }
        } catch (error) {
            console.error(`Make Payment Error :: ${JSON.stringify(error)}`);
        } finally {
            setLoading(false);
        }
    }
    return (
        <div className={`proposal-chat `}>
            <div className={`proposal-chat-container ${toggle ? "proposal-chat-active" : ""}`} ref={containerRef}>
                <div className={`${!activeInfluencer ? "" : "d-none"}`}>
                    <InfluencerList
                        campaignDetail={campaign_detail}
                        onChange={(value) => hanleSetActiveInfluencer(value)}
                        influencerslist={influencerList}
                        getSubscriptionDetail={getSubscriptionDetail} />
                </div>


                {
                    toggle && activeInfluencer &&
                    <Slide direction="left" in={showActiveInfluencerChat} mountOnEnter unmountOnExit container={containerRef.current}>
                        <Col className='col-auto'>
                            <InfluencerChatComponent
                                campaign={null}
                                campaignDetail={campaign_detail}
                                chats={activeInfluencerChat}
                                onClick={() => handleBackButton()}
                                influencer={activeInfluencer}
                                userData={arg.userData}
                                onSubmit={handleSubmitChat}
                            />
                        </Col>
                    </Slide>
                }
            </div>
            <ChatToggle messageCount={unreadMessagesCount} onChange={(e) => handleToggle(e)} value={toggle} />
            {popUps.openUpgradeBrandModal && (
                <UpgradeBrandPopUp
                    openUpgradeBrandModal={popUps.openUpgradeBrandModal}
                    onClose={() => handleClosePopUps()}
                    popupMinWidth='630px'
                >
                </UpgradeBrandPopUp>
            )}
            {popUps.openFocModal && (
                <CommonPopUp
                    modalTitle={modalTitle}
                    modalContent={modalContent}
                    openModal={popUps.openFocModal}
                    onClose={() => handleClosePopUps()}
                    // popupMinWidth='630px'
                    popupMaxWidth='600px'
                >
                    <div className="justify-content-between align-items-center mt-1">
                        <div className="">
                            <p className="inline mt-0">
                                {`To view more proposals, a payment of Rs.${availablePlan[0]?.amount} is applicable. This will allow you to view 10 additional proposals.
                                For unlimited access, please check out our `}
                                <a className="btn btn-text-link" target="_blank" href="/brand/settings/subscription">
                                    pricing plans
                                </a>.
                            </p>
                            <div className="form-check">
                                <input
                                    checked={isChecked}
                                    className="form-check-input mt-1"
                                    id="defaultCheck1"
                                    type="checkbox"
                                    onChange={handleCheckboxChange}
                                />
                                <label className="form-check-label" htmlFor="defaultCheck1">
                                    I agree to{' '}
                                    <a className="btn btn-text-link" target="_blank" href={`${BASE_URL_WEB}/terms_conditions`}>
                                        Terms &amp; Conditions
                                    </a>
                                </label>
                                <small className={`text-red ${isTcErrorVisible ? '' : 'd-none'}`}>This field is required.</small>
                            </div>
                        </div>
                        <div className='popup-footer mt-4'>
                            <div className='row'>
                                <div className='col text-end'>
                                    {availablePlan && (
                                        <>
                                            {availablePlan.map((data: any, index: number) => {
                                                return (
                                                    <LoadingButton
                                                        className='btn btn-primary buy-btn'
                                                        loading={loading}
                                                        loadingPosition='start'
                                                        variant='contained'
                                                        onClick={() => makePayment(data)}
                                                    >
                                                        Pay Rs. {data.amount}
                                                    </LoadingButton>
                                                );
                                            })}
                                        </>
                                    )}

                                </div>
                            </div>
                        </div>
                    </div>
                </CommonPopUp>
            )}
        </div>
    );
};


const mapToState = (state: any) => {

    return {
        userData: state.UserReducer.UserData,
        campaign_detail: state.InfSelectCamp.campaign,
        getSubscriptionDetail: state.Subscription.subscription_detail,
    }
};

const connectToRedux = connect(mapToState, null)(BrandProposalChat);
export { connectToRedux as BrandProposalChat }