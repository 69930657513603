import React from 'react'
import { Loading } from "../../../redux/action";
import { connect } from "react-redux";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Grid } from '@mui/material';

interface Props{
  UserReducer: any;
  loading: (data: any) => void;
  postErChart: any;
}

const PostErChart :React.FC<Props> = (props: Props)=>{
  const groupedData = props.postErChart.reduce((acc:any, item:any) => {
    if (!acc[item.month_year]) {
      acc[item.month_year] = { organic_er: 0, paid_er: 0 };
    }
    acc[item.month_year][`${item.post_type}_er`] = item.average_er;
    return acc;
  }, {});
  
  const transformedCategories = Object.keys(groupedData); // Extract month_year
  const organicData = transformedCategories.map(month => groupedData[month].organic_er || 0);
  const paidData = transformedCategories.map(month => groupedData[month].paid_er || 0);
  const totalData = transformedCategories.map(
    month => (groupedData[month].organic_er + groupedData[month].paid_er) / 2 || 0
  );
  
  // Highcharts options
  const options = {
    chart: {
      type: 'column',
    },
    title: {
      text: 'Posts ER',
      align:'left'
    },
    xAxis: {
      categories: transformedCategories, // ["Jul 2024", "Aug 2024"]
      title: {
        text: 'Month',
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Average ER',
      },
    },
    series: [
      {
        name: 'Organic ER',
        data: organicData, // [0, 0.0408]
      },
      {
        name: 'Paid ER',
        data: paidData, // [0.02, 0.05]
      },
      {
        name: 'Total ER',
        data: totalData, // [0.01, 0.0454]
      },
    ],
  };
      
  return (
    <>
        <Grid item xs={12} sm={12} md={12}>
            <div className="run-camp-card mt-5 mb-5">
            <div
                className="section-header brand-chart sml"
                style={{ paddingTop: 10, margin: 0 }}
            >
            </div>
            <div className="col-md-12">
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
            </div>
            </div>
        </Grid>

    </>
  )
}
const mapStateToProps = (state: any) => {
    return {
      UserReducer: state.UserReducer.UserData,
    };
  };
  
  const mapDispatchToProps = (dispatch: any) => {
    return {
      loading: (data: any) => dispatch(Loading(data)),
    };
  };
  
  const connectToRedux = connect(mapStateToProps, mapDispatchToProps)(PostErChart);
  
  export { connectToRedux as PostErChart };
  
  
