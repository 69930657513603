import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useSearchParams, useLocation } from 'react-router-dom';
import { Loading, SelectedCampaign } from '../../../../../../redux/action';
import { ApplyCampaignService } from '../../../../../../core/services';
import './proposals.scss';
import { BrandRejected } from './rejected/rejected';
import IconRejected from '../../../../../../components/icons/icon-rejected';
import IconAccepted from '../../../../../../components/icons/icon-accepted';
import IconNegotiation from '../../../../../../components/icons/icon-negotiation';
import IconShortListed from '../../../../../../components/icons/icon-shortlisted';
import IconReceived from '../../../../../../components/icons/icon-received';
import ReceiptLongRoundedIcon from '@mui/icons-material/ReceiptLongRounded';
import { BrandShortlisted } from './shortlisted/shortlisted';
import { BrandReceived } from './received/received';
import { BrandNegotiating } from './negotiating/negotiating';
import { BrandProposalChat } from './chat';
import { BrandAccepted } from './accepted/accepted';
import eventEmitter from '../../../../../../core/services/event-emitter';
import { AgreementHome } from '../../../../utlities/influencers-agreements/agreements-home';
import * as gtUtil from "../../../../../../core/utility"

interface Props {
    UserReducer: any;
    loading: (data: any) => void;
    selectCampaign: (data: any) => void;
};


const BrandProposal: React.FC<Props> = (props: Props) => {
    const [smId, setSmId] = useState<number | null>(null);
    const [activeTab, setActiveTab] = useState(1);
    const [selectedCampaignStatus, setSelectedCampaignStatus] = useState({
        Accepted: '0',
        Rejected: '0',
        Negotiating: '0',
        Pending: '0',
        Shortlisted: '0'
    });
    const [proposalUnreadValue, setProposalUnreadValue] = useState({
        Accepted: false,
        Rejected: false,
        Negotiating: false,
        Pending: false,
        Shortlisted: false
    });
    const [refreshComponent, setRefreshComponent] = useState<any>(null);
    const searchParams = useSearchParams();
    const getlocation = useLocation();
    const [campiagn_type, setCampiagnType] = useState<any>(null);
    const [unreadProposal, setUnreadProposal] = useState<any>([]);
    const [openAgreementPopup, setOpenAgreementPopup] = useState(false);
    const [lastActiveTab, setLastActiveTab] = useState<number>(1);
    const getSearchParams = searchParams[0].get('campaignID');

    useEffect(() => {
        props.loading(true);
        const getSearchParams = searchParams[0].get('campaignID');
        if (getSearchParams) {
            ApplyCampaignService.getCampaignDetails({
                user_id: props.UserReducer.user_id,
                account_id: props.UserReducer.account_id,
                campaign_id: getSearchParams,
            })
                .then((res: any) => {
                    if (res.data.status.status) {
                        if (res.data.rows.length > 0) {
                            setCampiagnType(res.data.rows[0].campaign_type)
                        }
                    }
                })
                .catch((error) => {
                    setCampiagnType(null);
                    // props.loading(false);
                }).finally(() => props.loading(false));
        }
    }, [refreshComponent, searchParams[0].get('campaignID'), getlocation]);

    useEffect(() => {
        props.loading(true);
        const getSearchParams = searchParams[0].get('campaignID');
        if (getSearchParams) {
            ApplyCampaignService.brandCampaignStatus({
                user_id: props.UserReducer.user_id,
                account_id: props.UserReducer.account_id,
                campaign_id: getSearchParams,
            })
                .then((res: any) => {
                    let temp: any = {
                        Accepted: '0',
                        Rejected: '0',
                        Negotiating: '0',
                        Pending: '0',
                        Shortlisted: '0'

                    };
                    if (res.data.status.status) {
                        for (let data of res.data.rows) {
                            temp[data.status] = data.counts;
                        }
                    }

                    setSelectedCampaignStatus(temp);
                    // props.loading(false);
                })
                .catch((error) => {
                    setSelectedCampaignStatus({
                        Accepted: '0',
                        Rejected: '0',
                        Negotiating: '0',
                        Pending: '0',
                        Shortlisted: '0'

                    });
                    // props.loading(false);
                }).finally(() => props.loading(false));
        }
    }, [refreshComponent, searchParams[0].get('campaignID'), getlocation]);

    useEffect(() => {
        getUnreadProposals();
    }, [refreshComponent, searchParams[0].get('campaignID'), getlocation])

    const getUnreadProposals = async () => {
        const getSearchParams = searchParams[0].get('campaignID');
        if (getSearchParams) {
            ApplyCampaignService.getUnreadProposalCreative({
                user_id: props.UserReducer.user_id,
                account_id: props.UserReducer.account_id,
                campaign_id: getSearchParams,
            })
                .then((res: any) => {
                    if (res.status === gtUtil.Constants.SUCCESS) {
                        setUnreadProposal(res.data.rows.unread_proposals);
                        let tempStatus: any = {
                            Accepted: false,
                            Rejected: false,
                            Negotiating: false,
                            Pending: false,
                            Shortlisted: false
                        };
                        if (res.data.rows.unread_proposals.length > 0) {
                            for (let data of res.data.rows.unread_proposals) {
                                tempStatus[data.status] = true;
                            }
                        }

                        setProposalUnreadValue(tempStatus);

                        if (res.data.rows.unread_proposals.length == 0) {
                            eventEmitter.emit('callUnreadProposalsCreatives');
                        }

                    }
                })
                .catch((error) => {
                    setProposalUnreadValue({
                        Accepted: false,
                        Rejected: false,
                        Negotiating: false,
                        Pending: false,
                        Shortlisted: false
                    })
                    setUnreadProposal([]);
                }).finally(() => { });
        }
    }
    {/*........ Agreement Popup Open Close Function........*/}
    const handleOpenAgreementPopup = () => {
        setOpenAgreementPopup(true);
        setLastActiveTab(activeTab);
        setActiveTab(6); 
    };

    const handleCloseAgreementPopup = () => {
        setOpenAgreementPopup(false);
        setActiveTab(lastActiveTab);
    };
    {/*....................................................*/}


    return (

        <div className='brand_proposal'>
            <BrandProposalChat infDetails={null} sm_id={smId} set_sm_id={() => setSmId(null)} />
            <div className='proposals_tool'>
                <ul className=''>
                    <li
                        onClick={() => {
                            setActiveTab(1);
                        }}
                        className={`${activeTab === 1 ? 'active' : ''}`}
                    >
                        <span className='proposals_tool_icon'>
                            <IconReceived />
                        </span>
                        <span className='proposals_tool_text'>
                            Received&nbsp;({parseInt(selectedCampaignStatus.Pending)})
                        </span>
                        {proposalUnreadValue.Pending && <span className='unread-notification-icon'></span>}
                    </li>
                    <li
                        onClick={() => {
                            setActiveTab(2);
                        }}
                        className={`${activeTab === 2 ? 'active' : ''}`}
                    >
                        <span className='proposals_tool_icon'>
                            <IconShortListed />
                        </span>
                        <span className='proposals_tool_text'>
                            Shortlisted&nbsp;({parseInt(selectedCampaignStatus.Shortlisted)})
                        </span>
                        {proposalUnreadValue.Shortlisted && <span className='unread-notification-icon'></span>}
                    </li>
                    {campiagn_type && campiagn_type != 'Barter' &&
                        <li
                            onClick={() => {
                                setActiveTab(3);
                            }}
                            className={`${activeTab === 3 ? 'active' : ''}`}
                        >
                            <span className='proposals_tool_icon'>
                                <IconNegotiation />
                            </span>
                            <span className='proposals_tool_text'>Negotiation&nbsp;({selectedCampaignStatus.Negotiating})
                            </span>
                            {proposalUnreadValue.Negotiating && <span className='unread-notification-icon'></span>}
                        </li>
                    }
                    <li
                        onClick={() => {
                            setActiveTab(4);
                        }}
                        className={`${activeTab === 4 ? 'active' : ''}`}
                    >
                        <span className='proposals_tool_icon'>
                            <IconAccepted />
                        </span>
                        <span className='proposals_tool_text'>Accepted&nbsp;({selectedCampaignStatus.Accepted})
                        </span>
                        {proposalUnreadValue.Accepted && <span className='unread-notification-icon'></span>}
                    </li>
                    <li
                        onClick={() => {
                            setActiveTab(5);
                        }}
                        className={`${activeTab === 5 ? 'active' : ''}`}
                    >
                        <span className='proposals_tool_icon'>
                            <IconRejected />
                        </span>
                        <span className='proposals_tool_text'>Rejected&nbsp;({selectedCampaignStatus.Rejected})
                        </span>
                        {proposalUnreadValue.Rejected && <span className='unread-notification-icon'></span>}
                    </li>
                    <div className='vr'></div>
                    <li
                        onClick={() => {
                            setActiveTab(6);
                            handleOpenAgreementPopup();
                        }}
                        className={`${activeTab === 6 ? 'active' : ''}`}
                        style={{border:'1px solid', borderRadius:'16px'}}
                    >
                        <span className='proposals_tool_icon'>
                            <ReceiptLongRoundedIcon />
                        </span>
                        <span className='proposals_tool_text'>Agreements </span>
                        {/* {proposalUnreadValue.Rejected && <span className='unread-notification-icon'></span>} */}
                    </li>
                    {/* <Tooltip title="Coming soon" arrow>
                        <li
                            onClick={() => {
                                // setActiveTab(0);
                            }}
                            className={`${activeTab === 0 ? 'active' : 'btn btn-disabled'}`}
                        >
                            <span className='proposals_tool_icon'>
                                <IconInvited />
                            </span>
                            <span className='proposals_tool_text'>
                                Invited&nbsp;(0)
                            </span>
                        </li>
                    </Tooltip> */}
                </ul>
            </div>
            <div className='inf-detail-selector'>

                {
                    activeTab === 1 &&
                    parseInt(selectedCampaignStatus.Pending) >= 0 && (
                        <BrandReceived unreadProposal={unreadProposal} updateUnreadProposal={getUnreadProposals} set_sm_id={(id: number) => setSmId(id)} />
                    )
                }
                {
                    activeTab === 2 &&
                    parseInt(selectedCampaignStatus.Shortlisted) >= 0 && (
                        <BrandShortlisted unreadProposal={unreadProposal} updateUnreadProposal={getUnreadProposals} set_sm_id={(id: number) => setSmId(id)} />
                    )
                }
                {
                    activeTab === 3 &&
                    parseInt(selectedCampaignStatus.Negotiating) >= 0 && (
                        <BrandNegotiating unreadProposal={unreadProposal} updateUnreadProposal={getUnreadProposals} set_sm_id={(id: number) => setSmId(id)} />
                    )}
                {activeTab === 4 &&
                    parseInt(selectedCampaignStatus.Accepted) >= 0 && (
                        <BrandAccepted unreadProposal={unreadProposal} updateUnreadProposal={getUnreadProposals} set_sm_id={(id: number) => setSmId(id)} />
                    )}
                {activeTab === 5 &&
                    parseInt(selectedCampaignStatus.Rejected) >= 0 && (
                        <BrandRejected unreadProposal={unreadProposal} updateUnreadProposal={getUnreadProposals} set_sm_id={(id: number) => setSmId(id)} />
                    )}
                {activeTab === 6 &&
                    <AgreementHome open={openAgreementPopup} closeDrawer={handleCloseAgreementPopup} onOpen={handleOpenAgreementPopup} onClose={handleCloseAgreementPopup} campaignId={getSearchParams}/>
                }    
            </div>
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        UserReducer: state.UserReducer.UserData,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        loading: (data: any) => dispatch(Loading(data)),
        selectCampaign: (data: any) => dispatch(SelectedCampaign(data)),
    };
};

const connectToRedux = connect(
    mapStateToProps,
    mapDispatchToProps
)(BrandProposal);

export { connectToRedux as BrandProposal };
