import React, {useState, useEffect} from 'react'
import FollowerCardInline from '../follower-card-inline'
import { Methods } from '../../../../core/utility';

type Props = {
  influencerData: any
}
const methods = new Methods();

const FollowerSecInline = ({influencerData}: Props) => {
  const [avg_reach, setAvgReach] = useState<number>(0);
  const [est_generated_reach, setEstGeneratedReach] = useState<number>(0);

  useEffect(() => {
    if (influencerData?.detail?.[0]) {
      if (influencerData?.detail?.[0]?.average_reach > 0){
        setAvgReach(influencerData?.detail?.[0]?.average_reach);
      } else {
        setAvgReach(influencerData?.detail?.[0]?.generated_reach || 0);
      }
      if (influencerData?.detail?.[0]?.est_follower_reach > 0){
        setEstGeneratedReach(influencerData?.detail?.[0]?.est_follower_reach);
      } else {
        setEstGeneratedReach(influencerData?.detail?.[0]?.est_generated_reach || 0);
      }
    }
  }, [influencerData]);
  return (
    <div className="follower-sec-inline mb-3">
        {avg_reach > 0 &&
          <FollowerCardInline title="Est. Follower Reach" value={influencerData?.detail?.[0]?.est_generated_reach && Math.round(influencerData?.detail?.[0]?.est_generated_reach) + '%'} value1={avg_reach && methods.convertNumber(avg_reach)} multipleValue />
        }
        {influencerData?.detail?.[0]?.avg_plays > 0 &&
            <FollowerCardInline title="Avg. Views" value={influencerData?.detail?.[0]?.avg_plays && methods.convertNumber(influencerData?.detail?.[0]?.avg_plays)} />
        }
      
        {(influencerData?.detail?.[0]?.avg_comments !== undefined && influencerData.detail[0].avg_comments !== 0) ||
            (influencerData?.detail?.[0]?.public_avg_comments !== undefined && influencerData.detail[0].public_avg_comments !== 0) &&
        <FollowerCardInline title="Avg. Comments" value={
            (influencerData?.detail?.[0]?.avg_comments !== undefined && influencerData.detail[0].avg_comments !== 0)
                ? methods.convertNumber(Math.round(influencerData.detail[0].avg_comments))
                : (influencerData?.detail?.[0]?.public_avg_comments !== undefined && influencerData.detail[0].public_avg_comments !== 0 && methods.convertNumber(Math.round(influencerData.detail[0].public_avg_comments)))
        } />
          }
        {influencerData?.profile_detail?.[0]?.media_count &&
        <FollowerCardInline title="Total Posts" value={influencerData?.profile_detail?.[0]?.media_count && methods.convertNumber(Math.round(influencerData?.profile_detail?.[0]?.media_count))} />
      }
    </div>
  )
}

export default FollowerSecInline