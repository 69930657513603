import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../../../redux/action";
import { connect } from "react-redux";

import {
  ApplyCampaignService,
  LocalService,
  UserService,
  PublicService,
  InvoiceService,
} from "../../../../core/services";
import { ListService } from "../../../../core/services";
import "./proposal.scss";
import SubmitAProposal from './submit-a-proposal';
import ProposalContainer from '../../../brand/plans/campaings/campaign-view/proposals/proposal-components/proposal-container';
import ConfirmPopup from '../../../../components/confirm-popup/confirm-popup';
import { createPortal } from 'react-dom';
import BarterProductInfo from "../../../brand/plans/campaings/campaign-view/proposals/proposal-components/barter-product-info";
import { FbConversionTrack } from "../../../../core/services/FbConversionTrackEvents";
import InfluencerAgreementTerms from "../../../brand/plans/campaings/campaign-view/proposals/proposal-components/agreement-terms";
import * as gtUtil from "../../../../core/utility";
import { DeliveryStatus, ProductCondition } from "../../../brand/plans/campaings/campaign-view/proposals/proposal-components";

interface Props {
  UserReducer: any;
  CampaignDetail: any;
  loading: (data: any) => void;
  selectCampaign: any;
  
}

const localService = new LocalService();
const applycampaignFlow = new ApplyCampaignService();
const userService = new UserService();
const invoiceService = new InvoiceService();
const fbConversionTrack: FbConversionTrack = new FbConversionTrack();


const initState = {
  country: "",
  state: "",
  city: "",
  area: "",
  landmark: "",
  house_no: "",
  pincode: "",
  residency_type: "",
  default_address: "false",
};

const listService: ListService = new ListService();
const applyCampaignService: ApplyCampaignService = new ApplyCampaignService();

type StatusType = 'Pending' | 'Negotiating' | 'Accepted' | 'Rejected' | 'Shortlisted' | 'NonNegotiable';

type CurrentSteps = 1 | 2 | 3 | 4;

interface FormDataType {
  negotiation_id: number;
  proposal_id: number;
  campaign_id: number;
  influencer_id: number;
  sm_id: number;
  deliverable_item: string;
  s_0_quantity: number;
  s_0_price: number;
  s_0_status: StatusType;
  s_0_date: string;
  s_1_quantity: number;
  s_1_price: number;
  s_1_status: StatusType;
  s_1_date: string;
  s_2_quantity: number;
  s_2_price: number;
  s_2_status: StatusType;
  s_2_date: string;
  s_3_quantity: number;
  s_3_price: number;
  s_3_status: StatusType;
  s_3_date: string;
  current_step: CurrentSteps;
  status: StatusType;
  proposal_datetime: string;
  created_at: string;
  updated_at: string;
}

const InfluencerProposal: React.FC<Props> = (props: Props) => {
  const [deliverables, setDeliverables] = useState<any>(null);
  const [proposalData, setProposalData] = useState<any>(null);
  const [proposalAddress_id, setProposalAddress_id] = useState(0);
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);
  const [addressList, setAddressList] = useState<any>(null);
  const [proposalChats, setProposalChats] = useState<any>(null);
  const [comment, setComment] = useState("");
  const [totalAmount, setTotalAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showApply, setShowApply] = useState(false);
  const [showAcceptReject, setShowAcceptReject] = useState(false);
  const [addressFormData, setAddressFormData] = useState(initState);
  const [recivedProductDate, setRecivedProductDate] = useState("");
  // const [productDelvieryStatus, setProductDelvieryStatus] = useState<DeliveryStatus>(DeliveryStatus.Pending);
  const [productCondition, setProductCondition] = useState<ProductCondition>(ProductCondition.Good);
  const [termsAccept, setTermsAccept] = useState(false);
  const [productDeliveryRemark, setProductDeliveryRemark] = useState("");
  const [deliverableHeader, setDeliverableHeader] = useState<any>([]);
  const [showInput, setShowInput] = useState(false);
  const [location, setLocation] = useState([]);
  const [showUploadPopup, setShowUploadPopup] = useState(false);
  const [proposalStatus,setProposalStatus] = useState("")
  const [costingModel] = useState(props.selectCampaign?.campaign_cost_model || "Individual");
  const [campaignType] = useState(props.selectCampaign?.campaign_type || "Paid");

  const [productDeliveryImageUrl, setProductDeliveryImageUrl] = useState("");
  const [productDeliveryImagePath, setProductDeliveryImagePath] = useState("");
  const [checkTermsCheckbox, setCheckTermsCheckBox] = useState(false);
  const [showInvoiceGenerateBtn, setShowInvoiceGenerateBtn] = useState(false);

  const [proposalComments, setProposalComments] = useState<any>([]);
  const [costPerUnitError, setCostPerUnitError] = useState({
    status: true,
    message: "",
  });

  const [openRejectPopup, setOpenRejectPopup] = useState(false);
  const [openAcceptPopup, setOpenAcceptPopup] = useState(false);
  const navigate = useNavigate();
  const [campaign_cost_model] = useState(
    !(props.CampaignDetail.campaign_cost_model + "")
      .toLocaleLowerCase()
      .includes("package")
  );
  const myref: any = useRef(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const getParams = new URLSearchParams(window.location.search);

  let smID = getParams.get("smID");


  const publicService = new PublicService();

  useEffect(() => {
    if(props.selectCampaign?.smId == "0")
      window.location.reload();
  }, []);

  /*Invoice Section */
  useEffect(() => {
    async function showGenrateInvoiceButton() {
      try {
        if(props.UserReducer !== null){
          let influencer_id = 0;
          let sm_id = Number(getParams.get("smId"));
          if (props.CampaignDetail.campaign_type !== 'Barter' && props.UserReducer.influencer_ids.length > 0) {
  
            for (let data of props.UserReducer.influencer_ids) {
              if ((props.CampaignDetail.campaign_platform === data.platform_type) && (sm_id === (data.ig_id || data.yt_id))) {
                influencer_id = data?.influencer_id;
                sm_id = data?.ig_id || data.yt_id;
              }
            }
          }
          if (influencer_id > 0) {
            const response = await invoiceService.allowToGenrateInvoice({
              user_id: props.UserReducer.user_id,
              account_id: props.UserReducer.account_id,
              campaign_id: props.CampaignDetail.campaign_id,
              influencer_id: influencer_id,
              sm_id: sm_id
            });
            setShowInvoiceGenerateBtn(response.data.status.status);
          }
  
        }
      } catch (error) {
        throw new Error(`showGenrateInvoiceButton error: ${error}`);
      }
    }

    //temp comment
    showGenrateInvoiceButton();
    (async () => {
      try {
        if (props.CampaignDetail){
          const fb_track_response = await fbConversionTrack.trackViewContent({userData: props.UserReducer, campaignData: props.CampaignDetail})
        }
      } catch (error) {
        console.error('Error fetching campaign details:', error);
      }
    })()
  }, [props.CampaignDetail, props.UserReducer]);


  useEffect(() => {
    proposalInfo();
    influencerShippingAddressList();
    async function getLocations() {
      try {
        props.loading(true);
        const res = await publicService.getLocation();
        if (res.data.status) {
          setLocation(res?.data?.rows);
        } else {
          setLocation([]);
        }
      } catch (error) {
        setLocation([]);
        console.log(`get Geo (Location) error :: ${JSON.stringify(error)}`);
      } finally {
        props.loading(false);
      }
    }
    getLocations();
  }, [props.CampaignDetail, proposalAddress_id]);

  function proposalInfo() {
    props.loading(true);
    ApplyCampaignService.proposalDetail({
      campaignId: props?.CampaignDetail?.campaign_id,
      smId: props?.CampaignDetail?.smId
    })
      .then((res: any) => {
        try {
          if (res?.data?.status?.status) {
            setDeliverables(res?.data?.rows);
            if (res.data.status.applyed) {
              setProposalData(res.data.rows.data);
              if (res.data.rows.comments.length > 0) {
                setProposalComments(res.data.rows.comments);
              }
              setShowApply(!res.data.status.status);
              setProposalAddress_id(
                parseInt(res.data.rows.data[0].address_id)
              );
              setProposalStatus(res.data.rows.data[0].campaign_proposal_status)
              if (res.data.rows.data[0].current_step === 2) {
                setDisableSubmitButton(false);
              }
              let status = false;
              switch (res.data.rows.data[0].status) {
                case "Accepted":
                  status = true;
                  break;
                case "Rejected":
                  status = true;
                  break;
                default:
                  status = false;
              }
              setShowAcceptReject(
                status
              );
              const allowToNegotiate = res.data.rows.data ?.some((proposal: any) => {
                const statusKey = `s_${proposal.current_step}_status`;
                const status = proposal[statusKey];
                return status === "Pending" || status === "Negotiating";
              }); 
              setNegoAllAcceptReject(allowToNegotiate)
              } 
              else {
              setShowApply(res.data.status.status);
              }
          } else {
            localService.toastify(res.data.status.message, "error", 1000);
          }
        } catch (error) { }
      })
      .catch((error) => {
        throw new Error(
          `get Campaign deliverables error in ApplyCampaignService :: ${error}`
        );
      })
      .finally(() => props.loading(false));
  }

  const handleCost = (
    index: number,
    qty: number,
    price: any,
    skipqty: boolean
  ) => {
    let deliv = [...deliverables];
    let totalprice = 0;
    if (!skipqty) {
      deliv[index].price = qty * +price;
    } else {
      deliv[index].price = +price;
    }
    for (let data of deliv) {
      let checkKey = Object.keys(data).includes("price");
      if (checkKey) {
        totalprice = totalprice + data.price;
      }
    }

    setTotalAmount(totalprice);
    setDeliverables(deliv);
  };

  const handleSwitch = (index: number, negotiable: boolean) => {
    let nego = "";
    if (negotiable) {
      nego = "Negotiating";
    } else {
      nego = "NonNegotiable";
    }
    let deliv = [...deliverables];
    deliv[index].status = nego;
  };

  function costPerUnitValidation(allow: boolean) {
    try {
      if (allow && campaign_cost_model) {
        for (let data of deliverables) {
          if (data.price === 0 && data.deliverable_item != "ig_static_image") {
            allow = false;
            setCostPerUnitError((e) => ({
              status: false,
              message: "Cost per unit cannot be empty",
            }));
            return false;
          } else {
            allow = true;
          }
        }
      } 
      else if (!campaign_cost_model) {
        for (let data of deliverables) {
          if 
          (
            (data.deliverable_item + "").toLocaleLowerCase().includes("package")
          ) {
            if (data.price > 0){
              allow =true;
            }
            else{
              allow = false;   
              setCostPerUnitError((e) => ({
                status: false,
                message: "Package costing model cannot be empty",
              }));
              }
    
          }
        }
      } else if (props.CampaignDetail.campaign_type === "Barter") {
        allow = true;
      }

      return allow;
    } catch (error) {
      allow = false;
      localService.toastify(
        "Internal Error Please Check in handleApply ==> " +
        JSON.stringify(error)
      );
    }
  }
  const [errorTerms, setErrorTerms] = useState(false);
  const handleApply = () => {
    let allowToApply = (props?.CampaignDetail.campaign_type + "")
      .toLocaleLowerCase()
      .includes("paid");
    if (checkTermsCheckbox && costPerUnitValidation(allowToApply)) {
      setLoading(true)
      ApplyCampaignService.applyCampaign({
        campaignId: props?.CampaignDetail.campaign_id,
        campaign_cost_model: props?.CampaignDetail.campaign_cost_model,
        smId: props?.CampaignDetail.smId,
        deliverables: deliverables,
        comments: comment
      })
        .then((res: any) => {
          if (res.data.status === 1) {
            localService.toastify(res.data.status.message, "success", 1000);
            const token = localStorage.getItem('token')
            setComment('');
            if (token) {
              if(token && token.length > 0){
                navigate(`/influencer/campaign/campaigndetails?key=${token}`);
              }

            }
            else{
              navigate("/influencer/dashboard");

            }
          } else {
            localService.toastify(res.data.status.message, "info", 2000);
          }

          setLoading(false);
          proposalInfo();
        })
        .catch((error) => {
          throw new Error(
            `Apply Campaign handleApply function Error : ${error}`
          );
        });
    } else {
      setCheckTermsCheckBox(true);
      setErrorTerms(true);
    }
  };


  const getCommonStatus = (details: any[],campaign_cost_model:string): string => {
    if (details.length === 0) return "Negotiating";
      const statusIndexKey = `s_${details[0].current_step}_status`;
    if (campaign_cost_model === "Individual") {
      const commonStatus = details[0]?.[statusIndexKey];
      return details.every(obj => obj?.[statusIndexKey] === commonStatus) ? commonStatus : "Negotiating";
      }
    else{
      const package_cost_item = details.filter(obj => obj?.deliverable_item === "Package Cost 1") ;
      return package_cost_item[0][statusIndexKey]
    }
  }
  const handleSubmit = async () => {
    try{
     setLoading(true);
     const status_value = getCommonStatus(proposalData,props.selectCampaign.campaign_cost_model);
     const response = await applyCampaignService.proposalNegotiate({
      proposalNegotiate: proposalData,
      sm_id: smID,
      status_value :status_value,
      user_type:"Influencer"
    })
     if(response){
      setNegotiateVisible(false)
      proposalInfo();
    }
    }
    catch(error){
     console.error("Error in Negotiation ")
    }
   }

  const handleCancel = () => {
    navigate("/influencer/dashboard");
  };

  const saveAddress = async () => {
    try {
      setLoading(true);
      const response = await userService.addShippingaddresss({
        influencerId: props.CampaignDetail.influencerId,
        mobile_no: props.UserReducer.login_mobile,
        full_name:props.UserReducer.full_name,
        ...addressFormData,

      });
      if (response.status === gtUtil.Constants.SUCCESS) {
        setAddressFormData(initState);
        setShowInput(!showInput);
        localService.toastify(response.info, "success", 2000);
        influencerShippingAddressList()
      }
    } catch (error) {
      throw new Error(`saveAddress is error :: ${error}`);
    } finally {
      setLoading(false);
    }
  };
  async function influencerShippingAddressList() {
    try {
      const response: any = await listService.influencerShippingAddressList(props?.UserReducer?.influencer_id || props.selectCampaign.influencerId);
      if (response.status === gtUtil.Constants.SUCCESS) {
        setAddressList(response.data.rows);
      }
    } catch (error) {
      throw new Error(`influencerShippingAddressList in proposal influencer error is ==> ${error}`)
    }
  }

  async function sendAddress(e: any) {
    try {
      proposalInfo();
      const response: any = await applycampaignFlow.updateProposalShippingAdd({
        proposal_id: proposalData[0].proposal_id,
        address_id: addressList[e].address_id,
      });
      if (response.status === gtUtil.Constants.SUCCESS) {
        localService.toastify(response.info, "success", 2000);
      } else {
        localService.toastify(response.info, "info", 2000);
      }
    } catch (error) {
      throw new Error(`sendAddress error in proposal :: ${error}`);
    }
  }

  const updateDefaultAddress = async (e: any) => {
    try {
      const selectedAddressId = e.target.dataset.address_id || e.target.closest('.ship-add-checkbox').dataset.address_id;
      if (selectedAddressId != undefined) {
        props.loading(true);
        const response: any = await userService.updateDefaultAddress({
          address_id: selectedAddressId,
          default_address: true,
          influencer_id: props?.UserReducer?.influencer_id || props.selectCampaign.influencerId
        });
        const checkStatus = (response.data.status) ? 'success' : 'info';
        await influencerShippingAddressList();
        props.loading(false);
        localService.toastify(response.data.message, checkStatus, 1000);
      }
    } catch (error) {
      props.loading(false);
      localService.toastify('Something went wrong.', 'error', 1000);
      console.error('updateDefaultAddress error :: ==>:', error);
    }
  };

  async function sendReciviedProduct() {
    try {
      setLoading(true);
      if (
        termsAccept &&
        productCondition &&
        recivedProductDate &&
        productDeliveryImageUrl
      ) {
        const response: any = await applycampaignFlow.updateDeliveryStatus({
          proposal_id: proposalData[0].proposal_id,
          product_delivery_remarks: productDeliveryRemark,
          product_condition: productCondition,
          product_delivery_status: DeliveryStatus.Received,
          product_delivery_date: recivedProductDate,
          product_delivery_image_url: productDeliveryImageUrl,
        });

        if (response.status === gtUtil.Constants.SUCCESS) {
          localService.toastify(response.info, "success", 2000);
          proposalInfo();
        } else {
          localService.toastify(response.info, "error", 2000);
        }
      }
    } catch (error) {
      throw new Error(`sendReciviedProduct :: ${error}`);
    } finally {
      setLoading(false);
    }
  }


  useEffect(() => {

    if (myref.current && props?.CampaignDetail) {
      myref.current.innerHTML = props?.CampaignDetail?.campaign_description

    }
  }, [myref.current, props?.CampaignDetail])
  useEffect(() => {

    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current.scrollHeight;
    }
  }, []);

  const AcceptProposal = async (status: string) => {
    try {
      setLoading(true);
      const res:any = await applyCampaignService.acceptProposal({
     status: status,
        proposal_id: proposalData[0].proposal_id,
        user_type: "Influencer",      
    })
      if(res.data.status.status == true){
        setLoading(false);
        proposalInfo()      
      }
    } catch (error) {
      setLoading(false);
      console.warn("error",error)

    }
  }
  const RejectProposal = async (status: string) => {
    try {
      setLoading(true);
      const res:any = await applyCampaignService.rejectProposal({
     status: status,
        proposal_id: proposalData[0].proposal_id,
        user_type: "Influencer",      
    })
      if(res.data.status.status == true){
        setLoading(false);
        proposalInfo()      
      }
    } catch (error) {
      setLoading(false);
      console.warn("error",error)

    }
  }
  const updateProposal = async (status: string) => {

    try {
      setLoading(true);
      ApplyCampaignService.updateProposal({
        status: status,
        proposal_id: proposalData[0].proposal_id,
      })
        .then((res: any) => {
          proposalInfo()
        })
        .catch((error) => console.warn(error))
        .finally(() => setLoading(false));
    } catch (error) {
    }
  }


  function handleNegoValue(event: FormDataType) {
    setProposalData((prev: any[]) => {
      return prev.map(item =>
        item.deliverable_item === event.deliverable_item ? { ...item, ...event } : item
      );
    });
  }

  useEffect(() => {
  }, [proposalData])


  const chatBodyRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    // Scroll to the bottom of the container on load
    if (chatBodyRef?.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [proposalChats]);


  const [openApply, setOpenApply] = React.useState(false);

  // const ProposalTableToRender: any = campaign_type == true && costing_model == false ? CommonProposalTable : CommonProposalTableBarterPackage;

  const [expanded, setExpanded] = React.useState<string | false>('0');
  const [negotiateVisible, setNegotiateVisible] = useState(false);
  const [negoAllAcceptReject, setNegoAllAcceptReject] = useState(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const getDeliverableHeader = async () => {
    try {
      const response: any = await ApplyCampaignService.getDeliverableHeader({ campaign_id: `${props?.CampaignDetail?.campaign_id}` });
      if (response.status === 1) {
        setDeliverableHeader(response.data.rows);
      }
    } catch (error) {
      console.error('Error fetching deliverable header:', error);
    }
  };
  useEffect(() => {
    getDeliverableHeader();
  }, [props?.CampaignDetail.campaign_id]);

  const handleRejectClose = () => {
    setOpenRejectPopup(false);
    document
      .getElementsByTagName('body')[0]
      .classList.remove('custom-drawer-active');
  };
  const handleReject = () => {
    setOpenRejectPopup(true);
    document
      .getElementsByTagName('body')[0]
      .classList.add('custom-drawer-active');
  }

  const handleAcceptClose = () => {
    setOpenAcceptPopup(false);
    document
      .getElementsByTagName('body')[0]
      .classList.remove('custom-drawer-active');
  };
  const handleAccept = () => {
    setOpenAcceptPopup(true);
    document
      .getElementsByTagName('body')[0]
      .classList.add('custom-drawer-active');
  }



  return (
    <div className="inf-proposal-main">
      {showApply &&
        <SubmitAProposal
          campaignDetail={props.CampaignDetail}
          checkTermsCheckbox={checkTermsCheckbox}
          // ref={myref}
          campaign_cost_model_value={campaign_cost_model}
          handleCost={handleCost}
          handleSubmit={handleSubmit}
          deliverables={deliverables}
          handleApply={handleApply}
          costPerUnitError={costPerUnitError}
          handleSwitch={handleSwitch}
          totalAmount={totalAmount}
          userReducer={props.UserReducer}
          proposalChats={proposalChats}
          comment={comment}
          setComment={setComment}
          errorTerms={errorTerms}
          setCheckTermsCheckBox={setCheckTermsCheckBox}
          handleCancel={handleCancel}
          loading={loading}
        />
      }
      {!showApply && proposalData != null &&
        <>
          <ProposalContainer deliverableHeader={deliverableHeader}
            data={proposalData}
            step={proposalData}
            negotiateVisible={negotiateVisible}
            setNegotiateVisible={setNegotiateVisible}
            submitProposal={handleSubmit}
            updateProposal={updateProposal}
            index={proposalData[0]}
            onChange={(event: any) => handleNegoValue(event)}
            campaignType={campaignType}
            userType="Influencer"
            throughWhatsapp = {props.UserReducer == null ? "true" : "false"}
          />
          {!negotiateVisible && (proposalStatus === "Pending" ||  proposalStatus === "Negotiating") && (proposalData[0].current_step === 2 || proposalData[0].current_step === 4) &&
            <div className='proposal-action-btn'>
              <button className='btn btn-outline-error' onClick={handleReject}>Reject</button>
              {(proposalData[0].current_step === 2) && negoAllAcceptReject &&(<button className='btn btn-outline-primary' onClick={(() => {
                setNegotiateVisible(true)
              })}>Negotiate</button>)}
            <button className='btn btn-primary' onClick={handleAccept}>Accept</button>
            </div>

          }

          {/* BARTER */}
          {showAcceptReject &&
            props.CampaignDetail.campaign_type
              .toString()
              .toLowerCase()
              .includes("barter") &&
            proposalData &&
            proposalData[0].status === "Accepted" && (
              <BarterProductInfo
                proposalData={proposalData}
                setShowInput={setShowInput}
                showInput={showInput}
                setAddressFormData={setAddressFormData}
                addressFormData={addressFormData}
                location={location}
                setLocation={setLocation}
                saveAddress={saveAddress}
                loading={loading}
                addressList={addressList}
                sendAddress={sendAddress}
                updateDefaultAddress={updateDefaultAddress}
                sendReciviedProduct={sendReciviedProduct}
                setProductCondition={setProductCondition}
                setTermsAccept={setTermsAccept}
                setRecivedProductDate={setRecivedProductDate}
                setProductDeliveryRemark={setProductDeliveryRemark}
                productDeliveryImageUrl={(data)=>setProductDeliveryImageUrl(data)}
                productDeliveryImagePath={(data)=>setProductDeliveryImagePath(data)}
                productDeliveryImage={productDeliveryImageUrl}
                showUploadPopup={showUploadPopup}
                setShowUploadPopup={()=>setShowUploadPopup(!showUploadPopup)}
                proposalAddressId={proposalAddress_id}
              />
            )}
          {/* BARTER ENDS */}
        {openRejectPopup && createPortal(<ConfirmPopup confirmPopupInnerText="Are you sure want to reject the proposal?" openPopup={openRejectPopup} confirmClosePopup={handleRejectClose} confirmClickAction={(() => {
            RejectProposal("Rejected")
          })} drawerTitle="" />, document.body)}
          
        {openAcceptPopup && createPortal(<ConfirmPopup confirmPopupInnerText="Are you sure want to accept the proposal?" openPopup={openAcceptPopup} confirmClosePopup={handleAcceptClose} confirmClickAction={(() => {
            AcceptProposal("Accepted")
          })} drawerTitle="" />, document.body)}
          <InfluencerAgreementTerms proposalData={proposalData} userType={props.UserReducer?.account_type ? props.UserReducer?.account_type : 'Influencer'}/>
        </>

      }
    </div>

  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
    UserID: state.UserID.userID,
    CampaignDetail: state.InfSelectCamp.campaign,
    selectCampaign: state.InfSelectCamp.campaign
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(InfluencerProposal);

export { connectToRedux as InfluencerProposal };