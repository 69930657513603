import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, Skeleton, SwipeableDrawer, useMediaQuery } from '@mui/material';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import YouTubeIcon from '@mui/icons-material/YouTube';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { ChevronLeft, Close, Launch } from '@mui/icons-material';
import { XIcon } from 'react-share';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import dayjs from 'dayjs';
import { InfluencerCampaignCard } from '../../../components';
import { Loading, SelectedCampaign } from '../../../redux/action';
import { ApplyCampaignService, ListService, LocalService, PublicService } from '../../../core/services';
import InstagramIcon from '../../../components/icons/instagram-icon';
import * as Utility from '../../../core/utility';
import CustomDrawer from '../../../components/custom-drawer/custom-drawer';
import IconGender from '../../../components/icons/icon-gender';
import IconLocation from '../../../components/icons/icon-location';
import IconCategory from '../../../components/icons/icon-category';
import { DummyCampaignImage } from '../../../config/config';
import DeliverableInfo from '../../../components/deliverables/deliverables';
import './dashboard.scss';
import MaterialSymbol from '../../../components/icons/material-symbol';
import { ApplyRestrictionPopup } from '../../../components/popup/apply-restriction-popup';
import InstagramIcon1 from '../../../components/icons/instagram-icon1';
import IconYouTube from '../../../components/icons/icon-youtube';
import { UserService } from '../../../core/services';
import { UserLogin } from '../../../redux/action';
import { CommonService } from '../../../core/services/common.service';
import ShowWelcomePopUp from '../../../components/popup/show-welcome';
import { FbConversionTrack } from '../../../core/services/FbConversionTrackEvents';

interface Props {
  UserReducer: any;
  UserID: any;
  loading: (data: any) => void;
  selectedInf?: (data: any) => void;
  updateUser: any;
}

type Platform = 'instagram' | 'youtube';

const localService = new LocalService();
const publicService: PublicService = new PublicService();
const methods: Utility.Methods = new Utility.Methods();
const listService: ListService = new ListService();
const userService: UserService = new UserService();
const commonService: CommonService = new CommonService();
const fbConversionTrack: FbConversionTrack = new FbConversionTrack();
const applyCampaignService = new ApplyCampaignService();
const InfluencerDashbaord: React.FC<Props> = (props: Props) => {
  const [campaignList, setCampaignList] = useState([]);
  const [campaignListCopy, setCampaignListCopy] = useState([]);
  const [appliedCampaignList, setAppliedCampaignList] = useState([]);
  const [btnName, setBtnName] = useState('Apply now');
  const [campaign_id, setCampaign_id] = useState({
    campaign_id: 0,
    campaign_platform: '',
  });
  const navigate = useNavigate();

  const [applyRestrictionPopup, setApplyRestrictionPopup] = useState(false);
  const [applyRestrictionData, setApplyRestrictionData] = useState({
    followers: {
      status: false,
    },
    subscription: {
      status: false,
    },
    social_connect: {
      status: false,
    },
  });
  // const navigate = useNavigate();
console.log('userreducer',props.UserReducer)
  const [openCampaignDetailPopup, setOpenCampaignDetailPopup] = useState(false);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [campaignDetailDrawerContent, setCampaignDetailDrawerContent] =
    useState<any>(null);
  const [openFilterPopup, setOpenFilterPopup] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [SM_ID, setSM_ID] = useState(0);
  const [shareUrl, setShareUrl] = useState('');
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const [selectedCampaign, setSelectedCampaign] = useState<any | null>(null);
  const [loading, setLoading] = useState(false);
  const [stateToUpdateUserReducer, setStateToUpdateUserReducer] = useState<boolean>(false);
  const [influencerDetails, setInfluencerDetails] = useState<any>([]);
  const [showWelcomePopUp, setShowWelcomePopUp] = useState(false)
  const [checkInfType, setCheckInfType] = useState({
    status: false,
  })

  async function handleClickShare(
    event: React.MouseEvent<HTMLButtonElement>,
    res: any
  ) {
    try {
      document
        .getElementsByTagName('body')[0]
        .classList.add('custom-drawer-active');
      setShareUrl(
        `${window.location.origin}/influencer/campaign/campaigndetails?campaignId=${res.data.rows.campaign_id}&platform=${res.data.rows.campaign_platform}&isShare=true`
      );
      setSelectedCampaign(res);
    } catch (error) {
      console.log('handleClickShare error ::', error);
    }
  }

  async function copyToClipboard() {
    navigator.clipboard.writeText(shareUrl);
    localService.toastify('Link Copied', 'success', 1000);
  }

  const [selectedPlatforms, setSelectedPlatforms] = useState({
    instagram: false,
    youtube: false,
  });

  const [paymentCheckboxes, setPaymentCheckboxes] = useState({
    Paid: false,
    Barter: false,
    BarterPaid: false,
    CPV: false,
  });

  const [showOnlyCheckboxes, setShowOnlyCheckboxes] = useState({
    Public: false,
    Private: false,
  });

  async function checkifThroughLink(campaignId: any) {
    sessionStorage.removeItem('campaignId');
    sessionStorage.clear();
    const response: any = await applyCampaignService.getPlatform({ campaignId })
    if (response?.data?.campaign_platform) {
      let sm_id = 0;
      if (props.UserReducer.account_type !== 'Talent Partner') {
        if (props.UserReducer?.influencer_ids.length == 1) {
          sm_id = response?.data?.campaign_platform == "Instagram" ? props.UserReducer?.influencer_ids[0]?.ig_id : props.UserReducer?.influencer_ids[0]?.yt_id;
        }
        for (let data of props.UserReducer?.influencer_ids) {

          if (response?.data?.campaign_platform == "Instagram" && data.token_status === "Connected") {
            if (data.ig_id) {
              sm_id = data?.ig_id;
            }
          }
          if (response?.data?.campaign_platform == "Youtube" && data.token_status === "Connected") {
            if (data.yt_id) {
              sm_id = data?.yt_id;
            }
          }
        }
      } else {

        for (let data of props.UserReducer?.influencer_ids) {
          if (response?.data?.campaign_platform == "Instagram" && data.influencer_id === props.UserReducer.influencer_id) {
            sm_id = data?.ig_id;
          }
          if (response?.data?.campaign_platform == "Youtube" && data.influencer_id === props.UserReducer.influencer_id) {
            sm_id = data?.yt_id;
          }
        }
      }
      navigate(`/influencer/campaign/campaigndetails?campaignId=${campaignId}&smId=${sm_id}`);

    }
  }


  const [locationCheckbox, setLocationCheckbox] = useState(false);

  const [searchValue, setSearchValue] = useState<string>('');

  const [filterCampaignList, setFilterCampaignList] = useState([]);

  const [clearSearch, setClearSearch] = useState(false);

  const handleClearAllPopup = () => {
    setSelectedPlatforms({
      instagram: false,
      youtube: false,
    });

    setPaymentCheckboxes({
      Paid: false,
      Barter: false,
      BarterPaid: false,
      CPV: false,
    });

    setShowOnlyCheckboxes({
      Public: false,
      Private: false,
    });

    setLocationCheckbox(false);

    setFilterCampaignList([]);

    setCampaignList(campaignListCopy);

    setAnchorEl(null);
  };

  const filterHandler = () => {
    try {
      const platform = [
        selectedPlatforms.instagram && 'Instagram',
        selectedPlatforms.youtube && 'Youtube',
      ].filter(Boolean) as string[];

      const payment = [
        paymentCheckboxes.Paid && 'Paid',
        paymentCheckboxes.Barter && 'Barter',
        paymentCheckboxes.BarterPaid && 'Paid + Barter',
        paymentCheckboxes.CPV && 'CPV',
      ].filter(Boolean) as string[];

      const showOnly = [
        showOnlyCheckboxes.Public && 'Public',
        showOnlyCheckboxes.Private && 'Private',
      ].filter(Boolean) as string[];

      // Format myLocation to match campaign_locations (split into city, state, country)
      const myLocation = locationCheckbox
        ? [influencerDetails[0].city, influencerDetails[0].state, influencerDetails[0].country]
          .filter(Boolean)
        : [];

      const isIndiaPreferred = myLocation.includes("India");

      const filteredCampaignList = campaignListCopy?.filter((data: any) => {
        const platformFilter =
          platform.length === 0 ||
          (data?.campaign_platform && platform.includes(data?.campaign_platform));

        const paymentFilter =
          payment.length === 0 ||
          (data?.campaign_type && payment.includes(data?.campaign_type));

        const showOnlyFilter =
          showOnly.length === 0 ||
          (data?.campaign_visibility &&
            showOnly.includes(data?.campaign_visibility));

        // Split campaign locations into an array of components (city, state, country)
        const campaignLocations = data?.campaign_locations
          ? data.campaign_locations.split(',').map((loc: string) => loc.trim())
          : [];

        // Define location filter logic
        const locationFilter =
          myLocation.length === 0 || // No location filter applied
          campaignLocations.some((campaignLoc: string) => {
            // Split campaign location into city, state, country
            const locComponents = campaignLoc.split(':').map((part) => part.trim());

            // Condition 1: Exact match with India:India:India
            if (locComponents.join(':') === "India:India:India") {
              return true;
            }

            // Condition 2: Exact match for user's city and state
            const exactMatch = myLocation.every((part, index) => part === locComponents[index]);

            // Condition 3: If user is in Delhi, allow campaigns with Delhi in any state (e.g., Delhi:Delhi:India or Delhi:Maharashtra:India)
            const cityMatch = myLocation[0] === locComponents[0] && locComponents[2] === "India";

            return exactMatch || cityMatch;
          });

        return platformFilter && paymentFilter && showOnlyFilter && locationFilter;
      });

      setFilterCampaignList(filteredCampaignList);
    } catch (error) {
      console.error('Error filtering campaigns:', error);
    }
  };



  const handleCheckboxChangePlatform = (platform: Platform) => {
    setSelectedPlatforms((prevSelectedPlatforms) => ({
      ...prevSelectedPlatforms,
      [platform]: !prevSelectedPlatforms[platform],
    }));
  };

  const handleCheckboxChangePayment =
    (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setPaymentCheckboxes((prevPaymentCheckboxes) => ({
        ...prevPaymentCheckboxes,
        [name]: event.target.checked,
      }));
    };

  const handleCheckboxShowOnly =
    (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setShowOnlyCheckboxes((prevShowOnlyCheckboxes) => ({
        ...prevShowOnlyCheckboxes,
        [name]: event.target.checked,
      }));
    };

  const handleLocation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLocationCheckbox(event.target.checked);
  };

  async function updateUserReducer() {
    let latestUserReducerData = await userService.getUpdatedUserReducer(props.UserReducer);
    if (![undefined, null, ''].includes(latestUserReducerData) && latestUserReducerData.status === 'Blocked') {
      localService.logout()
    }
    else if (![undefined, null, ''].includes(latestUserReducerData)) {
      props.updateUser(latestUserReducerData)
    }
  }

  const showWelcomePopUpCount = async () => {
    try {
      const response = await commonService.checkPopup({ user_id: props.UserReducer.user_id, account_id: props.UserReducer.account_id, popup_name: 'new_platform_welcome' });
      if (response.data.status) {
        setShowWelcomePopUp(true)
      }
    } catch (error: any) {
      throw new Error('getTopInfluencerData', error);
    }
  }

  useEffect(() => {
    updateUserReducer();
    showWelcomePopUpCount();
    filterHandler();
  }, [selectedPlatforms, paymentCheckboxes, showOnlyCheckboxes, locationCheckbox]);

  useEffect(() => {
    let sm_id = 0;
    const campaignId: any = sessionStorage.getItem("campaignId")
    if (campaignId && campaignId !== 'null') {
      checkifThroughLink(campaignId)
    }
    else {
      if (props.UserReducer.account_type !== 'Talent Partner') {
        if (props.UserReducer?.influencer_ids.length == 1) {
          if ((campaign_id?.campaign_platform === props.UserReducer?.influencer_ids[0]?.platform_type)) {
            sm_id = props.UserReducer?.influencer_ids[0]?.ig_id || props.UserReducer?.influencer_ids[0]?.yt_id;
          }
        }
        for (let data of props.UserReducer?.influencer_ids) {
          if ((campaign_id?.campaign_platform === data?.platform_type) && (data.token_status === "Connected")) {
            sm_id = data?.ig_id || data?.yt_id;
          }
        }
      } else {
        for (let data of props.UserReducer?.influencer_ids || [props.UserReducer?.influencer_id]) {
          if ((campaign_id?.campaign_platform === data?.platform_type) && (data.influencer_id === props.UserReducer.influencer_id)) {
            sm_id = data?.ig_id || data?.yt_id;
          }
        }
      }
      if (SM_ID == sm_id) {
        getApplyRestrictionData();
      } else {
        setSM_ID(sm_id);
      }
      (async () => {
        try {
          setLoading(true);
          const response: any = await publicService.influencerCampaignDetail(
            null,
            campaign_id.campaign_id + '',
            sm_id
          );
          setLoading(false);
          if (response?.data?.rows?.campaign_id) {
            const fb_track_response = await fbConversionTrack.trackViewContent({ userData: props.UserReducer, campaignData: response?.data?.rows })
          }
          setCampaignDetailDrawerContent(response);
        } catch (error) {
          console.error('Error fetching campaign details:', error);
          setLoading(false);
        }
      })()

    }
  }, [campaign_id, props, stateToUpdateUserReducer]);

  useEffect(() => {
    getApplyRestrictionData();
  }, [SM_ID])


  useEffect(() => {
    userService.getInfluencerDetails(`${props.UserReducer.influencer_id}`)
      .then((res: any) => {
        if (res.data.status == true) {
          setInfluencerDetails(res.data.rows);
        } else {
          // localService.toastify('No influencer Found', 'info');
        }
      })
      .catch((error: any) =>
        console.warn('getInfluencerDetails  Error :: ' + error)
      )
      .finally(() => {
      });
  }, []);

  useEffect(() => {
    setLoading(true)
    ListService.influencer_campaign_List({
      user_id: props.UserReducer.user_id,
      account_id: props.UserReducer.account_id,
    })
      .then((res: any) => {
        if (res.data.status === 1) {
          setCampaignList(res.data.rows);
          setCampaignListCopy(res.data.rows);
        } else {
          localService.toastify('No Campaign Found', 'info');
        }
      })
      .catch((error) =>
        console.warn('Influencer campaign List Error :: ' + error)
      )
      .finally(() => {
        // props.loading(false)
        setLoading(false)
      });
  }, []);

  useEffect(() => {
    props.loading(true);
    listService.get_applied_campaigns({
      influencerId: props.UserReducer.influencer_id,
      campaignId: campaign_id.campaign_id
    }).then((res: any) => {
      if (res.data.status) {
        setBtnName('Applied');
        setAppliedCampaignList(res.data.rows);
      } else {
        setBtnName('Apply now');
      }
    })
      .catch((error) =>
        console.warn('Influencer applied campaign List Error :: ' + error)
      )
      .finally(() => props.loading(false));
  }, [campaign_id]);


  // get Apply Restriction
  async function getApplyRestrictionData() {
    const res = await listService.getApplyRestriction({
      influencerId: props?.UserReducer?.influencer_id,
      platform: campaign_id?.campaign_platform,
      smId: SM_ID,
      campaignId: campaign_id.campaign_id,
      accountId: props?.UserReducer?.account_id
    });

    if (res.status === 1) {
      setApplyRestrictionData(res.data);
    }
  }

  const handleSearch = (e: any) => {
    const newSearchValue = e.target.value;
    setSearchValue(newSearchValue);

    const lowerCaseSearchValue = newSearchValue.toLowerCase();

    if (lowerCaseSearchValue === '' && lowerCaseSearchValue.length < 3) {
      setCampaignList(campaignListCopy);
      setClearSearch(false);
    } else {
      const newCampaignList = campaignListCopy?.filter((data: any) =>
        data?.campaign_title?.toLowerCase().includes(lowerCaseSearchValue)
      );
      setCampaignList(newCampaignList);
      setClearSearch(true);
    }
  };

  const clearSearchHandler = () => {
    setSearchValue('');
    setClearSearch(false);
    setCampaignList(campaignListCopy);
  };

  const showFilterCampaignData = () => {
    setCampaignList(filterCampaignList);
    setAnchorEl(null);
    setOpenFilterPopup(false);
    document
      .getElementsByTagName('body')[0]
      .classList.remove('custom-drawer-active');
  };

  const currentDate: Date = new Date();

  const handleCampaignDetailPopup = (data: any) => {
    setCampaign_id((prev) => ({
      ...prev,
      campaign_id: data.campaign_id,
      campaign_platform: data?.campaign_platform,
    }));
    setOpenCampaignDetailPopup(true);
  };
  const handleFilterPopup = () => {
    setOpenFilterPopup(true);
    document
      .getElementsByTagName('body')[0]
      .classList.add('custom-drawer-active');
  };

  const handleFilterPopupClose = () => {
    setOpenFilterPopup(false);
    document
      .getElementsByTagName('body')[0]
      .classList.remove('custom-drawer-active');
  };

  const handleCampaignDetailPopupClose = (id: any) => {
    setOpenCampaignDetailPopup(false);
    setCampaignDetailDrawerContent(null)
    setCheckInfType({
      status: false,
    })
  };
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );

  const drawerAnchor = isSmallScreen ? 'bottom' : 'right';
  const handleSearchInputShow = () => {
    setShowSearchInput((prev) => !prev);
    if (showSearchInput === false) {
      setSearchValue('');
      setCampaignList(campaignListCopy);
      setClearSearch(false);
    }
  };

  // handle the campaign when open on a new tab
  async function handleCampaign() {

    if (btnName === 'Applied') {

      const response: any = await listService.getAppliedSm_id({
        influencerId: props?.UserReducer?.influencer_id,
        campaign_id: campaignDetailDrawerContent?.data?.rows?.campaign_id
      })

      if (response.status === 1 && response.data.length > 0) {
        window.open(
          `/influencer/campaign/campaigndetails?campaignId=${campaignDetailDrawerContent?.data?.rows?.campaign_id}&smId=${response.data[0].sm_id || 0}`,
          '_blank'
        );
        setOpenCampaignDetailPopup(false);
      }
    } else {
      setCampaign_id({
        campaign_id: campaignDetailDrawerContent?.campaign_id,
        campaign_platform: campaignDetailDrawerContent?.campaign_platform,
      });
      window.open(
        `/influencer/campaign/campaigndetails?campaignId=${campaignDetailDrawerContent?.data?.rows?.campaign_id}&smId=${SM_ID}`,
        '_blank'
      );
      setOpenCampaignDetailPopup(false);
    }
  }

  const handleCustomDrawerClose = () => {
    setSelectedCampaign(null);
    document
      .getElementsByTagName('body')[0]
      .classList.remove('custom-drawer-active');
  };
  const isMobileDevice = useMediaQuery('(max-width:767px)');

  // handle the campaign apply
  async function handleCampaignApply() {
    try {
      if (btnName === 'Applied') {
        const response: any = await listService.getAppliedSm_id({
          influencerId: props?.UserReducer?.influencer_id,
          campaign_id: campaignDetailDrawerContent?.data?.rows?.campaign_id
        })

        if (response.status === 1 && response.data.length > 0) {
          const url = `/influencer/campaign/proposal?campaignId=${campaignDetailDrawerContent?.data?.rows?.campaign_id}&smId=${response.data[0].sm_id || 0}`;
          const target = isMobileDevice ? '_self' : '_blank';

          if (isMobileDevice) {
            window.location.href = url;  // For mobile devices, navigate in the same tab
          } else {
            window.open(url, target);  // For non-mobile devices, open in a new tab
          }
          setOpenCampaignDetailPopup(false);
        }
      } else {
        const res = await listService.getApplyRestriction({
          influencerId: props?.UserReducer?.influencer_id,
          platform: campaign_id.campaign_platform,
          smId: SM_ID,
          campaignId: campaign_id.campaign_id,
          accountId: props?.UserReducer?.account_id
        });

        if (res.status === 1) {
          setApplyRestrictionData(res.data);
          if (res.data?.followers?.status && res.data?.subscription?.status && res.data?.social_connect?.status) {
            if (res.data.checkInfType.status) {
              const url = `/influencer/campaign/proposal?campaignId=${campaignDetailDrawerContent?.data?.rows?.campaign_id}&smId=${SM_ID}`;
              const target = isMobileDevice ? '_self' : '_blank';

              if (isMobileDevice) {
                window.location.href = url;  // For mobile devices, navigate in the same tab
              } else {
                window.open(url, target);  // For non-mobile devices, open in a new tab
              }
              setOpenCampaignDetailPopup(false);
            } else {
              setCheckInfType({
                status: true,
              })
            }
          } else {
            setApplyRestrictionPopup(true);
          }
        }
      }
    } catch (err) {
      console.log('error in handleCampaignApply', err);
    }
  }

  return (
    <>
      <section className='dashboard-infl'>
        <div className='container'>
          {/* DASHBOARD INFLUENCER INFO SECTION */}
          <div className='dashboard-infl-info-sec'>
            {/* <HeadingStyle className='page-title' title={`Hi ${props?.UserReducer?.account_name}! 👋`} /> */}
            <span className='page-title'>
              <span>{`Hi ${props?.UserReducer?.account_name}! `}</span>
              <span className='page-title-icon wave'>👋</span>
            </span>
            {/* SEARCH SECTION */}
            <div className='search-sec'>
              <strong>Explore and Engage!</strong>
              <div className='search-filter'>
                <div className='search-sec'>
                  <button
                    className={
                      showSearchInput
                        ? 'btn btn-outline-primary btn-mui btn-rounded active'
                        : 'btn btn-outline-primary btn-mui btn-rounded'
                    }
                    onClick={handleSearchInputShow}
                    type='button'
                  >{showSearchInput ? <MaterialSymbol iconName='close' fontweight='200' /> :
                    <MaterialSymbol iconName='search' fontweight='200' />}
                  </button>
                  {showSearchInput && (
                    <>
                      <TextField
                        className='input-login search-plan-input w-100'
                        variant='outlined'
                        label='Search'
                        size='small'
                        value={searchValue}
                        onChange={handleSearch}
                      />
                      {clearSearch && (
                        <span
                          className='clear-search-result d-none d-md-block'
                          onClick={clearSearchHandler}
                          style={{ cursor: 'pointer', color: 'blue' }}
                        >
                          Clear search results
                        </span>
                      )}
                    </>
                  )}
                </div>

                <button
                  onClick={handleFilterPopup}
                  aria-describedby={id}
                  type='button'
                  className='btn btn-outline-primary btn-mui btn-rounded'
                >
                  <MaterialSymbol iconName='filter_alt' fontweight='200' />
                </button>
                {/* {showSearchInput && (
                  <>
                    <TextField
                      className='input-login d-md-none search-plan-input w-100'
                      variant='outlined'
                      label='Search'
                      size='small'
                      value={searchValue}
                      onChange={handleSearch}
                    />
                    {clearSearch && (
                      <span
                        className='d-md-none clear-search-result'
                        onClick={clearSearchHandler}
                        style={{ cursor: 'pointer', color: 'blue' }}
                      >
                        Clear search results
                      </span>
                    )}
                  </>
                )} */}
              </div>
            </div>

          </div>
        </div>
        <div className='container dashboard-card-container'>
          {/* DASHBOARD CARD */}
          {campaignList.length > 0 &&
            campaignList.map((res: any, index) => {
              return (
                <div
                  key={res?.campaign_id}
                  className={`${campaign_id.campaign_id === res?.campaign_id
                    ? 'dashboard-card active'
                    : 'dashboard-card'
                    }`}
                  onClick={() => handleCampaignDetailPopup(res)}
                >
                  <div className='dashboard-card-left'>
                    <img
                      src={loading ? <Skeleton width={182} height={182} animation="wave" /> : res.campaign_cover_url && res.campaign_cover_url ? res.campaign_cover_url : DummyCampaignImage.default_campaign_image}
                      alt=''
                      style={{ cursor: 'pointer' }}
                    />
                    <div className={res.campaign_category && (res.campaign_category === 'Invited' || res.campaign_category === 'Private') ? 'campaign-cate-detail-mobile d-inline-flex d-md-none' : 'campaign-cate-detail-mobile d-inline-flex d-md-none'}>
                      <span className='badge badge-primary-outline mb-2 mt-2 d-flex d-md-none'>
                        {(res.campaign_platform + '').toLocaleLowerCase() === 'instagram' && (<InstagramIcon1 width="20px" height="20px" />)}
                        {(res.campaign_platform + '').toLocaleLowerCase() === 'youtube' && (<YouTubeIcon style={{ color: '#f00', marginRight: '5px' }} />)}
                        {loading ? <Skeleton animation="wave" /> : res.campaign_type}
                      </span>
                      {res.campaign_category && (res.campaign_category === 'Invited' || res.campaign_category === 'Private') &&
                        <span style={{ order: res.campaign_category && (res.campaign_category === 'Invited' || res.campaign_category === 'Private') ? '-1' : '' }} className='badge badge-primary-black mb-2 mt-2 justify-self-start me-2'>Invited</span>}
                    </div>
                  </div>
                  <div className='dashboard-card-right'>
                    <div className='dashboard-card-right-top'>
                      <div className='dashboard-card-right-top-left'>
                        <span className='d-block color-blk-5d fs-14 mb-1'>
                          Posted{' '}
                          {methods.calculateDaysDifference(
                            new Date(res?.posted_at),
                            new Date()
                          )}{' '}
                          days ago
                        </span>
                        {(res?.reveal_brand_name === 'true') &&
                          <p className='mb-0'>{res?.brand_name}</p>
                        }
                        <h3 className='dashboard-card-title'>
                          {loading ? <Skeleton animation="wave" /> : res?.campaign_title}
                        </h3>
                      </div>

                      <div className={res.campaign_category && (res.campaign_category === 'Invited' || res.campaign_category === 'Private') ? 'dashboard-card-right-top-right d-none d-md-flex ' : ''}>
                        <span className='badge badge-primary-outline mb-2 mt-2 d-none d-md-flex'>
                          {(res.campaign_platform + '').toLocaleLowerCase() === 'instagram' && (<InstagramIcon1 width="20px" height="20px" />)}
                          {(res.campaign_platform + '').toLocaleLowerCase() === 'youtube' && (<YouTubeIcon style={{ color: '#f00', marginRight: '5px' }} />)}
                          {loading ? <Skeleton animation="wave" /> : res.campaign_type}
                        </span>
                        {res.campaign_category && (res.campaign_category === 'Invited' || res.campaign_category === 'Private') &&
                          <span style={{ order: res.campaign_category && (res.campaign_category === 'Invited' || res.campaign_category === 'Private') ? '-1' : '' }} className='badge badge-primary-black mb-2 mt-2 justify-self-start me-2'>Invited</span>}
                      </div>
                    </div>
                    <InfluencerCampaignCard
                      description={loading ? <Skeleton animation="wave" /> : res.campaign_description}
                      updated_at={loading ? <Skeleton animation="wave" /> : res.updated_at}
                    />
                    {/* CAMPAIGN DELIVERABLES */}
                    <div className='d-flex flex-wrap mb-2'>
                      <>
                        {res?.deliverables?.split(',').map((del: any) => {
                          const trimmedDeliverable = del.trim();

                          if (trimmedDeliverable !== '') {
                            return (
                              <span
                                key={del}
                                className='badge badge-default me-2 mb-2'
                              >
                                {loading ? <Skeleton animation="wave" /> : trimmedDeliverable}
                              </span>
                            );
                          }
                          return null;
                        })}
                      </>
                    </div>
                    {/* END CAMPAIGN DELIVERABLES */}
                    {/* CAMPAIGN DURATION INFO */}
                    <div className='dashboard-card-camp-dur'>
                      <div className='infl-det-list-sec proposal-dur-info'>
                        <div className='infl-det-list'>
                          <div className='infl-det-list-icon'>
                            <span className="material-symbols-rounded">
                              alarm
                            </span>
                          </div>
                          <div className='infl-det-list-content'>
                            <strong>
                              {loading ? <Skeleton animation="wave" /> : dayjs(res?.proposal_dt).format('LL')}{' '}
                            </strong>
                            <ul>
                              <li>Proposal Deadline</li>
                            </ul>
                          </div>
                        </div>
                        <div className='infl-det-list'>
                          <div className='infl-det-list-icon'>
                            <span className="material-symbols-rounded">
                              calendar_month
                            </span>
                          </div>
                          <div className='infl-det-list-content'>
                            <strong>
                              {loading ? <Skeleton animation="wave" /> : dayjs(res?.go_live_dt).format('LL')}{' '}
                            </strong>
                            <ul>
                              <li>Campaign Live Date</li>
                            </ul>
                          </div>
                        </div>
                        {res?.campaign_visibility && res?.campaign_visibility !== 'Private' &&
                          <div className='infl-det-list d-none d-lg-flex'>
                            <div className='infl-det-list-icon'>
                              <IconLocation />
                            </div>
                            <div className='infl-det-list-content'>
                              <strong>
                                {res?.campaign_locations
                                  ? (() => {
                                    const locationsArray = res.campaign_locations.split(',');
                                    // Remove ':India' from each location
                                    const filteredLocationsArray = locationsArray.map((location: any) =>
                                      location.replace(':India', '')
                                    );
                                    const formattedLocations = filteredLocationsArray.map((location: any) =>
                                      location.replace(/:/g, ', ')
                                    );
                                    const firstTwoLocations = formattedLocations.slice(0, 2).join('; ');

                                    if (formattedLocations.length > 2) {
                                      const moreLocationsCount = filteredLocationsArray.length - 2;
                                      return `${firstTwoLocations}, +${moreLocationsCount} more`;
                                    }

                                    return firstTwoLocations;
                                  })()
                                  : 'Default Value'}
                              </strong>
                              <ul>
                                <li>Location</li>
                              </ul>
                            </div>
                          </div>
                        }
                      </div>
                      {res?.campaign_visibility && res?.campaign_visibility !== 'Private' &&
                        <div className='infl-det-list d-lg-none'>
                          <div className='infl-det-list-icon'>
                            <IconLocation />
                          </div>
                          <div className='infl-det-list-content'>
                            <strong>
                              {res?.campaign_locations
                                ? (() => {
                                  const locationsArray = res.campaign_locations.split(',');
                                  // Remove ':India' from each location
                                  const filteredLocationsArray = locationsArray.map((location: any) =>
                                    location.replace(':India', '')
                                  );
                                  const formattedLocations = filteredLocationsArray.map((location: any) =>
                                    location.replace(/:/g, ', ')
                                  );
                                  const firstTwoLocations = formattedLocations.slice(0, 2).join('; ');

                                  if (formattedLocations.length > 2) {
                                    const moreLocationsCount = filteredLocationsArray.length - 2;
                                    return `${firstTwoLocations}, +${moreLocationsCount} more`;
                                  }

                                  return firstTwoLocations;
                                })()
                                : 'Default Value'}
                            </strong>
                            <ul>
                              <li>Location</li>
                            </ul>
                          </div>
                        </div>
                      }
                      <div className='d-flex align-items-baseline'>
                        <button className='btn btn-primary mt-2 me-4'>
                          View details
                        </button>
                        {res.days_old >= 1 && (
                          <span style={{ color: '#E50000' }} >
                            Application Closed
                          </span>
                        )}
                      </div>
                    </div>
                    {/* END CAMPAIGN DURATION INFO */}
                  </div>
                </div>
              );
            })}

          {/* DASHBOARD CARD POPUP DRAWER */}

          <SwipeableDrawer
            id={`selected-campaign-${campaign_id.campaign_id}`}
            anchor={drawerAnchor}
            open={openCampaignDetailPopup}
            onClose={handleCampaignDetailPopupClose}
            onOpen={handleCampaignDetailPopup}
            transitionDuration={300}
            className='popup-drawer drawer-infl-dashboard'
            disableSwipeToOpen={true}
            hysteresis={0}
            ModalProps={{
              keepMounted: false,
            }}
          >
            {/* POPUP DRAWER HEADER */}
            <div className='popup-drawer-header'>
              <div className='popup-drawer-header-left'>
                <IconButton
                  onClick={() =>
                    handleCampaignDetailPopupClose(campaign_id.campaign_id)
                  }
                >
                  <ChevronLeft className='d-none d-md-inline-flex' />
                  <Close className='d-inline-flex d-md-none' />
                </IconButton>
              </div>
              <div className='popup-drawer-header-right'>
                <div className={campaignDetailDrawerContent?.data?.rows.days_old > 0 ? "d-flex flex-column" : ""}>
                  <div className='d-flex'>
                    <button
                      className='btn btn-outline-primary btn-mui btn-rounded btn-lg'
                      onClick={(event) =>
                        handleClickShare(event, campaignDetailDrawerContent)
                      }
                    >
                      Share
                    </button>

                    {(campaignDetailDrawerContent && campaignDetailDrawerContent?.data?.rows.days_old < 1 &&
                      (campaignDetailDrawerContent?.data?.rows.influencer_gender === "Any" ||
                        campaignDetailDrawerContent?.data?.rows.influencer_gender === props.UserReducer.influencer_ids[0]?.gender)
                    ) ? (
                      <button onClick={handleCampaignApply} className="btn btn-primary btn-mui btn-rounded btn-lg ms-2">
                        {btnName}
                      </button>
                    ) : (
                      (btnName == 'Apply now') ?
                        <button className="btn btn-primary btn-mui btn-rounded btn-lg disabled ms-2">
                          {btnName}
                        </button> :
                        <button onClick={handleCampaignApply} className="btn btn-primary btn-mui btn-rounded btn-lg ms-2">
                          {btnName}
                        </button>
                    )}
                  </div>
                  {campaignDetailDrawerContent && campaignDetailDrawerContent?.data?.rows.days_old >= 1 && btnName === 'Apply now' &&
                    <span className=' text-sm-start text-md-end' style={{ color: '#E50000', marginTop: '10px;' }} >
                      Application Closed
                    </span>
                  }
                  {/* {campaignDetailDrawerContent &&
                    props.UserReducer != null &&
                    campaignDetailDrawerContent?.data?.rows?.influencer_gender !== 'Any' &&
                    (campaignDetailDrawerContent?.data?.rows?.influencer_gender !== props.UserReducer?.influencer_ids?.[0]?.gender ||
                      !props.UserReducer?.influencer_ids?.[0]) &&
                    btnName === 'Apply now' && (
                      <span className='error'>
                        {campaignDetailDrawerContent?.data?.rows?.influencer_gender !== 'Any' ||campaignDetailDrawerContent?.data?.rows?.influencer_gender !== props.UserReducer?.influencer_ids?.[0]?.gender ? `This campaign is open to ${campaignDetailDrawerContent?.data?.rows?.influencer_gender} influencers only.` : "You are not eligible to apply on this campaign."}
                        
                      </span>
                    )} */}

                  {campaignDetailDrawerContent &&
                    props.UserReducer &&
                    btnName === 'Apply now' && (
                      (() => {
                        const influencerGenderRequirement = campaignDetailDrawerContent?.data?.rows?.influencer_gender;

                        // Case when influencerGenderRequirement is null or undefined
                        if (influencerGenderRequirement == null) {
                          return
                        }

                        const userInfluencerGender = props?.UserReducer?.influencer_ids?.[0]?.gender;

                        // If the influencer gender is not 'Any' and the user's gender doesn't match
                        if (
                          influencerGenderRequirement !== 'Any' &&
                          (!userInfluencerGender || influencerGenderRequirement !== userInfluencerGender)
                        ) {
                          return (
                            <span className="error">
                              {influencerGenderRequirement !== 'Any'
                                ? `This campaign is open to ${(influencerGenderRequirement).toLowerCase()} influencers only.`
                                : 'You are not eligible to apply on this campaign.'}
                            </span>
                          );
                        }

                        return null; // If the user meets the gender requirement
                      })()
                    )}


                  {checkInfType.status && btnName === 'Apply now' && <span className='error'>You are not eligible to apply on this campaign.</span>}
                </div>
              </div>
            </div>
            {/* END POPUP DRAWER HEADER */}
            {/* POPUP DRAWER BODY */}
            <div className='popup-drawer-body'>
              <div className='dashboard-card'>
                <div className='dashboard-card-right'>
                  <div className='dashboard-card-right-top'>
                    <div className='dashboard-card-right-top-left'>
                      {(campaignDetailDrawerContent?.data?.rows.reveal_brand_name === 'true') &&
                        <p>{campaignDetailDrawerContent?.data?.rows.brand_name}</p>
                      }
                      <h3 className='dashboard-card-title'>
                        {loading ? <Skeleton animation="wave" /> : campaignDetailDrawerContent?.data?.rows.campaign_title}
                        <span
                          className='ms-2 dashboard-card-title-redirect-link'
                          style={{ verticalAlign: 'text-top' }}
                          onClick={handleCampaign}
                        >
                          <Launch />
                        </span>
                      </h3>
                      <span className='d-block color-blk-5d fs-14 mb-1'>
                        Posted{' '}
                        {loading ? <Skeleton animation="wave" /> : methods.calculateDaysDifference(
                          new Date(
                            campaignDetailDrawerContent?.data?.rows?.posted_at
                          ),
                          new Date()
                        )}{' '}
                        days ago
                      </span>
                    </div>

                    <div className='dashboard-card-right-top-right'>
                      {loading ? <Skeleton animation="wave" /> :
                        <span className='badge badge-primary-outline'>
                          {(
                            campaignDetailDrawerContent?.data?.rows
                              ?.campaign_platform + ''
                          ).toLocaleLowerCase() === 'instagram' ? (
                            <InstagramIcon width="20px" height="20px" />
                          ) : (
                            <YouTubeIcon width="20px" height="20px" />
                          )}

                          {campaignDetailDrawerContent?.data?.rows?.campaign_type
                          }
                        </span>
                      }
                    </div>

                  </div>
                  <strong>Description</strong>
                  <InfluencerCampaignCard
                    description=
                    {loading ? <Skeleton animation="wave" /> : campaignDetailDrawerContent?.data?.rows?.campaign_description
                    }
                    updated_at={loading ? <Skeleton animation="wave" /> :
                      campaignDetailDrawerContent?.data?.rows?.updated_at
                    }
                  />
                </div>
              </div>
              {/* PRODUCT DETAIL SECTION */}
              {campaignDetailDrawerContent?.data?.rows?.campaign_type === "Paid + Barter" || campaignDetailDrawerContent?.data?.rows?.campaign_type === "Barter" ?
                <div className='camp-detail-card grid-2-row'>
                  <strong className='camp-detail-card-sec-title'>
                    Product Details
                  </strong>
                  <div className='camp-detail-card-inner'>
                    <div className='camp-detail-card-left'>
                      <div className='product-detail-info'>
                        <div className='product-detail-img'>
                          <img
                            className='product-image'
                            src={loading ? <Skeleton animation="wave" /> :
                              campaignDetailDrawerContent?.data?.rows
                                ?.product_image_url ? campaignDetailDrawerContent?.data?.rows
                                ?.product_image_url : DummyCampaignImage.default_campaign_image
                            }
                            alt='product cover'
                          />
                        </div>
                        <div className='product-detail-price-info'>
                          <span>
                            {loading ? <Skeleton animation="wave" /> :
                              campaignDetailDrawerContent?.data?.rows
                                ?.product_name
                            }
                          </span>
                          <span>
                            Worth{' '}
                            <strong>
                              {loading ? <Skeleton animation="wave" /> :
                                campaignDetailDrawerContent?.data?.rows
                                  ?.product_cost
                              }
                            </strong>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className='camp-detail-card-right'>
                      <div className='row'>
                        <InfluencerCampaignCard
                          description=
                          {loading ? <Skeleton animation="wave" /> : campaignDetailDrawerContent?.data?.rows?.product_description
                          }
                          updated_at={loading ? <Skeleton animation="wave" /> :
                            campaignDetailDrawerContent?.data?.rows?.updated_at
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                : null}

              {/* END - PRODUCT DETAIL SECTION */}
              {/* DELIVERABLE SECTION */}
              <div className='camp-detail-card grid-1-col'>
                <strong className=' camp-detail-card-sec-title'>
                  Deliverables
                </strong>
                <div className='camp-detail-card-inner grid-3-col '>
                  <DeliverableInfo deliverablesData={campaignDetailDrawerContent?.data?.rows} loading={loading} />
                  {/* {campaignDetailDrawerContent?.data?.rows?.deliverables.map((mainDeliverable: any) => {
                    return <div className={campaignDetailDrawerContent?.data?.rows.influencer_type &&
                      mainDeliverable.influencer_type.includes(
                        campaignDetailDrawerContent?.data?.rows?.influencer_type
                      )
                      ? "deliverable-list active-deliverable "
                      : "deliverable-list"}>
                      <strong> {loading ? <Skeleton animation="wave" /> : mainDeliverable?.influencer_type}</strong>
                      <ul>
                        {mainDeliverable?.deliverables?.map((deliverable: any, index: number) => {
                          return <li key={index}>{loading ? <Skeleton animation="wave" /> : deliverable.split(",")}</li>
                        })}
                      </ul>
                    </div>
                  })} */}
                </div>
              </div>
              {/* DELIVERABLE SECTION ENDS */}
              {/* INFLUENCER DETAIL SECTION */}
              <div className='camp-detail-card grid-2-row'>
                <strong className='camp-detail-card-sec-title'>
                  Influencer Details
                </strong>
                <div className='infl-det-list-sec'>
                  <div className='infl-det-list'>
                    <div className='infl-det-list-icon'>
                      <IconGender />
                    </div>
                    <div className='infl-det-list-content'>
                      <strong>Gender </strong>

                      <ul>
                        <li> {loading ? <Skeleton animation="wave" /> : campaignDetailDrawerContent?.data?.rows?.influencer_gender}</li>
                      </ul>
                    </div>
                  </div>
                  {campaignDetailDrawerContent?.data?.rows?.categories &&
                    <div className='infl-det-list'>
                      <div className='infl-det-list-icon'>
                        <IconCategory />
                      </div>
                      <div className='infl-det-list-content'>
                        <strong>Category </strong>
                        <ul>
                          {
                            campaignDetailDrawerContent?.data?.rows?.categories &&
                              campaignDetailDrawerContent?.data?.rows?.categories.trim() !== "" ? campaignDetailDrawerContent?.data?.rows?.categories.split(",").map((cate: string) => { return <li> {loading ? <Skeleton animation="wave" /> : cate}</li> })
                              : null
                          }

                        </ul>
                      </div>
                    </div>
                  }
                  <div className='infl-det-list'>
                    <div className='infl-det-list-icon'>
                      <IconLocation />
                    </div>
                    <div className='infl-det-list-content'>
                      <strong>Location </strong>
                      <ul>
                        {campaignDetailDrawerContent?.data?.rows?.group_locations &&
                          campaignDetailDrawerContent?.data?.rows?.group_locations.trim() !== ""
                          ? (() => {
                            const locationsArray =
                              campaignDetailDrawerContent?.data?.rows?.group_locations.split(',');
                            const formattedLocations =
                              locationsArray.map((location: any) =>
                                location.replace(/:/g, ',')
                              );
                            const firstTwoLocations = formattedLocations.join(', ');
                            return <li> {loading ? <Skeleton animation="wave" /> : firstTwoLocations}</li>;
                          })()
                          : <li>All Locations</li>}

                        {/* 
                        <li>Delhi, Delhi, India</li>
                        <li>Mumbai, Maharashtra, India</li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* END - INFLUENCER DETAIL SECTION */}
              {/* REFERENCE LINKS */}
              {campaignDetailDrawerContent?.data?.rows?.reference_link_1 || campaignDetailDrawerContent?.data?.rows?.reference_link_2 || campaignDetailDrawerContent?.data?.rows?.reference_link_3 ?
                <div className='camp-detail-card grid-2-row'>
                  <strong className='camp-detail-card-sec-title'>
                    Reference Links
                  </strong>
                  <div className='camp-detail-card-left'>
                    <div>
                      {campaignDetailDrawerContent?.data?.rows?.reference_link_1 && <p className='reference-link'> <Link target='_blank' to={
                        campaignDetailDrawerContent?.data?.rows?.reference_link_1}>
                        {campaignDetailDrawerContent?.data?.rows?.reference_link_1}</Link></p>}
                      {campaignDetailDrawerContent?.data?.rows?.reference_link_2 && <p className='reference-link'> <Link target='_blank' to={
                        campaignDetailDrawerContent?.data?.rows?.reference_link_2}>
                        {campaignDetailDrawerContent?.data?.rows?.reference_link_2}</Link></p>}
                      {campaignDetailDrawerContent?.data?.rows?.reference_link_3 && <p className='reference-link'> <Link target='_blank' to={
                        campaignDetailDrawerContent?.data?.rows?.reference_link_3}>
                        {campaignDetailDrawerContent?.data?.rows?.reference_link_3}</Link></p>}
                    </div>
                  </div>
                </div>
                : null
              }
              {/* END - REFERENCE LINKS */}
              {/* COMMENTS SECTION */}
              {campaignDetailDrawerContent?.data?.rows?.comments && (
                <div className='camp-detail-card grid-1-col'>
                  <strong className=' camp-detail-card-sec-title'>
                    Comments
                  </strong>
                  <p className='commemt'>{loading ? <Skeleton animation="wave" /> : campaignDetailDrawerContent?.data?.rows?.comments}</p>
                </div>
              )}
              {/* COMMENTS SECTION ENDS */}
              {/* CAMPAIGN LIVE DATE */}
              <div className='camp-detail-card grid-2-row'>
                <div className='infl-det-list-sec proposal-dur-info'>
                  {campaignDetailDrawerContent?.data?.rows?.proposal_dt &&
                    <div className='infl-det-list'>
                      <div className='infl-det-list-icon'>
                        <span className='material-symbols-rounded'>
                          alarm
                        </span>
                      </div>
                      <div className='infl-det-list-content'>
                        <strong>{loading ? <Skeleton animation="wave" /> : dayjs(campaignDetailDrawerContent?.data?.rows?.proposal_dt).format('LL')} </strong>
                        <ul>
                          <li>Proposal Deadline</li>
                        </ul>
                      </div>
                    </div>
                  }
                  {campaignDetailDrawerContent?.data?.rows?.go_live_dt &&
                    <div className='infl-det-list'>
                      <div className='infl-det-list-icon'>
                        <span className='material-symbols-rounded'>
                          calendar_month
                        </span>
                      </div>
                      <div className='infl-det-list-content'>
                        <strong>{loading ? <Skeleton animation="wave" /> : dayjs(campaignDetailDrawerContent?.data?.rows?.go_live_dt).format('LL')}</strong>


                        <ul>
                          <li>Campaign Live Date</li>
                        </ul>
                      </div>
                    </div>
                  }


                </div>
              </div>
              {/* END CAMPAIGN LIVE DATE*/}


            </div>

            {/* END POPUP DRAWER BODY */}
          </SwipeableDrawer>
          {/* FILTER DRAWER */}
          <CustomDrawer
            open={openFilterPopup}
            onClose={handleFilterPopupClose}
            drawerTitle='Filters'
          >
            <div className='custom-drawer-body'>
              <div className='custom-drawer-body-inner'>
                <div className='filter-body'>
                  <span className='me-3 fw-500 fs-20'>Platform</span>
                  <div className='d-flex input-checkbox-box-container  filter-social-platform filter-content-group'>
                    <FormControlLabel
                      className={
                        selectedPlatforms.instagram
                          ? 'input-checkbox-box filter-social-platform-icon icon-active filter-content-outline'
                          : 'input-checkbox-box filter-social-platform-icon filter-content-outline'
                      }
                      control={
                        <Checkbox
                          checked={selectedPlatforms.instagram}
                          className='p-0'
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => handleCheckboxChangePlatform('instagram')}
                          icon={
                            <InstagramIcon
                              className='icon-instagram'
                              width={24}
                            />
                          }
                          checkedIcon={
                            <InstagramIcon
                              className='icon-instagram'
                              width={24}
                            />
                          }
                          name='Instagram'
                        />
                      }
                      label='Instagram'
                    />
                    <FormControlLabel
                      className={
                        selectedPlatforms.youtube
                          ? 'input-checkbox-box filter-social-platform-icon icon-active filter-content-outline'
                          : 'input-checkbox-box filter-social-platform-icon filter-content-outline'
                      }
                      control={
                        <Checkbox
                          checked={selectedPlatforms.youtube}
                          className='p-0'
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => handleCheckboxChangePlatform('youtube')}
                          icon={
                            <IconYouTube
                              className='icon-youtube'
                              width={28}
                            />
                          }
                          checkedIcon={
                            <IconYouTube
                              className='icon-youtube'
                              width={28}
                            />
                          }
                          name='Youtube'
                        />
                      }
                      label='YouTube'
                    />
                  </div>
                </div>
                <div className='filter-body'>
                  <span className='me-3 fw-500 fs-20'>Payment Type</span>
                  <div className='d-flex flex-wrap filter-content-group wo-border'>
                    <FormControlLabel
                      className='filter-content-outline custom-checkbox'
                      control={
                        <Checkbox
                          className='p-0 pe-1'
                          checked={paymentCheckboxes.Paid}
                          onChange={handleCheckboxChangePayment('Paid')}
                        />
                      }
                      label='Paid'
                    />
                    <FormControlLabel
                      className='filter-content-outline custom-checkbox'
                      control={
                        <Checkbox
                          className='p-0 pe-1'
                          checked={paymentCheckboxes.Barter}
                          onChange={handleCheckboxChangePayment('Barter')}
                        />
                      }
                      label='Barter'
                    />
                  </div>
                </div>
                <div className='filter-body'>
                  <span className='me-3 fw-500 fs-20'>
                    Show only (campaigns)
                  </span>
                  <div className='d-flex flex-wrap filter-content-group  wo-border'>
                    <FormControlLabel
                      className='filter-content-outline custom-checkbox'
                      control={
                        <Checkbox
                          className='p-0'
                          checked={showOnlyCheckboxes.Public}
                          onChange={handleCheckboxShowOnly('Public')}
                        />
                      }
                      label='Relevant'
                    />
                    <FormControlLabel
                      className='filter-content-outline custom-checkbox'
                      control={
                        <Checkbox
                          className='p-0'
                          checked={showOnlyCheckboxes.Private}
                          onChange={handleCheckboxShowOnly('Private')}
                        />
                      }
                      label='Invited'
                    />
                  </div>
                </div>
                <div className='filter-body'>
                  <span className='me-3 fw-500 fs-20'>
                    Location
                  </span>
                  <div className='filter-content-group  wo-border'>
                    <FormControlLabel
                      className='filter-content-outline custom-checkbox'
                      control={
                        <Checkbox
                          className='p-0'
                          checked={locationCheckbox}
                          onChange={handleLocation}
                        />
                      }
                      label='View campaign based on my location'
                    />
                  </div>
                </div>
              </div>
              <div
                className='d-flex justify-content-end custom-drawer-footer'
                style={{ borderTop: '1px solid #d7d7d7' }}
              >
                <button
                  className='btn btn-outline-primary '
                  onClick={handleClearAllPopup}
                >
                  Clear All
                </button>
                <button
                  className='btn btn-primary '
                  style={{ marginLeft: '10px' }}
                  onClick={showFilterCampaignData}
                  disabled={
                    !filterCampaignList || filterCampaignList.length === 0
                  }
                >
                  Show Results ({filterCampaignList.length})
                </button>
              </div>
            </div>
          </CustomDrawer>
          {/* CUSTOM DRAWER SHARE */}
          <CustomDrawer
            open={selectedCampaign}
            onClose={handleCustomDrawerClose}
            drawerTitle='Share'
            className="share-drawer"
          >
            <div className='custom-drawer-body'>
              <div className='share-sec-container'>
                <div className='share-icons'>
                  <TwitterShareButton
                    url={shareUrl}
                    className='Demo__some-network__share-button'
                  >
                    <XIcon size={24} round={true} />
                    <span className='share-titles'>X</span>
                  </TwitterShareButton>
                </div>
                <div className='share-icons'>
                  <FacebookShareButton
                    url={shareUrl}
                    className='Demo__some-network__share-button'
                  >
                    <FacebookIcon style={{ color: '#1877F2' }} />
                    <span className='share-titles'>Facebook</span>
                  </FacebookShareButton>
                </div>
                <div className='share-icons'>
                  <WhatsappShareButton
                    url={shareUrl}
                    className='Demo__some-network__share-button'
                  >
                    <WhatsAppIcon style={{ color: '#25D366' }} />
                    <span className='share-titles'>WhatsApp</span>
                  </WhatsappShareButton>
                </div>
                <div className='share-icons' style={{ color: '#00aae5' }}>
                  <button type='button' onClick={() => copyToClipboard()}>
                    <ContentCopyIcon />
                    <span className='share-titles'>Copy Link</span>
                  </button>
                </div>
              </div>
            </div>
          </CustomDrawer>
        </div>
        {
          applyRestrictionPopup &&
          <ApplyRestrictionPopup
            openModal={applyRestrictionPopup}
            onClose={(closed: boolean) => setApplyRestrictionPopup(closed)}
            applyRestrictionData={applyRestrictionData}
            platform={campaign_id.campaign_platform}
            stateToUpdateUserReducer={stateToUpdateUserReducer}
            stateToUpdateUserReducerfuntion={(data) => { setStateToUpdateUserReducer(data) }}
          />
        }
        {showWelcomePopUp && (
          <ShowWelcomePopUp
            openUpgradeBrandModal={showWelcomePopUp}
            onClose={() => setShowWelcomePopUp(false)}
            popupMinWidth='630px'
          />
        )}

      </section >
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
    UserID: state.UserID.userID,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
    selectedInf: (data: any) => dispatch(SelectedCampaign(data)),
    updateUser: (data: any) => dispatch(UserLogin(data)),
  };
};

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(InfluencerDashbaord);

export { connectToRedux as InfluencerDashbaord };
