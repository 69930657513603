import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./send-invoice.scss";
import { Loading } from "../../../../redux/action";
import { connect } from "react-redux";
import { InvoiceService, LocalService } from "../../../../core/services";
import CommonPopup from "../../../../components/popup/common-popup";
import { LoadingButton } from "@mui/lab";
import ProposalPaymentTable from "../../../brand/plans/campaings/campaign-view/payments/proposal_payment_table";
// For upload media component
import FileUploadPopup from "../../../../components/popup/file-upload-popup";
// import ProposalPaymentTableWhatsApp from "../../../brand/plans/campaings/campaign-view/payments/proposal_payment_whatsapp_flow";
import * as gtUtil from "../../../../core/utility";
import AccountDetails from "../../../brand/plans/campaings/campaign-view/payments/account-details";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import RenderDownloadInvoiceButtons from "../../../brand/plans/campaings/campaign-view/payments/render-download-invoice-buttons";
import RenderAcknoledgeReceiptForm from "../../../brand/plans/campaings/campaign-view/payments/render-acknowledge-receipt-form";

const invoiceService = new InvoiceService();
const localService = new LocalService();

interface Props {
  UserReducer: any;
  UserID: any;
  loading: (data: any) => void;
  selectedCampaign: any;
}

interface Invoice {
  file_url: string;
  file_name: string;
  total_amount: number; // invoice total amount.
}

interface Proposal {
  total_price: number;
  name: string;
  influencer_id: number;
  proposal_id: number;
  payment_status: string;
  account_type?: string;
  bank_name?: string;
  ifsc_code?: string;
  account_holder_name?: string;
  account_number?: string;
  address?: string;
  micr_code?: string;
  // file_urls: string;
  invoices: Invoice[];
}

const InfluencerSendInvoice: React.FC<Props> = (props: Props) => {
  const [showInvoiceGenerateBtn, setShowInvoiceGenerateBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  // to store accepted proposal details
  const [acceptedProposalPaymentDetails, setAcceptedProposalPaymentDetails] =
    useState<Proposal[]>([]);

  // for upload Invoice
  // const [file, setFile] = useState<any>()
  const path = `accounts/${props.selectedCampaign.influencerId}/campaign_invoices`;

  const [formData, setFormData] = useState({
    user_id: props?.UserReducer != null ? props?.UserReducer?.user_id : 0,
    account_id: props.UserReducer != null ? props.UserReducer.account_id : 0,
    influencer_id: props.selectedCampaign.influencerId,
    sm_id: props.selectedCampaign.smId,
    campaign_id: props.selectedCampaign.campaign_id,
    proposal_id: props.selectedCampaign.proposalId,
    file_path: "",
    file_url: "",
    file_type: "",
    file_name: "",
    storage_size: "",
    total_amount: "",
  });

  const [formError, setFormError] = useState({
    total_amount: { touched: false, status: false, message: "" },
    file_path: { status: false, message: "" },
  });
  // set foc popup and gstin popup state
  const [popUps, setPopUps] = useState({
    openUploadInvoiceModal: false,
    openUpdateSettingsModal: false,
    openUploadInvoicePaymentModal: false,
  });
  const getParams = new URLSearchParams(window.location.search);

  async function showGenrateInvoiceButton() {
    try {
      let influencer_id = 0;
      if (props.UserReducer !== null) {
        let sm_id = Number(getParams.get("smId"));
        if (props.selectedCampaign.campaign_type !== "Barter") {
          for (let data of props.UserReducer.influencer_ids) {
            if (
              props.selectedCampaign.campaign_platform === data.platform_type &&
              sm_id === (data.ig_id || data.yt_id)
            ) {
              influencer_id = data?.influencer_id;
              sm_id = data?.ig_id || data.yt_id;
            }
          }
        }

        if (influencer_id > 0) {
          const response = await invoiceService.allowToGenrateInvoice({
            user_id:
              props?.UserReducer != null ? props?.UserReducer?.user_id : 0,
            account_id:
              props.UserReducer != null ? props.UserReducer.account_id : 0,
            campaign_id: props.selectedCampaign.campaign_id || 0,
            influencer_id: influencer_id,
            sm_id: sm_id,
          });
          setShowInvoiceGenerateBtn(response.data.status.status);
        }
      } else {
        const response = await invoiceService.allowToGenrateInvoice({
          user_id: 0,
          account_id: 0,
          campaign_id: props.selectedCampaign.campaign_id || 0,
          influencer_id: props.selectedCampaign.influencerId,
          sm_id: props.selectedCampaign.smId,
        });
        setShowInvoiceGenerateBtn(response.data.status.status);
      }
    } catch (error) {
      throw new Error(`showGenrateInvoiceButton error: ${error}`);
    }
  }

  async function getAcceptedProposalPaymentDetails() {
    try {
      const response: any =
        await invoiceService.getAcceptedProposalPaymentDetails({
          campaign_id: props.selectedCampaign.campaign_id,
          influencer_id: props.selectedCampaign.influencerId,
        });

      if (response.status === gtUtil.Constants.SUCCESS) {
        setAcceptedProposalPaymentDetails(response.data.rows);
      } else {
        setAcceptedProposalPaymentDetails([]);
      }
    } catch (error) {
      throw new Error(`getAcceptedProposalPaymentDetails error :: ${error}`);
    }
  }

  useEffect(() => {
    //temp comment
    showGenrateInvoiceButton();
    getAcceptedProposalPaymentDetails();
  }, [props.selectedCampaign, props.UserReducer]);
  async function generate_invoice() {
    try {
      setLoading(true);
      let influencer_id = 0;
      let sm_id = Number(getParams.get("smId"));
      for (let data of props.UserReducer.influencer_ids) {
        if (
          props.selectedCampaign.campaign_platform === data.platform_type &&
          sm_id === (data.ig_id || data.yt_id)
        ) {
          influencer_id = data?.influencer_id;
          sm_id = data?.ig_id || data.yt_id;
        }
      }

      if (influencer_id > 0) {
        const response: any = await invoiceService.generateInvoice({
          user_id: props.UserReducer.user_id,
          account_id: props.UserReducer.account_id,
          campaign_id: props.selectedCampaign.campaign_id,
          proposal_id: props.selectedCampaign.proposalId,
          campaign_platform: props.selectedCampaign.campaign_platform,
          influencer_id: influencer_id,
          sm_id: sm_id,
        });

        if (response.status === gtUtil.Constants.SUCCESS) {
          // let baseUrl = window.location.origin + '/print/invoice';
          let baseUrl = `${window.location.origin}/print/invoice?id=${response.data.rows[0].id}`;
          window.open(baseUrl, "_blank");
        } else {
          // localService.toastify(response.data.status.message, 'info', 1000);
          setPopUps({ ...popUps, openUpdateSettingsModal: true });
        }
      }
    } catch (error) {
      throw new Error(`generate_invoice :: ${error}`);
    } finally {
      setLoading(false);
    }
  }
  function handleCloseUploadInvoiceModal() {
    setPopUps({ ...popUps, openUploadInvoiceModal: false });
  }

  function handleCloseUpdateSettingsModal() {
    setPopUps({ ...popUps, openUpdateSettingsModal: false });
  }
  function handleCloseUploadInvoicePaymentModal() {
    setPopUps({ ...popUps, openUploadInvoicePaymentModal: false });
    resetForm();
  }
  const validateForm = () => {
    let isValid = true;
    const errors = { ...formError };

    // Validate invoice amount
    if (!formData.total_amount || isNaN(Number(formData.total_amount))) {
      errors.total_amount = {
        touched: true,
        status: true,
        message: "Please enter a valid invoice amount.",
      };
      isValid = false;
    } else {
      errors.total_amount = { touched: true, status: false, message: "" };
    }

    // Validate file upload
    if (!formData.file_path) {
      errors.file_path = {
        status: true,
        message: "Please upload an invoice file before sending.",
      };
      isValid = false;
    } else {
      errors.file_path = { status: false, message: "" };
    }

    setFormError(errors);
    return isValid;
  };
  const insertCampaignInvoiceDetail = async (e: any) => {
    e.preventDefault();

    // Validate the form
    if (!validateForm()) return;

    try {
      setLoading(true);
      const response:any = await invoiceService.uploadCampaignInvoice(formData);
      console.log("total_amount", response)
      const checkStatus = (response.status === gtUtil.Constants.SUCCESS) ? "success" : "error";

      // Show success or error message
      localService.toastify(response.info, checkStatus, 1000);

      handleCloseUploadInvoicePaymentModal();
      resetForm();
    } catch (error) {
      console.error(`Something went wrong :: ${error}`);
    } finally {
      setLoading(false);
      getAcceptedProposalPaymentDetails();
    }
  };
  const resetForm = () => {
    setFormData({
      user_id: props?.UserReducer != null ? props?.UserReducer?.user_id : 0,
      account_id: props.UserReducer != null ? props.UserReducer.account_id : 0,
      influencer_id: props.selectedCampaign.influencerId,
      sm_id: props.selectedCampaign.smId,
      campaign_id: props.selectedCampaign.campaign_id,
      proposal_id: props.selectedCampaign.proposalId,
      file_path: "",
      file_url: "",
      file_type: "",
      file_name: "",
      storage_size: "",
      total_amount: "",
    });
    setFormError({
      total_amount: { touched: false, status: false, message: "" },
      file_path: { status: false, message: "" },
    });
  };
  // const insertCampaignInvoiceDetail = async (e: any) => {
  //   try {
  //     const response = await invoiceService.uploadCampaignInvoice(formData);
  //     const checkStatus = response.data.status.status ? "success" : "error";
  //     // localService.toastify(response.data.status.message, checkStatus, 1000);
  //     handleCloseUploadInvoicePaymentModal();
  //   } catch (error) {
  //     console.error(`Something went wrong :: ${error}`);
  //   } finally {
  //     setLoading(false);
  //     getAcceptedProposalPaymentDetails();
  //   }
  // };
  const insertUploadInvoiceDetail = async (e: any) => {
    try {
      const updatedFormData = {
        ...formData,
        file_url: e.url,
        file_path: e.key,
        file_type: e.fileType,
        file_name: e.fileName,
        storage_size: e.storage_size,
      };
      setFormData(updatedFormData);

      handleCloseUploadInvoiceModal();
    } catch (error) {
      console.error(`Something went wrong :: ${error}`);
    } finally {
      setLoading(false);
    }
  };

  {
    console.log(
      "acceptedProposalPaymentDetails SEnd Invoice",
      acceptedProposalPaymentDetails
    );
  }
  const onUpdatePaymentStatus = () => {};
  return (
    <div className="invoice-info-sec">
      <div className="row generate_invoice mt-3 mb-3  align-items-center justify-content-center">
        <div className="invoice-upload-generate mb-4">
          <div>
            <button
              disabled={!showInvoiceGenerateBtn}
              type="button"
              className="btn btn-outline-primary"
              onClick={() =>
                setPopUps({ ...popUps, openUploadInvoicePaymentModal: true })
              }
            >
              Upload Invoice
            </button>

            {props.UserReducer !== null && (
              <button
                disabled={!showInvoiceGenerateBtn}
                className="btn btn-outline-primary ms-2 me-2"
                onClick={generate_invoice}
              >
                Generate Invoice
              </button>
            )}
          </div>
          {!showInvoiceGenerateBtn && (
            <>
              <span>
                you can't upload/generate the invoice until your proposal is
                accepted.
              </span>
            </>
          )}
        </div>
        {showInvoiceGenerateBtn && (
          <div className="proposal-table">
            <strong className="snapshot-card-title pb-1 d-block">
              Payout Details
            </strong>
            <div className="table-responsive">
              <TableContainer>
                <Table className="table table-bottom-influencer common-table">
                  <TableHead className="common-table-header">
                    <TableRow>
                      <TableCell>#</TableCell>
                      <TableCell>Due Amount (Excl Taxes)</TableCell>
                      <TableCell>Proposal Payment Status</TableCell>
                      <TableCell>Account Detail</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className="common-table-body">
                    {acceptedProposalPaymentDetails.map((detail, index) => (
                      <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{detail.total_price}</TableCell>
                        <TableCell>{detail.payment_status}</TableCell>

                        <TableCell>
                          {detail.account_type ? (
                            <AccountDetails account={detail} />
                          ) : (
                            "NA"
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="payment-advice-sec">
              {acceptedProposalPaymentDetails.map((detail, index) => {
                console.log(detail, "detail");
                return (
                  <>
                    <RenderDownloadInvoiceButtons proposal={detail} UserReducer={props?.UserReducer} acceptedProposalPaymentDetails={acceptedProposalPaymentDetails} selectedCampaign={props?.selectedCampaign} />
                  </>
                );
              })}
              {/* <RenderAcknoledgeReceiptForm acceptedProposalPaymentDetails={acceptedProposalPaymentDetails} proposal={acceptedProposalPaymentDetails[0]} UserReducer={props.UserReducer} onUpdatePaymentStatus={onUpdatePaymentStatus}/> */}
            </div>
            {/* <span>
                <p>Due Amount:</p>
                <p>
                  {acceptedProposalPaymentDetails.map((detail, index) => (
                    <React.Fragment key={index}>
                      {detail.total_price}
                    </React.Fragment>
                  ))}
                </p>
              </span>
              <span>
                <p>Proposal Payment Status:</p>
                <p>
                  {acceptedProposalPaymentDetails.map((detail, index) => (
                    <React.Fragment key={index}>
                      {detail.payment_status}
                    </React.Fragment>
                  ))}
                </p>
              </span>
              <span>
                <p>Account Detail:</p>
                <p>
                  {acceptedProposalPaymentDetails.map((detail, index) => (
                    <React.Fragment key={index}>
                      {detail.account_type
                        ?
                        <AccountDetails account={detail} />
                        :
                        'NA'
                      }
                    </React.Fragment>
                  ))}
                </p>
              </span> */}
          </div>
        )}
        {popUps.openUploadInvoicePaymentModal && (
          <CommonPopup
            modalTitle="Upload Invoice"
            openModal={popUps.openUploadInvoicePaymentModal}
            onClose={() => handleCloseUploadInvoicePaymentModal()}
            className="modal-small mx-auto"
          >
            <div className="row">
              <div className="col">
                <div className="row mb-3">
                  <TextField
                    className={`w-100 input-style input-common ${formError.total_amount.status ? "" : "mb-3"}`}
                    size="small"
                    autoFocus
                    name="total_amount"
                    placeholder="Enter Invoice Amount"
                    label="Enter Invoice Amount"
                    variant="outlined"
                    onChange={(e) => {
                      const value = e.target.value;
                      setFormData((prev) => ({
                        ...prev,
                        total_amount: value,
                      }));
                      setFormError((prev) => ({
                        ...prev,
                        total_amount: {
                          ...prev.total_amount,
                          touched: true,
                        },
                      }));
                    }}
                    value={formData.total_amount}
                    error={
                      formError.total_amount.touched &&
                      formError.total_amount.status
                    }
                    // helperText={formError.total_amount.touched && formError.total_amount.message}
                  />
                  {formError.total_amount.status && (
                    <span className="error mb-3">
                      {formError.total_amount.message}
                    </span>
                  )}
                  {/* <span className="error">
                                        This field is required.
                                      </span> */}
                  {/* <span className="error">{errorFullName?.message}</span> */}
                  <button
                    disabled={!showInvoiceGenerateBtn}
                    type="button"
                    className={`btn btn-outline-primary ${formError.file_path.status ? "" : "mb-3"}`}
                    onClick={() =>
                      setPopUps({ ...popUps, openUploadInvoiceModal: true })
                    }
                  >
                    Upload Invoice
                  </button>
                  {formError.file_path.status && (
                    <span className="error mb-3">{formError.file_path.message}</span>
                  )}
                  {/* <button
                    onClick={(e) => insertCampaignInvoiceDetail(e)}
                    className="btn btn-primary"
                  >
                    Send Invoice
                  </button> */}
                  <button
                    disabled={loading}
                    onClick={insertCampaignInvoiceDetail}
                    className="btn btn-primary"
                  >
                    {loading ? "Sending..." : "Send Invoice"}
                  </button>
                </div>
              </div>
            </div>

            <FileUploadPopup
              openModal={popUps.openUploadInvoiceModal}
              onClose={(e) => handleCloseUploadInvoiceModal()}
              // onSend={(e: any) =>
              //   insertCampaignInvoiceDetail(e)
              // }
              onSend={(e: any) => insertUploadInvoiceDetail(e)}
              path={path}
              acceptedFileTypes=".doc, .docx, .pdf"
            />
          </CommonPopup>
        )}
        <CommonPopup
          modalTitle="Update details"
          modalContent="One or more items from the below mentioned details is missing. To generate the invoice, please complete the following details. This is a one time process and wont take more than a few minutes."
          openModal={popUps.openUpdateSettingsModal}
          onClose={() => handleCloseUpdateSettingsModal()}
          className="modal-medium mx-auto"
        >
          <div>
            <ol>
              <li>Address details</li>
              <li>
                Company details
                <small> (Optional)</small>
              </li>
              <li>Account details</li>
            </ol>
            <NavLink
              className="btn btn-primary mb-2"
              to="/influencer/settings/companydetails"
              target="_blank"
            >
              Update now
            </NavLink>
          </div>
        </CommonPopup>
      </div>
      {/* {showInvoiceGenerateBtn && (
        <>
          <ProposalPaymentTable
            UserReducer={
              props?.UserReducer != null
                ? props?.UserReducer
                : {
                    user_id: 0,
                    account_id: 0,
                    influencer_id: props.selectedCampaign.influencerId,
                  }
            }
            acceptedProposalPaymentDetails={acceptedProposalPaymentDetails}
            selectedCampaign={props.selectedCampaign}
          />
        </>
      )} */}
      {/* {showInvoiceGenerateBtn && props?.UserReducer == null &&
        <ProposalPaymentTableWhatsApp acceptedProposalPaymentDetails={acceptedProposalPaymentDetails} selectedCampaign={props.selectedCampaign} />
      } */}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
    UserID: state.UserID.userID,
    selectedCampaign: state.InfSelectCamp.campaign,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(InfluencerSendInvoice);

export { connectToRedux as InfluencerSendInvoice };
