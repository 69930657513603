import React, { useState } from "react";
import "../../brand/settings/main-settings.scss";
import { Outlet, useNavigate, NavLink } from "react-router-dom";
import HeadingStyle from "../../../components/heading-style/heading-style";
import { Tab, Tabs } from "@mui/material";
import { BASE_URL_WEB } from "../../../config/config";
import MaterialSymbol from "../../../components/icons/material-symbol";

export const TalentPartnerSetting: React.FC = () => {
  return (
    <>
      <div className="profile_settings_container">
        <div className="container">
          <HeadingStyle title="Settings" className="pb-4 d-block" />
          <div className="row profile_settings_main_container">
            <div className="col-md-3 profile_settings_left_side d-none d-md-flex list-section-sidebar flex-column">
              <ul className="">
                <li>
                  <NavLink
                    className={
                      "profile_settings_navlinks list-section-sidebar-link"
                    }
                    to={"personal-info"}
                  >
                    Personal Information
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={
                      "profile_settings_navlinks list-section-sidebar-link"
                    }
                    to={"companydetails"}
                  >
                    Company Details
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={
                      "profile_settings_navlinks list-section-sidebar-link"
                    }
                    to={"security"}
                  >
                    Security
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={
                      "profile_settings_navlinks list-section-sidebar-link"
                    }
                    to={"subscription"}
                  >
                    Influencer Subscriptions
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={
                      "profile_settings_navlinks list-section-sidebar-link"
                    }
                    to={"subscription-history"}
                  >
                    Subscription history
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className={
                      "profile_settings_navlinks list-section-sidebar-link"
                    }
                    to={"notifications"}
                  >
                    Notification
                  </NavLink>
                </li>
              </ul>
              <div className="d-inline-flex flex-wrap">
                <NavLink
                  className={
                    "profile_settings_navlinks list-section-sidebar-link fs-14 py-0 profile_settings_policy"
                  }
                  to={`${BASE_URL_WEB}/privacy_policy`}
                  target="_blank"
                >
                  Privacy Policy
                </NavLink>
              </div>
            </div>
            <Tabs
              aria-label="nav tabs example"
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
              className="d-flex d-md-none nav sub-tabs nav-rounded"
            >
              <NavLink
                className="tab-underline-menu nav-link"
                to={"personal-info"}
              >
                Personal Information
              </NavLink>
              <NavLink
                className="tab-underline-menu nav-link"
                to={"companydetails"}
              >
                Company Details
              </NavLink>
              <NavLink className="tab-underline-menu nav-link" to={"security"}>
                Security
              </NavLink>
              <NavLink
                className={"tab-underline-menu  nav-link"}
                to={"subscription"}
              >
                Talent Subscriptions
              </NavLink>
              <NavLink
                className={"tab-underline-menu nav-link"}
                to={"subscription-history"}
              >
                Subscription history
              </NavLink>
              <NavLink
                className="tab-underline-menu nav-link"
                to={"notifications"}
              >
                Notification
              </NavLink>
            </Tabs>

            <div className="col-md-9 profile_settings_right_side ">
              <Outlet />
            </div>
           
          </div>
        </div>
      </div>
    </>
  );
};
