import React, { useEffect, useState } from 'react'
import { Grid, IconButton, SwipeableDrawer, TextField } from '@mui/material'
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { ListService, LocalService } from '../../core/services';
import { Editor } from '../editor/editor';
import { FormValidation } from '../../core/utility';

const listService: ListService = new ListService()
const localService: LocalService = new LocalService();
const formValidation: FormValidation = new FormValidation();


type Props = {
  open: any;
  onClose: any;
  onOpen: any;
  closeDrawer: any;
  planInfo: any;
  selectedPlanName: any;
  planId?: any;
  onSuccess?: (planName: string) => void;
}

export default function PlanDetailDrawer({ closeDrawer, open, onOpen, onClose, planInfo, selectedPlanName, planId, onSuccess }: Props) {
  const [showEdit, setShowEdit] = useState(true);
  const [formData, setFormData] = useState({
    plan_id: planId,
    plan_name: '',
    plan_description: ''
  });
  const [formError, setFormError] = useState({
    plan_name: { status: true, message: "" },
    plan_description: { status: true, message: "" },
  });
  
  useEffect(() => {
    setFormData({
      plan_id: planId,
      plan_name: selectedPlanName || '',
      plan_description: planInfo?.description || '',
    });
  }, [selectedPlanName, planInfo, planId, showEdit]);

 
  const handleFormData = async (e: React.FormEvent) => {
    e.preventDefault();
    // props.loading(true);
  
    try {
      // Validate the form inputs
      const isValidPlanName = formValidation.GetNameValidate(formData?.plan_name);
      const isValidPlanDescription = formValidation.getdescriptionIsValid(formData.plan_description);
  
      // Set form error state based on validation results
      setFormError((prevData) => ({
        ...prevData,
        plan_name: isValidPlanName,
        plan_description: isValidPlanDescription,
      }));
  
      // Proceed only if both validations pass
      if (isValidPlanName.status && isValidPlanDescription.status) {
        const res = await listService.editPlanDetails(formData);
  
        if (res.data.status.status) {
          localService.toastify("Plan details updated successfully", 'success', 1000);
          onSuccess?.(formData?.plan_name);
          setShowEdit(true);
          closeDrawer();
        } else {
          localService.toastify(res.data.status.message, 'error', 1000);
          setShowEdit(true);
          closeDrawer();
        }
      }
    } catch (error) {
      localService.toastify('An error occurred while updating plan details.', 'error', 1000);
      console.error(`Error updating plan details: ${error}`);
    } finally {
      // props.loading(false);
    }
  };
  
  const closeDrawerReset = () => {
    setShowEdit(true);
    closeDrawer();
  };
  return (
    <SwipeableDrawer
      anchor="right"
      open={open}
      onClose={closeDrawerReset}
      onOpen={onOpen}
      className='drawer-plan-detail popup-drawer '
    >
      <div className='popup-drawer-header'>
        <div className='popup-drawer-header-left'>
          <IconButton
            onClick={closeDrawer}
          >
            <span className='d-none d-md-inline-flex material-symbols-rounded'>chevron_left</span>
            <span className='d-inline-flex d-md-none material-symbols-rounded'>cancel</span>
          </IconButton>
          <div className='scrolling-text-sec d-flex align-items-center'>
            <div className='inner-scrolling-text-container'>
              {/* <strong className='inner-scrolling-text'>{`${selectedPlanName}`}</strong> */}
              <strong className='inner-scrolling-text'>Plan Details:</strong>
            </div>
            <div className='icon-edit' onClick={()=> setShowEdit(false)} style={{marginLeft:'205px'}}>
              {showEdit && <ModeEditIcon style={{fontSize: '18px', cursor:'pointer'}}/>}
            </div>
          </div>
        </div>
      </div>
      <div className='popup-drawer-body'>
        <div className="camp-detail-toggle">
          <ul>
            {selectedPlanName && showEdit &&
              <li><span>Plan Name:</span> <span>{selectedPlanName}</span></li>
            }
            {!showEdit && 
            <Grid item xs={12} md={12} className='mb-3'>
              <TextField
                  className='w-100 inputFieldSp'
                  size='small'
                  value={formData.plan_name}
                  error={!formError.plan_name.status}
                  helperText={formError.plan_name.message}
                  onChange={(e) =>
                      setFormData({
                          ...formData,
                          plan_name: e.target.value,
                      })
                  }
                  id='plan_name'
                  label='Plan Name'
              />
            </Grid>}

            {planInfo?.industry &&
              <li><span>Industry:</span> <span>{planInfo?.industry}</span></li>
            }

            {planInfo?.organization &&
              <li><span>Organization:</span> <span>{planInfo?.organization}</span></li>
            }

            {planInfo?.brand &&
              <li><span>Product Brand:</span> <span>{planInfo?.brand}</span></li>
            }

            {planInfo?.category &&
              <li><span>Product Category:</span> <span>{planInfo?.category}</span></li>
            }

            {planInfo?.description && showEdit &&
              <li><span>Description:</span>
                <div dangerouslySetInnerHTML={{ __html: planInfo?.description }}/></li>
            }

            {!showEdit && 
             <Grid item xs={12} className='mt-3'>
                    <Editor
                      editorValues={formData.plan_description}
                      getEditorValue={(newValue) =>
                        setFormData((prevState) => ({
                          ...prevState,
                          plan_description: newValue,
                        }))
                      }
                    />
                    <span className="error">{formError.plan_description.message}</span>
            </Grid>}
          </ul>
        </div>
      </div>
      {!showEdit && <div className="popup-footer w-auto px-2">
          <div className="d-flex justify-content-end">
            <button className='btn btn-outline-primary me-2' onClick={() => (setShowEdit(true), closeDrawerReset())}>Cancel</button>
            <button className='btn btn-primary' onClick={handleFormData}>Save</button>
          </div>
      </div>}
    </SwipeableDrawer>
  )
}