import React, { useState, useEffect } from 'react';
import {
    Autocomplete,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    TextField,
    Grid,
    SwipeableDrawer,
    useMediaQuery,
    RadioGroup,
    Radio,
    IconButton,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { connect } from 'react-redux';
import { Loading } from '../../../../redux/action';
import {
    ListService,
    PublicService,
    UserService,
    LocalService,
} from '../../../../core/services';
import ShippingAddressCard from '../../../../components/address-card/shipping-add-card';
import './shipping-address.scss';
import { FormValidation } from '../../../../core/utility';
import { FormLabel } from 'react-bootstrap';
import ConfirmPopup from '../../../../components/confirm-popup/confirm-popup';
import { createPortal } from 'react-dom';
import MaterialSymbol from '../../../../components/icons/material-symbol';
import { MenuPropsAutoComplete, MenuPropsAutoCompleteNoCheckBox } from '../../../../core/utility/style';
import * as gtUtil from "../../../../core/utility"

interface Props {
    UserReducer: any;
    loading: (data: boolean) => void;
}

const formValidation = new FormValidation();


const initState = {
    address_id: '',
    full_name: '',
    mobile_no: '',
    country: '',
    state: '',
    city: '',
    area: '',
    landmark: '',
    house_no: '',
    pincode: '',
    residency_type: '',
    default_address: false,
};

const ShippingAddress: React.FC<Props> = (props: Props) => {
    const userService = new UserService();
    const localService = new LocalService();
    const listService = new ListService();
    const publicService = new PublicService();

    const [addressList, setAddressList] = useState<any>([]);
    const [formData, setFormData] = useState(initState);
    const [showInput, setShowInput] = useState(false);
    const [location, setLocation] = useState([]);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [headingName, setHeadingName] = useState("Add Address")
    const [addAddressPopupOpen, setAddAddressPopupOpen] = useState(false);
    const [deletingAddressIndex, setDeletingAddressIndex] = useState<number | null>(null);
    const [openApply, setOpenApply] = React.useState(false);



    const [formError, setFormErrors] = useState<any>({
        full_name: { status: true, message: '' },
        mobile_no: { status: true, message: '' },
        house_no: { status: true, message: '' },
        area: { status: true, message: '' },
        pincode: { status: true, message: '' },
        city: { status: true, message: '' },
        address_type: { status: true, message: '' },
    });

    useEffect(() => {
        props.loading(true);
        try {
            influencerShippingAddressList();
            async function getLocations() {
                const res = await publicService.getLocation();
                if (res.data.status) {
                    setLocation(res?.data?.rows);
                } else {
                    setLocation([]);
                }
            }
            getLocations();
        } catch (error) {
            setLocation([]);
            console.log(`shipping-address error :: ${JSON.stringify(error)}`)
        } finally {
            props.loading(false)
        }
    }, [props.UserReducer]);


    const saveAddress = async () => {
        try {
            setButtonLoading(true);

            const validateFullName = formValidation.GetNameValidate(formData.full_name);
            const validateMobileNumber = formValidation.GetMobileControlIsValid(formData.mobile_no);
            const validateHouseName = formValidation.GetNameValidate(formData.house_no);
            const validateAreaName = formValidation.GetNameValidate(formData.area);
            const validatePincode = formValidation.GetPincodeValid(formData.pincode);
            const validateLocation = formValidation.GetSelectFieldValidate(formData.city);
            const validateAddressType = formValidation.GetSelectFieldValidate(formData.residency_type);
            // let validateOtherAddName = { status: true, message: '' };

            // Validate other_address only when residency_type is 'Other'
            // if (formData.residency_type === 'Other') {
            //     validateOtherAddName = formValidation.GetNameValidate(formData.other_address);
            // }

            setFormErrors({
                full_name: { status: validateFullName.status, message: validateFullName.message },
                mobile_no: { status: validateMobileNumber.status, message: validateMobileNumber.message },
                house_no: { status: validateHouseName.status, message: validateHouseName.message },
                area: { status: validateAreaName.status, message: validateAreaName.message },
                pincode: { status: validatePincode.status, message: validatePincode.message },
                city: { status: validateLocation.status, message: validateLocation.message },
                address_type: { status: validateAddressType.status, message: validateAddressType.message },
            });

            if (
                !validateFullName.status ||
                !validateMobileNumber.status ||
                !validateHouseName.status ||
                !validateAreaName.status ||
                !validatePincode.status ||
                !validateLocation.status ||
                !validateAddressType.status
            ) {
                return;
            }


            let response;

            if (formData.address_id) {
                response = await userService.updateShippingaddresss(formData);
            } else {
                response = await userService.addShippingaddresss({
                    influencerId: props.UserReducer.influencer_id,
                    ...formData,
                });
            }
            if (response.status === gtUtil.Constants.SUCCESS) {
                setFormData(initState);
                setShowInput(!showInput);
                influencerShippingAddressList();
            } else {
                localService.toastify(response.info, 'error', 1000);
            }
        } catch (error) {
            console.log('saveAddress error:', error);
        } finally {
            setButtonLoading(false);
        }
    };


    function getAutoCompleteArray(value: any) {
        if (value != null) {
            let splitvalue = value.split(',');
            const temp = {
                city: splitvalue[0],
                state: splitvalue[1],
                country: splitvalue[2],
            };
            setFormData({ ...formData, ...temp });
        } else {
            const temp = {
                city: '',
                state: '',
                country: '',
            };
            setFormData({ ...formData, ...temp });
        }
    };

    async function influencerShippingAddressList() {
        try {
            const response: any = await listService.influencerShippingAddressList(props.UserReducer.influencer_id);
            if (response.status === gtUtil.Constants.SUCCESS) {
                setAddressList(response.data.rows);
            }
        } catch (error) {
            console.log('influencerShippingAddressList in proposal influencer error is ==>', error);
        }
    }

    const updateDefaultAddress = async (e: any) => {
        try {
            const selectedAddressId = e.target.dataset.address_id || e.target.closest('.ship-add-checkbox').dataset.address_id;
            if (selectedAddressId != undefined) {
                props.loading(true);
                const response: any = await userService.updateDefaultAddress({
                    address_id: selectedAddressId,
                    default_address: true,
                    influencer_id: props.UserReducer.influencer_id
                });
                const checkStatus = (response.data.status) ? 'success' : 'info';
                await influencerShippingAddressList();
                props.loading(false);
                localService.toastify(response.data.message, checkStatus, 1000);
            }
        } catch (error) {
            props.loading(false);
            localService.toastify('Something went wrong.', 'error', 1000);
            console.error('updateDefaultAddress error :: ==>:', error);
        }
    };


    const editAddress = (index: number) => {
        const selectedAddress = addressList[index];
        setFormData({
            ...selectedAddress,
            default_address: selectedAddress.default_address === 'true'
        });

        if (selectedAddress.address_id) {
            setHeadingName("Edit Address");
        } else {
            setHeadingName("Add Address");
            setFormData(initState);
        }

        setShowInput(!showInput);
        setAddAddressPopupOpen(true);

    };

    //Delete Address
    // const deleteAddress = async (index: number) => {
    //     debugger
    //     try {
    //         const selectedAddress = addressList[index].address_id;
    //         if (selectedAddress != undefined) {
    //             props.loading(true);
    //             const response: any = await userService.deleteShippingAddress({
    //                 address_id: selectedAddress,
    //                 influencer_id: props.UserReducer.influencer_id
    //             });
    //             const checkStatus = (response.data.status) ? 'success' : 'info';
    //             await influencerShippingAddressList();
    //             props.loading(false);
    //             localService.toastify(response.data.message, checkStatus, 1000);
    //         }
    //     } catch (error) {
    //         props.loading(false);
    //         localService.toastify('Something went wrong.', 'error', 1000);
    //         console.error('deleteShippingAddress error :: ==>:', error);
    //     }
    // };

    const deleteAddress = (index: number) => {
        const selectedAddress = addressList[index].address_id;
        if (selectedAddress !== undefined) {
            setDeletingAddressIndex(index);
            setOpenApply(true);
        }
    };

    // Handle confirmation of delete action
    const confirmDeleteAddress = async () => {
        if (deletingAddressIndex !== null) {
            const index = deletingAddressIndex;
            try {
                props.loading(true);
                const response: any = await userService.deleteShippingAddress({
                    address_id: addressList[index].address_id,
                    influencer_id: props.UserReducer.influencer_id
                });
                const checkStatus = response.data.status ? 'success' : 'info';
                await influencerShippingAddressList();
                props.loading(false);
                localService.toastify('Address Deleted Successfully', checkStatus, 1000);
            } catch (error) {
                props.loading(false);
                localService.toastify('Something went wrong.', 'error', 1000);
                console.error('deleteShippingAddress error:', error);
            }
            // Reset deletingAddressIndex after handling delete action
            setDeletingAddressIndex(null);
            handleApplyClose();
        }
    };

    const cancelAddAddress = () => {
        setHeadingName("Add Address");
        setShowInput(!showInput);
        setFormData(initState);
    };


    const handleAddressPopupClose = () => {
        setAddAddressPopupOpen(false);
    };
    const handleOpenAddressPopup = (data: any) => {
        setAddAddressPopupOpen(true);

    };
    const isSmallScreen = useMediaQuery((theme: any) =>
        theme.breakpoints.down('sm')
    );
    const drawerAnchor = isSmallScreen ? 'bottom' : 'right';

    //For Confirm Popup
    const handleApplyClose = () => {
        setOpenApply(false);
        document
            .getElementsByTagName('body')[0]
            .classList.remove('custom-drawer-active');
    };

    return (
        <div className='shipping-main-container'>
            <div className='list-section-content rounded'>
                <strong className=''>Shipping Address</strong>
                    <div className='col-auto'>
                        <span
                            className='add-button ms-auto  btn btn-primary btn-mui'
                            onClick={() => {
                                setShowInput(!showInput);
                                setAddAddressPopupOpen(true);
                            }}                    >
                            Add address
                        </span>
                    </div>
            </div>
            <div className='add-address-card row'>
                <ShippingAddressCard
                    addressList={addressList}
                    setDefaultAddress={editAddress}
                    onEdit={editAddress}
                    enableEdit={true}
                    onChange={updateDefaultAddress}
                    onDelete={deleteAddress}
                    onClickFunction={() => setShowInput(!showInput)}
                />

            </div>

            <hr />

            {showInput && (
                <SwipeableDrawer
                    anchor={drawerAnchor}
                    open={addAddressPopupOpen}
                    onClose={() => {
                        handleAddressPopupClose();
                        setShowInput(showInput);
                    }}
                    onOpen={handleOpenAddressPopup}
                    transitionDuration={300}
                    className='popup-drawer shipping-drawer'
                    disableSwipeToOpen={false}
                    hysteresis={0.8}
                    ModalProps={{
                        keepMounted: true,
                    }}
                >
                    <div className='d-flex flex-wrap mb-3'>
                        <div className='col popup-drawer-header'>
                            <span className='align-self-center'>{headingName}</span>

                            <IconButton
                                edge='end'
                                color='inherit'
                                onClick={cancelAddAddress}
                                aria-label='close'
                            >
                                <MaterialSymbol iconName='close' fontweight='200' />
                            </IconButton>
                        </div>
                    </div>
                    <div className='add-address px-4'>
                        <Grid container rowSpacing={2} spacing={2}>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    className='w-100 inputFieldSp'
                                    size='small'
                                    value={formData.full_name}
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            full_name: e.target.value,
                                        })
                                    }
                                    error={!formError?.full_name?.status}
                                    helperText={
                                        formError?.full_name?.message
                                    }
                                    id='full_name'
                                    label='Full Name'
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    className='w-100 inputFieldSp'
                                    size='small'
                                    value={formData.mobile_no}
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            mobile_no: e.target.value,
                                        })
                                    }
                                    error={!formError?.mobile_no?.status}
                                    helperText={
                                        formError?.mobile_no?.message
                                    }
                                    id='mobile_no'
                                    label='Phone no.'
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    className='w-100 inputFieldSp'
                                    size='small'
                                    value={formData.house_no}
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            house_no: e.target.value,
                                        })
                                    }
                                    error={!formError?.house_no?.status}
                                    helperText={
                                        formError?.house_no?.message
                                    }
                                    id='id_house_no'
                                    label='Flat, House no., Building, Apartment'
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    className='w-100 inputFieldSp'
                                    size='small'
                                    value={formData.area}
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            area: e.target.value,
                                        })
                                    }
                                    error={!formError?.area?.status}
                                    helperText={
                                        formError?.area?.message
                                    }
                                    id='id_area'
                                    label='Area'
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    className='w-100 inputFieldSp'
                                    size='small'
                                    value={formData.landmark}
                                    placeholder='near HDFC bank'
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            landmark: e.target.value,
                                        })
                                    }
                                    id='id_landmark'
                                    label='Landmark'
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                {/* <Autocomplete
                                className='inputFieldSp'
                                size='small'
                                disablePortal
                                id='combo-box-demo'
                                onChange={(e: any, v) =>
                                    getAutoCompleteArray(v)
                                }
                                options={
                                    location
                                        ? location?.map(
                                            (res: {
                                                id: number;
                                                city: string;
                                                state: string;
                                                country: string;
                                            }) => {
                                                return `${res.city}, ${res.state}, ${res.country}`;
                                            }
                                        )
                                        : ['No Data']
                                }
                                renderInput={(params) => {
                                    return (
                                        <TextField
                                            name='location_id'
                                            {...params}
                                            value={formData.city}
                                            label='Location'
                                            error={!formError?.city?.status}
                                            helperText={
                                                formError?.city?.message
                                            }
                                        />
                                    );
                                }}
                            /> */}
                                <Autocomplete
                                    className='inputFieldSp'
                                    size='small'
                                    disablePortal
                                    id='combo-box-demo'
                                    value={formData.city ? `${formData.city}, ${formData.state}, ${formData.country}` : ''}
                                    onChange={(e: any, v) => getAutoCompleteArray(v)}
                                    ListboxProps={MenuPropsAutoCompleteNoCheckBox.PaperProps}
                                    options={
                                        location
                                            ? location?.map(
                                                (res: {
                                                    id: number;
                                                    city: string;
                                                    state: string;
                                                    country: string;
                                                }) => {
                                                    return `${res.city}, ${res.state}, ${res.country}`;
                                                }
                                            )
                                            : ['No Data']
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            name='location_id'
                                            {...params}
                                            label='Location'
                                            error={!formError?.city?.status}
                                            helperText={formError?.city?.message}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextField
                                    className='w-100 inputFieldSp'
                                    size='small'
                                    placeholder='6 digits PIN code'
                                    value={formData.pincode}
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            pincode: e.target.value,
                                        })
                                    }
                                    error={!formError?.pincode?.status}
                                    helperText={
                                        formError?.pincode?.message
                                    }
                                    id='id_pincode'
                                    label='Pincode'
                                />
                            </Grid>

                            <Grid item xs={12} md={12}>
                                <FormControl>
                                    <FormLabel id="demo-radio-buttons-group-label">Select Address Type : </FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        name="radio-buttons-group"
                                        value={formData.residency_type}
                                        onChange={(e) => {
                                            const selectedValue = e.target.value;
                                            setFormData({
                                                ...formData,
                                                residency_type: selectedValue,
                                            });

                                        }}
                                    >
                                        <FormControlLabel value={'Home'} control={<Radio />} label="Home" />
                                        <FormControlLabel value={'Office'} control={<Radio />} label="Office" />
                                        {/* <FormControlLabel value={'Other'} control={<Radio />} label="Other" /> */}
                                    </RadioGroup>
                                    {/* {formData.residency_type === 'Other' && (
                                        <TextField
                                            onChange={(e) =>
                                                setFormData({
                                                    ...formData,
                                                    other_address: e.target.value,
                                                })
                                            }
                                            value={formData.other_address}
                                            error={!formError?.other_address?.status}
                                            helperText={
                                                formError?.other_address?.message
                                            }
                                        />
                                    )} */}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                {/* <FormControlLabel
                                defaultChecked={initState.default_address}
                                onChange={(e, v) =>
                                    setFormData({
                                        ...formData,
                                        // default_address: `${v}`,
                                        default_address: v,
                                    })
                                }
                                control={<Checkbox />}
                                label='Set as Default'
                            /> */}
                                <FormControlLabel
                                    className='custom-checkbox ps-2'
                                    control={
                                        <Checkbox
                                            className='p-0'
                                            checked={formData.default_address}
                                            onChange={(e) =>
                                                setFormData({
                                                    ...formData,
                                                    default_address: e.target.checked,
                                                })
                                            }
                                        />}
                                    label='Set as Default'
                                />
                            </Grid>
                            <Grid item xs={12} md={12} display={'flex'} justifyContent={'flex-end'}>
                                <Button
                                    className='btn btn-outline-primary'
                                    onClick={cancelAddAddress}
                                    variant='outlined'
                                >
                                    Cancel
                                </Button>
                                <LoadingButton
                                    id='id_login_btn'
                                    loading={buttonLoading}
                                    loadingPosition='start'
                                    className='btn btn-primary ms-2'
                                    variant='contained'
                                    onClick={saveAddress}
                                >
                                    Save
                                </LoadingButton>

                            </Grid>
                        </Grid>

                        {/* <TextField className="w-100 inputFieldSp" size="small" onChange={(e) => setFormData({ ...formData, city: e.target.value })} id="id_town" label="Town/City" /> */}
                    </div>
                </SwipeableDrawer>
            )}
            {createPortal(<ConfirmPopup confirmPopupInnerText={`Are you sure want to delete this address?`} openPopup={openApply} confirmClosePopup={handleApplyClose}
                confirmClickAction={confirmDeleteAddress} drawerTitle="" />, document.body)}
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        UserReducer: state.UserReducer.UserData,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        loading: (data: any) => dispatch(Loading(data)),
    };
};

const connectToRedux = connect(
    mapStateToProps,
    mapDispatchToProps
)(ShippingAddress);

export { connectToRedux as ShippingAddress };