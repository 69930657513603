import React, { Fragment, useState } from 'react';
import CommonPopUp from './common-popup';
import { Methods } from '../../core/utility';
import { UploadService, LocalService } from '../../core/services';
import LinearProgress from '@mui/material/LinearProgress';
import * as gtUtil from "../../core/utility";
import { filterProps } from 'framer-motion';
import MaterialSymbol from '../icons/material-symbol';

const methods: Methods = new Methods();
const localService = new LocalService();

interface FileUploadPopupProps {
    openModal: boolean;
    onClose: (closed: boolean) => void;
    acceptedFileTypes: string;
    onSend: (data: any) => void;
    path: string;
    fileMinimumSize?: number; // in KB
    fileMaximumSize?: number; 
}

const FileUploadPopup: React.FC<FileUploadPopupProps> = ({
    openModal,
    onClose,
    onSend,
    acceptedFileTypes,
    path,
    fileMinimumSize = 0,
}) => {
    const [file, setFile] = useState<File | null>(null);
    const [loading, setLoading] = useState(false);
    const [src, setSelectedImage] = useState<any>(null);
    const [error, setError] = useState({ status: false, message: '' });

    // Define size limits in KB
    const fileSizeLimits: Record<string, number> = {
        'video/mp4': 150 * 1024, // 150 MB
        'image/jpeg': 2 * 1024,  // 2 MB
        'image/png': 2 * 1024,   // 2 MB
        'image/jpg': 2 * 1024,   // 2 MB
        'application/pdf': 500,  // 500 KB
        'application/msword': 500, // 500 KB
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 500, // 500 KB
    };

    const handleClosePreviewImage = () => {
        setFile(null);
    };

    // Upload file to server
    const uploadFile = () => {
        if (file != null && src != null) {
            setLoading(true);
            const form = new FormData();
            form.append('file', file, file.name);
            form.append('path', path);

            UploadService.UploadMedia(form)
                .then((res) => {
                    if (res.status === gtUtil.Constants.SUCCESS) {
                        localService.toastify(res.info, 'success', 1000);
                        onSend(res?.data?.rows);
                        onClose(false);
                    } else {
                        localService.toastify(res.info, 'error', 1000);
                    }
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                    localService.toastify('Error uploading file', 'error', 1000);
                });
        }
    };

    // Handle file selection
    const getImageHandle = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            const selectedFile = e.target.files[0];
            const fileType = selectedFile.type;
            const fileSize = selectedFile.size / 1024; // in KB
            const maxSize = fileSizeLimits[fileType] || 0;

            if (fileSize < fileMinimumSize || fileSize > maxSize) {
                setError({
                    status: true,
                    message: `${fileType !== 'application/pdf' 
                                ? `File size must not exceed ${maxSize}MB for this file type.` 
                                : `File size must not exceed ${maxSize}KB for this file type.`}`,
                });
                return;
            }

            setFile(selectedFile);
            setSelectedImage(URL.createObjectURL(selectedFile));
            setError({ status: false, message: '' });
        }
    };
console.log("acceptedFileTypes", file && file?.type)
    return (
        <CommonPopUp
            modalTitle="Upload"
            modalContent=""
            openModal={openModal}
            onClose={() => onClose(false)}
            className="modal-medium mx-auto"
        >
            <div className="upload-doc-sec">
                <Fragment>
                    {file && (
                        <div className="creative-image-preview-sec">
                            <span
                                className="material-symbols-rounded icon-filled close-preview"
                                onClick={handleClosePreviewImage}
                            >
                                cancel
                            </span>
                            {(file.type !== 'application/pdf' &&
                                file.type !== 'application/msword' &&
                                file.type !== 'video/mp4') && (
                                <img
                                    src={URL.createObjectURL(file)}
                                    alt=""
                                    className="creative-image-preview"
                                />
                            )}
                            {file.type === 'video/mp4' && (
                                <video
                                    style={{ cursor: 'pointer' }}
                                    height={400}
                                    width={400}
                                    className="creative-image-preview"
                                    controls
                                >
                                    <source src={URL.createObjectURL(file)} type="video/mp4" />
                                </video>
                            )}
                            {(file.type === 'application/pdf') &&
                                <MaterialSymbol fontSize='48px' iconName='picture_as_pdf' fontweight='200' />
                                }
                                {(
                                file.type === 'application/msword' ||
                                file.type === 'mats-officedocument.wordprocessingml.document') &&
                                <MaterialSymbol fontSize='48px' iconName='description' fontweight='200' />
                                }
                        </div>
                    )}
                    {!file && (
                        <div className="upload-btn">
                            <input type="file" accept={acceptedFileTypes} onChange={getImageHandle} />
                            <span className="material-symbols-rounded">upload</span>{' '}
                            <strong>Choose File</strong>
                        </div>
                    )}
                    {error.status && <span className="error">{error.message}</span>}
                    <span className="fs-14">accepts: {acceptedFileTypes}</span>
                    {!file && (
                              <>{(acceptedFileTypes === '.jpg, .jpeg, .png') && 
                              <span className='fs-14 pb-5'>size limit : 2 MB.</span>}
                             {(acceptedFileTypes === '.doc, .docx, .pdf') && 
                             <span className='fs-14 pb-5'>
                             size limit : 500 KB.
                             </span>
                            }
                             {(acceptedFileTypes === '.mp4') && 
                              <span className='fs-14 pb-5'>size limit : 150 MB.</span>}
                            </>
                    )}
                    {file && (
                        
                        <span className="fs-14 pb-5">
                            {/* {((file.type !== 'application/pdf')  &&  (file.type !== 
                        'application/msword') &&
        (file.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')) && <>
                            size limit : {((file.type !== 'application/pdf')  &&  (file.type !== 
                        'application/msword') &&
        (file.type !== 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')) && methods.decimalFormat((fileSizeLimits[file.type]) / 1024)} MB.</>} */}
        {
  // Check if file type is jpeg, png, or jpg
  (file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg') && (
    <>
      size limit: {fileSizeLimits[file.type] ? methods.decimalFormat(fileSizeLimits[file.type] / 1024) : 'N/A'} MB.
    </>
  )
}
                            {file?.type !== 'image/jpeg' && file?.type !== 'image/png' && file?.type !== 'image/jpg' && ( 
                                <>
                             size limit : {(file.type === 'application/pdf' || file.type === 'application/msword' || file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') && <>500 KB.</>
                             }</>)
                            }
                             
                        </span>
                    )}
                    {loading && (
                        <div className="uploading-progress">
                            <LinearProgress />
                            <span className="fs-14">File is uploading, please don't close this window.</span>
                        </div>
                    )}
                    <button
                        className="upload-file-btn btn btn-primary"
                        disabled={!file || loading}
                        onClick={uploadFile}
                    >
                        {loading ? 'Uploading...' : 'Upload'}
                    </button>
                </Fragment>
            </div>
        </CommonPopUp>
    );
};

export default FileUploadPopup;
