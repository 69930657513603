interface Props {
  className?: string;
  height?: string; 
  width?: string;
  fontWeight?: number
}
const IconPayment: React.FC<Props> = (props: Props) => {
  return (
    <div
      className={props.className}
    >
      <svg style={{height: props.height, width: props.width, fontWeight: props.fontWeight}} width="16" height="24" viewBox="0 0 16 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.371 23.3334L1.7339 14.3576V12.6539H5.87492C7.34667 12.6539 8.68297 12.1906 9.8838 11.2641C11.0846 10.3375 11.696 8.98451 11.7177 7.2052H0.208252V6.00648H11.6397C11.3739 4.80595 10.7072 3.81564 9.63953 3.03556C8.57189 2.25549 7.31701 1.86546 5.87492 1.86546H0.208252V0.666748H15.7916V1.86546H9.94876C10.6934 2.27773 11.3288 2.86559 11.8549 3.62903C12.381 4.39245 12.7137 5.18493 12.8529 6.00648H15.7916V7.2052H12.9011C12.8938 9.25816 12.187 10.8793 10.7806 12.0686C9.37422 13.258 7.73899 13.8527 5.87492 13.8527H2.93353L12.0247 23.3334H10.371Z" fill="#1C1B1F" />
      </svg>
    </div>
  );
};

export default IconPayment;






