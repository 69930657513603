import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { Loading } from '../../../../redux/action';
import { ApplyCampaignService, LocalService } from '../../../../core/services';
import HeadingStyle from '../../../../components/heading-style/heading-style';
import { Button, Skeleton, useMediaQuery } from '@mui/material';
import './creatives.scss';
import CustomDrawer from '../../../../components/custom-drawer/custom-drawer';
import { createPortal } from 'react-dom';
import HeadingStyleSecondary from '../../../../components/heading-style/heading-style-secondary';
import FileUploadPopup from '../../../../components/popup/file-upload-popup';
import GenericSwipeableDrawer from '../../../../components/generic-swipe-drawer/generic-swiper-drawer';
import MaterialSymbol from '../../../../components/icons/material-symbol';
import { DefaultUserProfile, DummyCampaignImage } from '../../../../config/config';
import * as gtUtil from "../../../../core/utility"

interface Props {
  UserReducer: any;
  selectedCampaign: any;
  loading: (data: any) => void;
}

const applyCampaignService = new ApplyCampaignService();
const localService = new LocalService();

const InfluencerCreative: React.FC<Props> = (props: Props) => {
  const [file, setFile] = useState<any>();
  const [selectedCreative, setSelectedCreative] = useState<any>(null);
  const [campaignID, setCampaignID] = useState('');
  const [creatives, setCreatives] = useState<any>(null);
  const [creativeCommentList, setCreativeCommentList] = useState<any>(null);
  const [comment, setComment] = useState('');
  const [postedUrl, setPostedUrl] = useState('');
  const [postedUrlList, setPostedUrlLists] = useState<any | any[]>(null);
  const [loading, setLoading] = useState(false);
  const [visibilityButton, setVisibilityButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const location = useLocation();
  const [openApprovedCreativeModal, setOpenApprovedCreativeModal] = useState(false)
  const [openModal, setOpenModal] = useState(false);
  const [uploadCreative, setUploadCreative] = useState({
    fileName: "",
    fileType: "",
    key: "",
    message: "",
    storage_size: "",
    url: "",
    smId: 0,
    campaignId: 0
  })
  useEffect(() => {
    getCreativeDetails();
  }, [props.selectedCampaign]);


  const getCreativeDetails = async () => {
    const response = await applyCampaignService.influencerCreativeDetail({
      campaign_id: props.selectedCampaign.campaign_id,
      smId: props.selectedCampaign.smId,
    });
    if (response.status === gtUtil.Constants.SUCCESS) {
      setVisibilityButton(response.status === gtUtil.Constants.SUCCESS || response.status === gtUtil.Constants.DB_QUERY_NO_RECORD_FOUND);
      setCreatives(response.data.rows);
    } else {
      setVisibilityButton(response.status === gtUtil.Constants.SUCCESS || response.status === gtUtil.Constants.DB_QUERY_NO_RECORD_FOUND);
      setErrorMessage(response.info);
      localService.toastify(response.info, 'info', 1000);
    }
  };

  const path = `smId/${props.selectedCampaign.smId}/campaign/${props?.selectedCampaign?.campaign_id}/creatives`

  const uploadFile = async (e: any) => {
    try {
      setLoading(true);
      const updatedFormData = {
        ...uploadCreative,
        fileName: e.fileName,
        fileType: e.fileType,
        key: e.key,
        storage_size: e.storage_size,
        url: e.url,
        campaignId: props.selectedCampaign.campaign_id,
        smId: props.selectedCampaign.smId
      }
      const response: any = await applyCampaignService.uploadCreative(updatedFormData);
      const statusMessage = response.info;
      const statusType = (response.status === gtUtil.Constants.SUCCESS) ? 'success' : 'error';

      localService.toastify(statusMessage, statusType, 1000);
      if (response.status === gtUtil.Constants.SUCCESS) {
        getCreativeDetails();
        setLoading(false);
      }
      setOpenModal(false);
    } catch (err) {
      console.log("error Message", err)
      localService.toastify(`Please Choose the file`, 'info', 1000);
    }
  };
  async function getLinks(data: any) {
    try {
      const response = await applyCampaignService.getInfluencerCreativeLinks({
        campaign_id: props.selectedCampaign.campaign_id,
        creative_id: data.creative_id,
      });

      if (response.data.status.status) {
        setPostedUrlLists(response.data.rows);
      } else {
        setPostedUrlLists(null);
      }
    } catch (error) {
      throw new Error(`getLinks function error :: ${JSON.stringify(error)}`);
    }
  }


  async function getComments(data: any) {
    try {
      const response: any =
        await applyCampaignService.getInfluencerCreativeComments({
          creative_id: data.creative_id,
        });

      if (response.status === gtUtil.Constants.SUCCESS) {
        setCreativeCommentList(response.data.rows);
      } else {
        setCreativeCommentList(null);
      }
    } catch (error) {
      throw new Error(`getComments Error :: ${JSON.stringify(error)}`);
    }
  }

  const handleSelectCreative = async (data: any) => {
    setOpenApprovedCreativeModal(true)
    setSelectedCreative(data);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    const bodyClass = document.getElementsByTagName('html')[0];
    if (bodyClass) {
      bodyClass.classList.add("custom-drawer-modal-open");
    }
    try {
      //get Create Links
      await getLinks(data);
      //get Creative Comment (Messages)
      await getComments(data);
    } catch (error) {
      throw new Error(`handleSelectCampaign function error :: ${error}`);
    }
  };

  const handleSendComment = async (e: any) => {
    
    e.preventDefault();
    setIsSubmitting(true)
    if (comment === '') {
      localService.toastify("Message can't be blank!");
      return
    }
    try {
      const response: any = await applyCampaignService.sendCreativeComments({
        creative_id: selectedCreative.creative_id,
        proposal_id: selectedCreative.proposal_id,
        comment: comment,
        account_name: props.UserReducer?.account_name ?? 'Invitation',
        user_id: props.UserReducer?.user_id ?? 0,
        account_id: props.UserReducer?.account_id ?? 0,
        influencer_id: props.UserReducer?.influencer_id ?? 0,
        sm_id: selectedCreative.sm_id ?? 0,
        // receiver_account_id: props.selectedCampaign.account_id ?? 0,
        receiver_influencer_id: props.selectedCampaign.influencer_id ?? 0
      });
      if (response.status === gtUtil.Constants.SUCCESS) {
        handleSelectCreative(selectedCreative);
        setComment('');
      } else {
        localService.toastify(response.info);
      }
    } catch (error) {
      throw new Error(`handleSendComment :: ${error}`);
    }
    finally {
      setIsSubmitting(false);
    }
  };

  const handleApprovedCreativeCloseModal = () => {
    setOpenApprovedCreativeModal(false)
    const bodyClass = document.getElementsByTagName('html')[0];
    if (bodyClass) {
      bodyClass.classList.remove("custom-drawer-modal-open");
    }
  }
  const chatBodyRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    // Scroll to the bottom of the container on load
    if (chatBodyRef?.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  }, [creativeCommentList]);
  
  const handleClosePreviewImage = () => {
    setFile("")

  }
  function handleCloseModal() {
    setOpenModal(false);
  }

  const handleCreative = (e: HTMLButtonElement) => {
    uploadFile(e)
  }


  useEffect(() => {
    window.addEventListener('popstate', function (event) {
      if (openApprovedCreativeModal === true) {
        const bodyClass = document.getElementsByTagName('html')[0];
        if (bodyClass) {
          bodyClass.classList.remove("custom-drawer-modal-open");
        }
        setOpenApprovedCreativeModal(false)
      }
    });
  }, [openApprovedCreativeModal])
  const isSmallScreen = useMediaQuery((theme: any) =>
    theme.breakpoints.down('sm')
  );
  const drawerAnchor = isSmallScreen ? 'bottom' : 'right';
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const imageUrl = props?.UserReducer?.photo || props?.UserReducer?.gcp_profile_image_url || props?.UserReducer?.profile_image_url || DummyCampaignImage.default_campaign_image;
  const drawerContent = (
    <>
      <div className="container">
        <div className='row'>
          <div className='col-md-12 d-flex justify-content-between  pb-3'>
            <div className="d-flex flex-column">
              {props.UserReducer != null && <HeadingStyleSecondary className='mb-0' title={props.UserReducer?.full_name} />}
              <span className="">{`${props.selectedCampaign.campaign_title} (${props.selectedCampaign.campaign_type})`}</span>
            </div>
          </div>
        </div>
        <div className='creative-detail'>
          <div className="creative-detail-img">

            {selectedCreative?.file_type === "image" ? (
              <img
                src={selectedCreative?.url || null}
                className="selected-creative"
                alt="creative"
                key={selectedCreative?.url}
              // onClick={handleCreativeZoom}

              />
            ) : (
              <>

                <video
                  className="selected-creative"
                  controls
                  key={selectedCreative?.url}
                >
                  <source src={selectedCreative && selectedCreative?.url} type="video/mp4"></source>
                </video>
              </>

            )}
          </div>
          {props.UserReducer != null && <div className="chatbox showbox pro-inf-chat-container">
            <div className="modal-dialog-scrollable">
              <div className="modal-content">
                <div className='proposal-message-container chat-view' ref={chatBodyRef}>
                  <ul className="parent-ul">
                    {creativeCommentList && creativeCommentList.slice().reverse().map((data: any, index: number) => { // Reverse the list
                      const currentCommentDate = dayjs(data.created_at).format('DD MMM, YYYY');
                      const previousCommentDate = index > 0 ? dayjs(creativeCommentList.slice().reverse()[index - 1].created_at).format('DD MMM, YYYY') : null;
                      const extraClass = props.UserReducer.influencer_id === data.influencer_id ? 'proposal-message-sec-alternate' : '';

                      return (
                        <React.Fragment key={index}>
                          {currentCommentDate !== previousCommentDate && (
                            <span className="proposal-status" style={{ display: 'inline-flex', margin: '0 auto' }}>
                              {loading ? <Skeleton /> : currentCommentDate}
                            </span>
                          )}
                          <li className={`mt-3 child-li ${data.influencer_id === props.UserReducer.influencer_id ? 'reply to' : 'sender from'}`}>
                            <>
                            <span>{loading ? <Skeleton /> : data.comments}</span>
                              <img className="profile-image-size" src={data.influencer_id === props?.UserReducer?.influencer_id ? imageUrl : props?.selectedCampaign.campaign_cover_url} alt="Profile Image" />
                            </>
                          </li>
                        </React.Fragment>
                      );
                    })}
                  </ul>
                  
                </div>

                <div className="send-box conversion-section">
                  <form onSubmit={handleSendComment}>
                    <input
                      type="text"
                      name="text_message"
                      className="form-control"
                      aria-label="message…"
                      placeholder="Write message…"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    />
                    <button type="submit" className='send-btn' disabled={isSubmitting}>
                      <MaterialSymbol className={`send-icon`} iconName='send' fontweight='200' />
                    </button>
                  </form>

                </div>
              </div>
            </div>
          </div>}
        </div>
      </div>
    </>
  );
  return (
    <div
      className={'inf-creative-container'}
    >
      <div className='creative-upload'>
        <button disabled={!visibilityButton} type='button' className='btn btn-outline-primary me-2' onClick={() => setOpenModal(true)}>Upload</button>
        {openModal &&
          <FileUploadPopup

            openModal={openModal}
            onClose={handleCloseModal}
            onSend={(e: any) => {
              handleCreative(e);
            }
            }
            path={path}
            acceptedFileTypes=".jpg, .jpeg, .png, .mp4"
          />
        }
       {!visibilityButton && <span>you can't upload the creative until your proposal is accepted.</span>}
      </div>
      {
        visibilityButton && (
          <>
            <div className='row mb-3'>
            </div>
            <div className='creative-card-list'>
              <div
                className={
                  selectedCreative
                    ? 'creative-list'
                    : 'creative-list'
                }
              >
                {creatives &&
                  creatives.map((data: any, index: number) => {
                    return (
                      <div
                        className={[`creative-card ${data.status}`].join(
                          ' '
                        )}
                        onClick={() => handleSelectCreative(data)}
                        key={index}
                      >
                        {' '}
                        <span
                          className={[`creative-status ${data.status}`].join(
                            ' '
                          )}
                        >
                          {data.status}
                        </span>{' '}
                        {data.file_type === 'image' ? (
                          <img
                            src={data.url}
                            className='creative_size'
                            alt='creative'
                          />
                        ) : (
                          //  <a href={data.url} >Video</a>
                          <React.Fragment key={data?.id}>
                            <span
                              className="icon-video material-symbols-rounded icon-filled">play_circle
                            </span>
                            <video
                              style={{
                                cursor: 'pointer',
                              }}
                              height={400}
                              width={400}
                            >
                              <source src={data.url} type='video/mp4'></source>
                            </video>
                          </React.Fragment>
                        )}
                      </div>
                    );
                  })}
              </div>


            </div>
            {createPortal(
              <GenericSwipeableDrawer
                className='creative-drawer-popup'
                drawerAnchor={drawerAnchor}
                open={openApprovedCreativeModal}
                onClose={handleApprovedCreativeCloseModal}
                onOpen={() => openApprovedCreativeModal}
                title=''
                content={drawerContent}
              />, document.body)}
            {/* {createPortal(
              <CustomDrawer
                open={openApprovedCreativeModal}
                className="creative-infl-side popup-approved popup-approved-chat custom-drawer-lg "
                onClose={handleApprovedCreativeCloseModal}
                drawerTitle=''
              >
                <div className='custom-drawer-body'>
                  <div className="container">
                    <div className='row'>
                      <div className='col-md-12 d-flex justify-content-between  pb-4 pt-4'>
                        <div className="d-flex flex-column">
                          <HeadingStyleSecondary className='mb-0' title={props.UserReducer?.full_name} />
                          <span className="">{`${props.selectedCampaign.campaign_title} (${props.selectedCampaign.campaign_type})`}</span>
                        </div>
                      </div>
                    </div>
                    <div className='creative-detail'>
                      <div className="creative-detail-img">

                        {selectedCreative?.file_type === "image" ? (
                          <img
                            src={selectedCreative?.url || null}
                            className="selected-creative"
                            alt="creative"
                            key={selectedCreative?.url}
                          // onClick={handleCreativeZoom}

                          />
                        ) : (
                          <>

                            <video
                              className="selected-creative"
                              controls
                              key={selectedCreative?.url}
                            >
                              <source src={selectedCreative && selectedCreative?.url} type="video/mp4"></source>
                            </video>
                          </>

                        )}
                      </div>
                      <div className="chatbox showbox">
                        <div className="modal-dialog-scrollable">
                          <div className="modal-content">
                            <div className='proposal-message-container' ref={chatBodyRef}>
                              {creativeCommentList && creativeCommentList.map((data: any, index: number) => {
                                const extraClass = props.UserReducer.influencer_id === data.influencer_id ? 'proposal-message-sec-alternate' : '';
                                return (
                                  <div key={index} className={`proposal-message-sec ${extraClass}`}>
                                    <span className='proposal-message-date'>
                                      {dayjs(data.created_at).format('DD MMM')}
                                    </span>
                                    <span className='proposal-message'>{data.comments}</span>
                                    <span className='proposal-message-date proposal-message-time'>
                                      {dayjs(data.created_at).format('hh:mm A')}
                                    </span>
                                  </div>
                                )
                              })}
                            </div>

                            <div className="send-box">
                              <form >
                                <input
                                  type="text"
                                  name="text_message"
                                  className="form-control"
                                  aria-label="message…"
                                  placeholder="Write message…"
                                  value={comment}
                                  onChange={(e) => setComment(e.target.value)}
                                />
                                <Button
                                  variant='contained'
                                  className='btn btn-outline-primary'
                                  onClick={handleSendComment}
                                  type='submit'
                                >
                                  Send
                                </Button>
                              </form>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </CustomDrawer>
              , document.body)} */}
          </>
        )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
    UserID: state.UserID.userID,
    selectedCampaign: state.InfSelectCamp.campaign,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(InfluencerCreative);

export { connectToRedux as InfluencerCreative };
