import React from 'react';
import { Loading } from '../../../redux/action';
import { connect } from 'react-redux';
import { Methods } from '../../../core/utility/methods';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Launch from '@mui/icons-material/Launch';
import InstagramIcon1 from '../../../components/icons/instagram-icon1';

const methods = new Methods();

interface Props {
  UserReducer: any;
  loading: (data: any) => void;
  profileData: any;
}

const BrandPulseProfileCard: React.FC<Props> = (props: Props) => {

  return (
    <>
      <div className="" style={{ fontFamily: 'Open Sans',display:'flex',flexDirection:'column',width:'100%',backgroundColor:'#f5fbff',borderRadius:'10px',boxShadow:'0 4px 6px rgba(0, 0, 0, 0.1)'}}>
        <div className="profile-header" style={{justifyContent:'space-around',}}>
          <div className="profile-logo">
            <img
              src={props.profileData[0].gcp_profile_image_url}
              alt={props.profileData[0].insta_name}
              style={{ width: '229px', height: '229px', objectFit: 'cover' }}
            />
          </div>
          <div className="profile-info" style={{display:'flex',flexDirection:'column'}}>
            <div>
              <p
                className=""
                style={{
                  fontWeight: '400',
                  fontSize: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  margin: 0,
                }}
              >
                {props.profileData[0].insta_name}
                <a
                  href={`https://www.instagram.com/${props.profileData[0].username}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ marginLeft: '8px' }}
                >
              <Launch fontSize="small" sx={{ height: 'auto' }} />
              </a>
              </p>

              <div
                className="profile-name"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '2px',
                  marginBottom: '8px',
                }}
              >
                <InstagramIcon1 width={25} />                 <p
                  style={{
                    fontSize: '14px',
                    fontWeight: '600',
                    color: '#83A5B0',
                    margin: 0,
                  }}
                >
                  {props.profileData[0].username}
                </p>
              </div>
              <p style={{ margin: '8px 0', color: '#6B7280' }}>
              {props.profileData[0].industry_name}
              </p>
            </div>

            {/* profile stats */}
          <div className="profile-stats" style={{ display: 'flex', gap: '16px', marginTop: '16px' }}>
              <div
                className="stat-item"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  border: '0.84px solid #BED8E2',
                  borderRadius: '8.4px',
                  padding: '10px',
                  width: '100px',
                }}
              >
                <p
                  style={{
                    fontSize: '26.87px',
                    fontWeight: '800',
                    lineHeight: '36.59px',
                    margin: 0,
                    color:'#3B5763'
                  }}
                >
                  {}
                  {methods.formatNumber(props.profileData[0].followers)}
                </p>
                <p style={{ color: '#3B5763', fontSize: '14px', margin: 0 }}>Followers</p>
              </div>

              <div
                className="stat-item"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  border: '0.84px solid #BED8E2',
                  borderRadius: '8.4px',
                  padding: '10px',
                  width: '100px',
                }}
              >
                <p
                  style={{
                    fontSize: '26.87px',
                    fontWeight: '800',
                    lineHeight: '36.59px',
                    margin: 0,
                     color:'#3B5763'
                  }}
                >
                  {props.profileData[0].media_count}
                </p>
                <p style={{ color: '#3B5763', fontSize: '14px', margin: 0 }}>Posts</p>
              </div>

              <div
                className="stat-item"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  border: '0.84px solid #BED8E2',
                  borderRadius: '8.4px',
                  padding: '10px',
                  width: '100px',
                }}
              >
                <p
                  style={{
                    fontSize: '26.87px',
                    fontWeight: '800',
                    lineHeight: '36.59px',
                    margin: 0,
                     color:'#3B5763'
                  }}
                >
                  {props.profileData[0].image_count}
                </p>
                <p style={{ color: '#3B5763', fontSize: '14px', margin: 0 }}>Images (90 days)</p>
              </div>

              <div
                className="stat-item"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  border: '0.84px solid #BED8E2',
                  borderRadius: '8.4px',
                  padding: '10px',
                  width: '100px',
                }}
              >
                <p
                  style={{
                    fontSize: '26.87px',
                    fontWeight: '800',
                    lineHeight: '36.59px',
                    margin: 0,
                     color:'#3B5763'
                  }}
                >
                  {props.profileData[0].video_count}
                </p>
                <p style={{ color: '#3B5763', fontSize: '14px', margin: 0 }}>Videos (90 days)</p>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(mapStateToProps, mapDispatchToProps)(BrandPulseProfileCard);

export { connectToRedux as BrandPulseProfileCard };
