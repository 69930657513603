import React, { useState } from 'react'
import { connect } from "react-redux";
import { Loading } from "../../../redux/action";
import "./brand-data.scss";

import { Grid, TableCell, TableRow } from '@mui/material';
import { SortingColumnsType,TableComponent } from '../../../components/table-component/table';
import { Methods } from "../../../core/utility";
import { DefaultUserProfile } from "../../../config/config";
const methods = new Methods();


interface Props {
    UserReducer: any;
    loading: (data: any) => void;
    detailedInfluencer:any;
  }
  const columnDisplayName = {
    full_name: "Name",
    username: "Username",
    followers_count: "Followers",

    no_of_posts: "Posts",
    total_likes: "Total Likes",
    total_comments: "Total Comments",
    total_video_plays: "Total Video Views",

    average_er: "ER",


    // total_video_views: "Total Video Views",
    // total_media_count: "Total Media",
    amount_spent: "Amount Spent (INR)",
    emv: "Earned Media Value (EMV)"
  };
  const sortingColumnType: SortingColumnsType[] = [
    {
      name: "full_name",
      columnSize: "180px",
    },
    {
      name: "username",
      columnSize: "120px",
    },
    {
      name: "followers_count",
      columnSize: "120px",
    },

    {
      name: "no_of_posts",
      columnSize: "100px",
    },
    {
      name: "total_likes",
      columnSize: "100px",
    },
    {
      name: "total_comments",
      columnSize: "100px",
    },
    {
      name: "total_video_plays",
      columnSize: "100px",
    },
    {
      name: "average_er",
      columnSize: "120px",
    },    
    {
      name: "amount_spent",
      columnSize: "120px",
    },    
    {
      name: "emv",
      columnSize: "120px",
    }
  ];

const DetailedInfluencerAnalytics: React.FC<Props> = (props: Props) => {
  const [renderData, setRenderData] = useState<any>([]);

  return (
    <div>
      <Grid item xs={12} sm={12} md={12}>
        <div className="mb-2"><strong>Detailed Influencer Analytics: </strong></div>
        <TableComponent
          pagination={true}
          columnDisplayName={columnDisplayName}
          inputDataSource={props.detailedInfluencer}
          orderByColumnName=""
          sortingColumnType={sortingColumnType}
          renderData={renderData}
          setRenderData={(e: any) => setRenderData(e)}
        >
          {renderData.map((data: any, index: number) => {
            return (
              <TableRow key={index} >
                <TableCell className="d-flex align-items-center"><div><img src={DefaultUserProfile?.influencer_unknown_default} style={{
                  width: '45px', height: '45px', borderRadius: '50%', marginRight:
                    '10px', display: 'inline-flex',
                  border: '1px solid rgba(0, 0, 0, 0.4)'
                }} /></div><div>{data.full_name}</div></TableCell>
                <TableCell>{data.username}</TableCell>
                <TableCell>{methods.convertNumber(data.followers_count)}</TableCell>
                <TableCell>{methods.formatInfluencerProfileNumber(data.no_of_posts)}</TableCell>
                <TableCell>{methods.convertNumber(data.average_er)}</TableCell>
                <TableCell>{methods.formatInfluencerProfileNumber(data.total_likes)}</TableCell>
                <TableCell>{methods.formatInfluencerProfileNumber(data.total_comments)}</TableCell>
                {/* <TableCell>{methods.formatInfluencerProfileNumber(data.total_video_views)}</TableCell> */}
                <TableCell>{methods.formatInfluencerProfileNumber(data.total_video_plays)}</TableCell>
                <TableCell>{methods.formatInfluencerProfileNumber(data.amount)}</TableCell>
                <TableCell>{methods.formatInfluencerProfileNumber(data.emv)}</TableCell>
                {/* <TableCell>{data.engagement_rate.toFixed(2)} %</TableCell> */}
              </TableRow>
            );
          })}
        </TableComponent>
      </Grid>

    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(mapStateToProps, mapDispatchToProps)(DetailedInfluencerAnalytics);

export { connectToRedux as  DetailedInfluencerAnalytics };

