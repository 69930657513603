import { HttpService } from "./https.service";
import { BASE_URL } from "../../config/config";

const http = new HttpService();

export class BrandDataService {
  async getBrandSearch(params: any): Promise<any> {
    try {
      const path = BASE_URL + `/brand/search?value=${params}`;
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`Brand Search :: ${error}`);
    }
  }

  async getBrandData(params: any): Promise<any> {
    try {
      const path = BASE_URL + `/brand/details?ref_id=${params}`;
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`Brand Data :: ${error}`);
    }
  }

  async compareBrandData(params: any): Promise<any> {
    try {
      const path = BASE_URL + `/brand/compare?ref_id=${params}`;
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`Brand Data :: ${error}`);
    }
  }

}