import React from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import "./invoice.scss";

interface TableProps {
    columns: any;
    columnsAlias?: any;
    children: React.ReactNode;
    className?: string

}

export const InvoiceTableComponent: React.FC<TableProps> = ({className, columns, columnsAlias, children, ...props }: TableProps) => {
    return (
        <TableContainer className={`w-100 ${className}`} id="table_id">
            <Table stickyHeader aria-label="simple table" className="prev-inv-table table w-100">
                <TableHead>
                    <TableRow>
                        {columns.map(
                            (column: any, index: number) => (
                                <TableCell
                                    key={index}
                                    className="tableCell"
                                    style={{
                                        minWidth: column.columnSize,
                                    }}
                                    align="left"
                                >
                                    <strong>{column.name}</strong>
                                </TableCell>
                            )
                        )}
                    </TableRow>
                </TableHead>
                <TableBody className="pln_table_body">
                    {children}
                </TableBody>
            </Table>
        </TableContainer>

    )
}