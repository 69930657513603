import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import './image-crop.scss';
import { Button, Slider, Typography } from '@mui/material';
import getCroppedImg from './utils/image-crop-utils';
import CancelIcon from '@mui/icons-material/Cancel';
import CropIcon from '@mui/icons-material/Crop';
import { UploadService, LocalService } from '../../core/services';
import { toast } from 'react-toastify';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { Methods } from '../../core/utility';
import SaveIcon from '@mui/icons-material/Save';
import defaultImage from '../../assets/images/image-fill.svg';
import * as gtUtil from "../../core/utility";

const localService = new LocalService();
const methods: Methods = new Methods();

interface Props {
    path: string;
    ratio: any;
    popup: (data: Boolean) => void;
    onCroped: (data: any) => void;
}

export const ImageCrop: React.FC<Props> = (props: Props) => {
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState<any>(1);
    const [src, setSelectedImage] = useState<any>(null);
    const [rotation, setRotation] = useState<any>(0);
    const [croppedAreaPixels, setCropAreaPixels] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({ status: false, message: '' });

    const onCropComplete = useCallback(
        (croppedArea: any, croppedAreaPixels: any) => {
            setCropAreaPixels(croppedAreaPixels);
        },
        [crop]
    );

    const getImageHandle = (e: any) => {
        const file = e.target.files[0];
        const size = file.size / 1024;
        if (size < 800) {
            setSelectedImage(URL.createObjectURL(file));
            setError({ status: true, message: '' });
        } else {
            setError({
                status: false,
                message: `Your file is size : ${methods.decimalFormat(
                    size
                )}KB please attach max 800KB`,
            });
        }
    };

    const getCropped = async () => {
        if (src != null) {
            setLoading(true);
            const getCrp: any = await getCroppedImg(
                src,
                croppedAreaPixels,
                rotation
            );
            // props.onCroped(getCrp);
            const form = new FormData();
            form.append('file', getCrp.file, getCrp.file.name);
            form.append('path', props.path);

            try {
                UploadService.UploadMedia(form)
                    .then((res) => {
                        // console.warn(res)
                        if (res?.status === gtUtil.Constants.SUCCESS) {
                            localService.toastify(res.info, "success", 1000);
                            // localService.toastify(
                            //     res?.data?.rows?.message,
                            //     'success',
                            //     1000
                            // );
                            props.onCroped(res?.data?.rows);
                            props.popup(false);
                        } else {
                            localService.toastify(
                                res?.info,
                                'error',
                                1000
                            );
                        }
                        setLoading(false);
                    })
                    .catch((error) => {
                        setLoading(false);
                    });
            } catch (err) {
                setLoading(false);
                localService.toastify(
                    'uploading error :: ==> ' + JSON.stringify(err),
                    'error',
                    1000
                );
                throw new Error(`getCropped Error in component :: ${err}`);
            } finally {
            }
        }
    };

    const cancel = () => {
        props.popup(false);
    };

    return (
        <div className='container image_cropper_container_main'>
            <div className='row image_cropper_main_row'>
                <div className='col-lg-4 image_cropper_main_col'>
                    <div className='cropper_title'>
                        <input
                            type='file'
                            accept='image/*'
                            onChange={getImageHandle}
                        />
                    </div>
                    {error && <span className='error'>{error.message}</span>}
                    <div className='image_cropper_container'>
                        <Cropper
                            image={src}
                            crop={crop}
                            zoom={zoom}
                            rotation={rotation}
                            aspect={props.ratio}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                        />
                    </div>
                    <div className='row mb-3'>
                        <div className='col-md-6'>
                            <div className='image_cropper_tools_zoom'>
                                <Typography>
                                    Zoom : {zoomPercent(zoom)}
                                </Typography>
                                <Slider
                                    valueLabelDisplay='auto'
                                    valueLabelFormat={zoomPercent}
                                    min={1}
                                    max={3}
                                    step={0.1}
                                    value={zoom}
                                    onChange={(e, zoom) => setZoom(zoom)}
                                />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='image_cropper_tools_rotation'>
                                <Typography>Rotate : {rotation}</Typography>
                                <Slider
                                    valueLabelDisplay='auto'
                                    min={0}
                                    max={360}
                                    value={rotation}
                                    onChange={(e, rota) => setRotation(rota)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='cropper_buttons'>
                        {/* <Button onClick={cancel} variant="outlined" className='buttons' startIcon={<CancelIcon />}>
                            Cancel
                        </Button>
                        <Button variant="contained" onClick={getCropped} className='buttons' startIcon={<CropIcon />}>
                            Crop
                        </Button> */}
                        <LoadingButton
                            loading={loading}
                            className='btn btn-outline-primary btn-mui'
                            loadingPosition='start'
                            variant='outlined'
                            onClick={cancel}
                            color='error'
                        >
                            Cancel
                        </LoadingButton>
                        <LoadingButton
                            loading={loading}
                            className='btn btn-primary btn-mui'
                            loadingPosition='start'
                            variant='outlined'
                            onClick={getCropped}
                        >
                            Crop
                        </LoadingButton>
                    </div>
                </div>
            </div>
        </div>
    );
};

const zoomPercent = (value: any) => {
    return `${Math.round(value * 100)}%`;
};
