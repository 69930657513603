import React, { useEffect, useState, useReducer } from 'react';
import { ClanShopService } from '../../core/services/clan.shop.service';
import {
    useNavigate,
    useLocation,
    Link,
    Outlet,
    Navigate,
} from 'react-router-dom';
import './product-details.scss';
import { styled } from '@mui/system';
import useRazorpay, { RazorpayOptions } from 'react-razorpay';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-toastify';
import {
    PaymentGatewayService,
    LocalService,
    PublicService,
    // SubscriptionService,
} from '../../core/services';
import { ClanShopCreateOrder, CreateOrder } from 'custom-type';
import { connect, useDispatch, useSelector } from 'react-redux';
import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import OtpField from '../../components/otp/otp';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { FormValidation } from '../../core/utility';
import { PAYMENT_GATEWAY_KEY } from '../../config/config';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material';
import HeadingStyle from '../../components/heading-style/heading-style';
import { ArrowDownward, ShoppingCart, Add, Remove } from '@mui/icons-material';
import {
    Unstable_NumberInput as NumberInput,
    NumberInputProps,
    numberInputClasses,
} from '@mui/base/Unstable_NumberInput';

import { setGuestUser } from '../../redux/action';
import { CartReducer } from '../../redux/reducer/reducer';
import { AddToCart, RemoveFromCart, updateQuantity } from '../../redux/action';
import { SET_CART } from '../../redux/types';
import Cookies from 'js-cookie';
import { AffiliateService } from '../../core/services/affiliate.service';
import * as gtUtil from "../../core/utility"

const paymentGateway: PaymentGatewayService = new PaymentGatewayService();
const localService: LocalService = new LocalService();
const publicService = new PublicService();
const affiliateService = new AffiliateService();
// const subscriptionService: SubscriptionService = new SubscriptionService();

interface Props {
    CartReducer: any;
    UserReducer: any;
    loading?: (data: any) => void;
    setGuestUser?: (data: any) => void;
    AddToCart?: (data: any) => void;
    cartList: any;
}
interface ErrorTypes {
    status: boolean;
    message: string;
}

function createData(
    return_reason: string,
    return_period: string,
    return_policy: string
) {
    return { return_reason, return_period, return_policy };
}

const rows = [
    createData(
        'Size too small, Size too large',
        '10 days from delivery',
        'Exchange with different size or colour'
    ),
    createData('Any other reason', '10 days from delivery', 'Full refund'),
];

const ProductDetails: React.FC<Props> = (props: Props) => {
    const [Razorpay] = useRazorpay();
    const formValidation = new FormValidation();
    const dispatch = useDispatch<any>();
    const navigate = useNavigate();
    const [productData, setProductData] = useState<any>([]);
    const [pincode, setPincode] = useState<any>('');
    const [showotpBox, setOtpBox] = useState(false);
    const [disableWhatsappfield, setDisableWhatsAppField] = useState(false);
    const [errorWhatsApp, setErrorWhatsApp] = useState<ErrorTypes>();
    const [whatsappnumber, setWhatsAppNumber] = useState('');
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [open, setOpen] = React.useState(false);
    const [errorEmail, setErrorEmail] = useState<ErrorTypes>();
    const [pincodeCheckStatus, setPincodeCheckStatus] = useState<any>();
    const [errorName, setErrorName] = useState<ErrorTypes>();
    const [quantityValue, setQuantityValue] = React.useState<number>(1);
    const [buyNowDisabled, setBuyNowDisabled] = React.useState<boolean>(true);

    const [quantityError, setQuantityError] = useState<ErrorTypes>();

    const [isReturnExchangeShow, setIsReturnExchangeShow] =
        useState<any>(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const [productIsInCart, setProductIsInCart] = useState(false);
    const shop_id = searchParams.get('shop_id');
    const product_id = searchParams.get('product_id');
    // const [state, dispatch] = useReducer(CartReducer, { cartList: [] });

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const shop_id = queryParams.get('shop_id');

        const guest_user_login: any =
            localService.getStorageItem('guest_user_login');
        if (guest_user_login) {
            dispatch(setGuestUser(true));
        }
        const savedCart = localStorage.getItem('cart');
        const user_id = localService.getStorageItem('user_id');

        const product_ids = localService.getStorageItem('product_id');

        if (!guest_user_login) {
            if (savedCart) {
                // Parse and dispatch the cart data to Redux
                const parsedCart = JSON.parse(savedCart);
                dispatch({ type: SET_CART, payload: parsedCart });
            }
        }
        ClanShopService.getProductDetail(product_id)
            .then((res) => {
                setProductData(res?.data?.rows);
                if (productData[0]?.delivery_pincode === "PAN India") {
                    setBuyNowDisabled(false);
                }
            })
            .catch((e) => setProductData([]));
    }, [location, productData[0]?.delivery_pincode]);

    async function createOrder(orderDetail: ClanShopCreateOrder) {
        try {
            const response: any = await paymentGateway.clanShopCreateOrder(
                orderDetail
            );
            return response;
        } catch (error) {
            throw new Error(`CreaterOrder`);
        }
    }

    const handleEmail = (e: any) => {
        setErrorEmail(formValidation.GetEmailControlIsValid(e.target.value));
    };

    const handlePincode = (e: any) => {
        if (pincode?.length === 6) {
            if (Number(pincode) > 110000 && Number(pincode) < 110099) {
                setPincodeCheckStatus("Delivery Available");
                setBuyNowDisabled(false);
            } else {
                setPincodeCheckStatus("Delivery not available at this pincode")
            }
        } else {
            setPincodeCheckStatus('Invalid Pincode');
        }

    };

    const handleSendOtpButton = () => {
        if (`${whatsappnumber}`.length === 10) {
            PublicService.sendOtp({ mobile: +whatsappnumber })
                .then((res: any) => {
                    if (res.status === gtUtil.Constants.SUCCESS) {
                        setOtpBox(res.status === gtUtil.Constants.SUCCESS);
                        setDisableWhatsAppField(res.status === gtUtil.Constants.SUCCESS);
                        toast.success(res.info);
                    } else {
                        toast.error(res.info);
                    }
                })
                .catch((error: any) => {
                    console.warn(`Otp Response Error :: ${error}`);
                    if (props.loading) {
                        dispatch(props.loading(false));
                    }
                });
        }
    };
    const handleWhatsAppOtp = (otp: any) => {
        if (otp.length === 4) {
            PublicService.verifyOtp({ mobile: +whatsappnumber, otp: otp })
                .then((res) => {
                    if (res.status === gtUtil.Constants.SUCCESS) {
                        setOtpBox(false);
                        setDisableWhatsAppField(true);
                        toast.success(res.info);
                    } else {
                        toast.error(res.info);
                    }
                    // if (res.status === gtUtil.Constants.SUCCESS) {
                    //     setOtpBox(false);
                    //     setDisableWhatsAppField(true);
                    //     toast.success(res?.data?.rows?.message)
                    // } else {
                    //     toast.error("Verify Failed.");
                    // }
                })
                .catch((error) => {
                    throw new Error('VerifyOtp Error ::: ' + error);
                });
        }
    };

    const handleWhatsApp = (e: any) => {
        setErrorWhatsApp(
            formValidation.GetMobileControlIsValid(e.target.value)
        );
    };

    const handleReturnExchange = () => {
        setIsReturnExchangeShow(!isReturnExchangeShow);
    };

    const handleBuyNow = () => {
        navigate(`user-details?shop_id=${shop_id}&product_id=${product_id}`);
    };
    const quantityValidation = formValidation.GetQuantity(quantityValue);

    const handleCart = (product: any, quantity: any) => {
        const productId = product.product_id;
        const user_id = localService.getStorageItem('user_id');
        const guest_user_login =
            localService.getStorageItem('guest_user_login');
        if (productIsInCart) {
            // Product is in the cart, remove it

            if (user_id && guest_user_login) {
                const product_data: any = {};
                product_data['user_id'] = user_id;
                product_data['product_id'] = productId;
                ClanShopService.deleteCartItem(product_data)
                    .then((res) => {
                        if (res.data.status.status) {
                            dispatch(RemoveFromCart(productId));
                            toast.error('Product removed from the cart');

                            setQuantityValue(1);
                        }
                        // setProductData(res?.data?.rows);
                    })
                    .catch((e) => console.log('respone', e));
            }
            dispatch(RemoveFromCart(productId));
            toast.error('Product removed from the cart');
            setQuantityValue(1);
        } else {
            const cookieValue: any = Cookies.get('cc_affiliate');
            if (cookieValue) {
                if (JSON.parse(cookieValue)?.cct_id) {
                    affiliateService.affiliateAddToCart({ cct_id: JSON.parse(cookieValue)?.cct_id, shop_id: shop_id, product_id: productId }).then((res) => {
                        // console.log("response",res);
                    }).catch((error) => console.log("errorr", error));
                }
            }
            // Validate quantity only if adding to the cart
            const quantityValidation =
                formValidation.GetQuantity(quantityValue);
            setQuantityValue(1);
            if (quantityValidation.status) {
                if (user_id && guest_user_login) {
                    product['user_id'] = user_id;
                    product['quantity'] = quantity;
                    product['shop_id'] = shop_id;
                    const updatedData: any = [];
                    updatedData.push(product);
                    ClanShopService.saveCartProduct(updatedData)
                        .then((res: any) => {
                            if (res.data.status.status) {
                                dispatch(
                                    AddToCart({ ...product, quantity, shop_id })
                                );
                                const cookieValue: any = Cookies.get('cc_affiliate');
                                if (JSON.parse(cookieValue)?.cct_id) {
                                    affiliateService.affiliateAddToCart({ cct_id: JSON.parse(cookieValue)?.cct_id, shop_id: shop_id, product_id: productId }).then((res) => {
                                        // console.log("response",res);
                                    }).catch((error) => console.log("errorr", error));
                                }


                                toast.success('Product added to the cart');
                                // Update the state variable to reflect that the product is in the cart
                                setProductIsInCart(true);
                            }
                        })
                        .catch((error: any) => {
                            console.warn(`cart_response :: ${error}`);
                        });
                } else {
                    dispatch(AddToCart({ ...product, quantity, shop_id }));
                    toast.success('Product added to the cart');
                }

                // Update the state variable to reflect that the product is in the cart
                // localStorage.setItem('cart', product);
                setProductIsInCart(true);
            } else {
                // Show an error message for quantity validation
                toast.error(quantityValidation.message);
            }

        }
    };
    function calculateRemainingPrice(
        originalPrice: any,
        discountPercentage: any
    ) {
        // Calculate the remaining percentage after discount
        const remainingPercentage = 100 - discountPercentage;
        // Convert remaining percentage to decimal
        const remainingDecimal = remainingPercentage / 100;
        // Calculate the remaining price
        const remainingPrice = originalPrice * remainingDecimal;
        return remainingPrice;
    }
    useEffect(() => {
        // Check if the product is in the cart when the component mounts
        const productId = productData[0]?.product_id; // Assuming you have productData available
        const isProductInCart = props.CartReducer?.cartList?.some(
            (item: any) => item.product_id === productId
        );

        setProductIsInCart(isProductInCart);
    }, [props.CartReducer?.cartList, productData]);
    const handleQuantityChange = (event: any) => {
        const val = event.target.value;
        setQuantityValue(val);
    };
    const handleIncrement = () => {
        setQuantityValue((prevValue) => {
            const newValue = prevValue + 1;
            if (newValue <= 3) {
                return newValue;
            }
            return prevValue; // Ensure the value doesn't exceed 3
        });
    };

    const handleDecrement = () => {
        setQuantityValue((prevValue) => {
            const newValue = prevValue - 1;
            if (newValue >= 1) {
                return newValue;
            }
            return prevValue; // Ensure the value doesn't go below 1
        });
    };
    return (
        <>
            <div className='product-details-main'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-4'>
                            <div className='product-detail-img'>
                                <img
                                    src={productData[0]?.product_image_url}
                                    alt='brands'
                                />
                            </div>
                        </div>
                        <div className='col-md-8'>
                            <div className='details'>
                                <HeadingStyle
                                    title={
                                        productData[0] &&
                                        productData[0]?.product_name
                                    }
                                    className='mb-3 d-block'
                                />
                                {productData[0]?.description && (
                                    <p>
                                        <strong> Description : </strong>
                                        {productData[0]?.description}
                                    </p>
                                )}
                                {productData[0]?.inventory > 0 ? (
                                    <p className='prod-inventory in-stock'>
                                        {/* {productData[0]?.inventory} */}
                                        In Stock
                                    </p>
                                ) : productData[0]?.inventory > 0 &&
                                    productData[0]?.inventory < 5 ? (
                                    <p className='prod-inventory few-stock'>
                                        {/* {productData[0]?.inventory} */}
                                        Hurry! Last few stocks left.
                                    </p>
                                ) : (
                                    <span className='prod-inventory out-of-stock'>
                                        Out of Stock
                                    </span>
                                )}
                                <p>
                                    <strong>Cost : </strong>
                                    <span className='product-original-price'>
                                        &#8377;{productData[0]?.product_cost}
                                    </span>
                                    <strong className='product-after-discount'>
                                        &#8377;
                                        {calculateRemainingPrice(
                                            productData[0]?.product_cost,
                                            productData[0]?.discount
                                        )}
                                    </strong>
                                </p>
                                <p>
                                    <strong>Discount : </strong>
                                    {productData[0]?.discount}%
                                </p>
                                {!productIsInCart &&
                                    productData[0]?.inventory > 0 && (
                                        <p className='d-flex align-items-center'>
                                            <strong>Quantity : </strong>
                                            <div>
                                                {/* <input
                                                min={1}
                                                max={3}
                                                type='number'
                                                value={quantityValue}
                                                onChange={handleQuantityChange}
                                            />

                                            <button
                                                type='button'
                                                disabled={quantityValue <= 1}
                                                onClick={handleDecrement}
                                            >
                                                Decrement
                                            </button> */}
                                                <div className='product-quantity'>
                                                    <button
                                                        type='button'
                                                        disabled={
                                                            quantityValue <= 1
                                                        }
                                                        className='prod-qty-btn product-quantity-dec-btn'
                                                        onClick={
                                                            handleDecrement
                                                        }
                                                    >
                                                        <Remove />
                                                    </button>
                                                    <input
                                                        type='number'
                                                        min={1}
                                                        max={3}
                                                        value={quantityValue}
                                                        className='product-quantity-input'
                                                        onChange={
                                                            handleQuantityChange
                                                        }
                                                        id='input'
                                                        readOnly={true}
                                                    />
                                                    <button
                                                        type='button'
                                                        disabled={
                                                            quantityValue >= 3
                                                        }
                                                        className='prod-qty-btn  product-quantity-inc-btn'
                                                        onClick={
                                                            handleIncrement
                                                        }
                                                    >
                                                        <Add />
                                                    </button>
                                                </div>
                                                <span className='error'>
                                                    {
                                                        quantityValidation?.message
                                                    }
                                                </span>
                                            </div>
                                        </p>
                                    )}

                                <div className='d-flex flex-column mb-2'>
                                    {productData[0]?.delivery_pincode !== "PAN India" ? (
                                        <>
                                            <p className='mb-1 fs-14'>
                                                Use Pincode to check delivery info
                                            </p>
                                            <div className='col-lg-3 me-2'>
                                                <TextField
                                                    size='small'
                                                    fullWidth
                                                    className='influencer_personal_info input-style'
                                                    name='pincode'
                                                    type='number'
                                                    id='outlined-basic'
                                                    label='Enter Pincode'
                                                    variant='outlined'
                                                    value={pincode}
                                                    onChange={(e) =>
                                                        setPincode(e.target.value)
                                                    }
                                                    inputProps={{ maxLength: 6 }}
                                                />
                                                <span className={pincodeCheckStatus === "Delivery Available" ? 'delivery_availaible' : 'error'}>
                                                    {pincodeCheckStatus}
                                                </span>


                                            </div>
                                            <Button
                                                variant='outlined'
                                                onClick={handlePincode}
                                                className='btn btn-outline-primary align-self-start'
                                            >
                                                Submit
                                            </Button>
                                        </>
                                    ) : (
                                        <p>Delivery Availaible in PAN India</p>
                                    )}
                                </div>
                                <p
                                    className='return-exchange-icon'
                                    onClick={handleReturnExchange}
                                >
                                    <AssignmentReturnIcon /> 10 days return &
                                    Exchange
                                </p>
                                {isReturnExchangeShow && (
                                    <div>
                                        <TableContainer component={Paper}>
                                            <Table
                                                sx={{ minWidth: 650 }}
                                                aria-label='simple table'
                                            >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>
                                                            Return Reason
                                                        </TableCell>
                                                        <TableCell align='right'>
                                                            Return Period
                                                        </TableCell>
                                                        <TableCell align='right'>
                                                            Return Policy
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {rows.map((row) => (
                                                        <TableRow
                                                            key={
                                                                row.return_reason
                                                            }
                                                            sx={{
                                                                '&:last-child td, &:last-child th':
                                                                {
                                                                    border: 0,
                                                                },
                                                            }}
                                                        >
                                                            <TableCell
                                                                component='th'
                                                                scope='row'
                                                            >
                                                                {
                                                                    row.return_reason
                                                                }
                                                            </TableCell>
                                                            <TableCell align='right'>
                                                                {
                                                                    row.return_period
                                                                }
                                                            </TableCell>
                                                            <TableCell align='right'>
                                                                {
                                                                    row.return_policy
                                                                }
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                        <Link
                                            className='btn-link fs-14'
                                            to={''}
                                        >
                                            Know More
                                        </Link>
                                    </div>
                                )}
                                {productData[0]?.inventory > 0 ? (
                                    <div className='d-flex mt-5'>
                                        <LoadingButton
                                            className='btn btn-outline-primary buy-btn btn-lg me-2'
                                            loading={loading}
                                            loadingPosition='start'
                                            variant='contained'
                                            onClick={() => {
                                                handleCart(
                                                    productData[0],
                                                    quantityValue
                                                );
                                            }}
                                            startIcon={<ShoppingCart />}
                                        >
                                            {productIsInCart
                                                ? 'Remove from Cart'
                                                : 'Add to Cart'}
                                        </LoadingButton>
                                        <LoadingButton
                                            className='btn btn-primary buy-btn btn-lg'
                                            loading={loading}
                                            loadingPosition='start'
                                            variant='contained'
                                            onClick={handleBuyNow}
                                            disabled={buyNowDisabled}
                                        >
                                            Buy Now
                                        </LoadingButton>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog
                    maxWidth='md'
                    className='user-add-details'
                    open={open}
                    onClose={handleClose}
                >
                    <DialogTitle>Enter Details..</DialogTitle>
                    <DialogContent>
                        {/* <div className="row"> */}
                        <form
                        // onSubmit={handleSubmit}
                        >
                            <div className='col-lg-12'>
                                <TextField
                                    size='small'
                                    fullWidth
                                    className='influencer_personal_info'
                                    name='name'
                                    type='text'
                                    id='outlined-basic'
                                    label='Name'
                                    variant='outlined'
                                />
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-12 col-12 mt-1 mb-3'>
                                <div className='__wa_column'>
                                    <TextField
                                        className='w-100 input-style'
                                        type='number'
                                        size='small'
                                        color='primary'
                                        InputProps={{
                                            readOnly: disableWhatsappfield,
                                        }}
                                        onBlur={handleWhatsApp}
                                        name='whatsapp_number'
                                        value={whatsappnumber}
                                        onChange={(e) =>
                                            e.target.value.length <= 10
                                                ? setWhatsAppNumber(
                                                    e.target.value
                                                )
                                                : whatsappnumber
                                        }
                                        id='whatsapp_number_id'
                                        label='WhatsApp No.'
                                        variant='outlined'
                                    />
                                    <Button
                                        variant='contained'
                                        onClick={handleSendOtpButton}
                                        disabled={disableWhatsappfield}
                                        className='__button __button-influender'
                                        color='success'
                                        startIcon={<WhatsAppIcon />}
                                    >
                                        {' '}
                                        Send OTP{' '}
                                    </Button>
                                    {/* {!dynamicOtp && <span className='error'>Please Verify Mobile No. *</span>} */}
                                    {/* {showotpBox && <span className='countdown'>{countDown}</span>} */}
                                    {showotpBox && (
                                        <OtpField
                                            handleOtp={handleWhatsAppOtp}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className='col-lg-12'>
                                <TextField
                                    size='small'
                                    fullWidth
                                    className='influencer_personal_info'
                                    name='email_id'
                                    type='text'
                                    id='outlined-basic'
                                    label='Email'
                                    onBlur={handleEmail}
                                    variant='outlined'
                                />
                            </div>
                            <div className='col-lg-12'>
                                <TextField
                                    size='small'
                                    multiline
                                    minRows={2}
                                    maxRows={6}
                                    fullWidth
                                    className='influencer_personal_info'
                                    name='address'
                                    type='text'
                                    id='outlined-basic'
                                    label='Address'
                                    variant='outlined'
                                />
                            </div>
                            <Button type='submit'>Submit</Button>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        {/* <Button onClick={handleClose}>Subscribe</Button> */}
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
        UserReducer: state.UserReducer.UserData,
        CartReducer: state.CartReducer,
        cartList: state.CartReducer.cartList,
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return {
        AddToCart: (data: any) => dispatch(AddToCart(data)),
        setGuestUser: (data: any) => dispatch(setGuestUser(data)),
    };
};

const connectToRedux = connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductDetails);

export { connectToRedux as ProductDetails };
