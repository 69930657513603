import React, { useEffect, useState } from 'react';
import './notifications.scss';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Switch,
} from '@mui/material';
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { LocalService, UserService } from '../../../../core/services';
import { connect } from 'react-redux';
import { Loading } from '../../../../redux/action';
import * as gtUtil from "../../../../core/utility";

interface Props {
  UserReducer: any;
  loading: (data: any) => any;
}

const userService = new UserService();
const localService = new LocalService();


const UserNotification: React.FC<Props> = (props: Props) => {
  const [mailNotificationSwitch, toggleMailNotification] = useState(true);
  const [whatsNotificationSwitch, toggleWhatsappNotification] = useState(true);
  const [loading, setLoading] = useState(false);
  const [emailCheckboxStates, setEmailCheckboxStates] = useState({
    'New Campaign Alert': false,
    'New Message Alert': false,
    'Proposal Updates': false,
    'Creative Updates': false,
    'Transaction Updates': false,
    'Profile Updates': false,
  });

  const [whatsappCheckboxStates, setWhatsappCheckboxStates] = useState<any>({
    'New Campaign Alert': false,
    'New Message Alert': false,
    'Proposal Updates': false,
    'Creative Updates': false,
    'Transaction Updates': false,
    'Profile Updates': false,
  });

  useEffect(() => {
    async function getNotificationsSettings() {
      try {
        // start page loader
        // props.loading(true);
        const response: any = await userService.getAllowedNotifications(
          {
            user_id: props.UserReducer.user_id,
            account_id: props.UserReducer.account_id,
            influencer_id: props.UserReducer.influencer_id,
          });
        if (response.status === gtUtil.Constants.SUCCESS) {
          // To set email and whatsapp existing notification data, from the response get in 2 rows(1 row has email notification data, second has whatsapp).
          for (const data of response.data.rows) {
            const notificationType = (data.notification_type + '').toLowerCase();
            switch (notificationType) {
              case 'email':
                setEmailCheckboxStates((prevState) => ({
                  ...prevState,
                  'New Campaign Alert': parseBoolean(data.new_campaign_alert),
                  'New Message Alert': parseBoolean(data.new_message_alert),
                  'Proposal Updates': parseBoolean(data.proposal_updates),
                  'Creative Updates': parseBoolean(data.creative_updates),
                  'Transaction Updates': parseBoolean(data.transaction_updates),
                  'Profile Updates': parseBoolean(data.profile_updates)
                }));
                // To set Toggle switch false if all checkbox are false
                if (!Object.values(data).includes('true')) {
                  toggleMailNotification(false);
                }
                break;
              case 'whatsapp':
                setWhatsappCheckboxStates((prevState: any) => ({
                  ...prevState,
                  'New Campaign Alert': parseBoolean(data.new_campaign_alert),
                  'New Message Alert': parseBoolean(data.new_message_alert),
                  'Proposal Updates': parseBoolean(data.proposal_updates),
                  'Creative Updates': parseBoolean(data.creative_updates),
                  'Transaction Updates': parseBoolean(data.transaction_updates),
                  'Profile Updates': parseBoolean(data.profile_updates)
                }));
                // To set Toggle switch false if all checkbox are false
                if (!Object.values(data).includes('true')) {
                  toggleWhatsappNotification(false);
                }
                break;
            }
          }
        }
        // remove page loader
        props.loading(false);
      } catch (error) {
        // remove page loader
        props.loading(false);
        console.log('Get Notification Error', JSON.stringify(error));
      }
    };
    function parseBoolean(value: any) {
      return typeof value === 'string' ? JSON.parse(value.toLowerCase()) : value;
    }
    getNotificationsSettings();
  }, [props.UserReducer.user_id, props.UserReducer.account_id]);


  const handleCheckboxChange = (type: string, label: string) => {
    if (type === 'email') {
      setEmailCheckboxStates((prevState: any) => ({
        ...prevState,
        [label]: !prevState[label],
      }));
    } else if (type === 'whatsapp') {
      setWhatsappCheckboxStates((prevState: any) => ({
        ...prevState,
        [label]: !prevState[label],
      }));
    }
  };


  const handleSaveButtonClick = () => {
    // Start button loader
    setLoading(true)
    updateNotifications();
  };

  const handleEmailNotificationSwitch = (evnt: any) => {
    toggleMailNotification(evnt);
    if (evnt) {
      setEmailCheckboxStates((prevState: any) => ({
        ...prevState,
        'New Campaign Alert': evnt,
        'New Message Alert': evnt,
        'Proposal Updates': evnt,
        'Creative Updates': evnt,
        'Transaction Updates': evnt,
        'Profile Updates': evnt
      }));
    }
    else {
      setEmailCheckboxStates((prevState: any) => ({
        ...prevState,
        'New Campaign Alert': false,
        'New Message Alert': false,
        'Proposal Updates': false,
        'Creative Updates': false,
        'Transaction Updates': false,
        'Profile Updates': false
      }));
    }

  }

  const handleWhatsappNotificationSwitch = (evnt: any) => {
    toggleWhatsappNotification(evnt);
    if (evnt) {
      setWhatsappCheckboxStates((prevState: any) => ({
        ...prevState,
        'New Campaign Alert': evnt,
        'New Message Alert': evnt,
        'Proposal Updates': evnt,
        'Creative Updates': evnt,
        'Transaction Updates': evnt,
        'Profile Updates': evnt
      }));
    }
    else {
      setWhatsappCheckboxStates((prevState: any) => ({
        ...prevState,
        'New Campaign Alert': false,
        'New Message Alert': false,
        'Proposal Updates': false,
        'Creative Updates': false,
        'Transaction Updates': false,
        'Profile Updates': false
      }));
    }
  }

  const updateNotifications = async () => {
    try {
      // set common values of email and whatsapp notification data
      const commonParams = {
        user_id: props.UserReducer.user_id,
        account_id: props.UserReducer.account_id,
        influencer_id: props.UserReducer.influencer_id,
      };
      // set email and whatsapp notification data as object of objects, to send in post request as single object
      const combinedNotificationData = {
        email: {
          ...commonParams,
          notification_type: 'email',
          new_campaign_alert: emailCheckboxStates['New Campaign Alert'],
          new_message_alert: emailCheckboxStates['New Message Alert'],
          proposal_updates: emailCheckboxStates['Proposal Updates'],
          creative_updates: emailCheckboxStates['Creative Updates'],
          transaction_updates: emailCheckboxStates['Transaction Updates'],
          profile_updates: emailCheckboxStates['Profile Updates'],
        },
        whatsapp: {
          ...commonParams,
          notification_type: 'whatsapp',
          new_campaign_alert: whatsappCheckboxStates['New Campaign Alert'],
          new_message_alert: whatsappCheckboxStates['New Message Alert'],
          proposal_updates: whatsappCheckboxStates['Proposal Updates'],
          creative_updates: whatsappCheckboxStates['Creative Updates'],
          transaction_updates: whatsappCheckboxStates['Transaction Updates'],
          profile_updates: whatsappCheckboxStates['Profile Updates'],
        },
      };

      const results = await userService.updateNotificationSetting(combinedNotificationData);

      // Remove Button loader
      setLoading(false)
      if (results.status === gtUtil.Constants.SUCCESS) {
        // localService.toastify(results.data.message, 'success', 1000);
      } else {
        localService.toastify(results.info, 'error', 1000);
      }
    } catch (error) {
      // Remove Button loader
      setLoading(false)
      console.error('Error Updating Notification Settings:', error);
    }
  };


  return (
    <div className='notification_main_container'>
      <div className='list-section-content rounded'>
        <div className='row align-items-center'>
          <strong className='col'>Notification</strong>
        </div>
      </div>

      <div className='row'>
        <div className='col-12 col-sm-6 mb-4 mb-md-0'>
          <span className='pb-2 d-block'>
            <FormControlLabel
              checked={mailNotificationSwitch}
              onChange={(e, c) => handleEmailNotificationSwitch(c)}
              control={<Switch />}
              label='Email'
              className='me-1'
            />
            <EmailIcon className='notification_email_icon' />
          </span>
          <FormGroup className='ps-2'>
            {Object.entries(emailCheckboxStates).map(([label, checked], index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={checked as boolean}
                    onChange={() => handleCheckboxChange('email', label)}
                  />
                }
                label={label}
                className='pb-1 fs-14 notification-checklist'
              />
            ))}
          </FormGroup>

        </div>
        <div className='col-12 col-sm-6'>
          <span className='pb-2 d-block'>
            <FormControlLabel
              checked={whatsNotificationSwitch}
              onChange={(e, c) => handleWhatsappNotificationSwitch(c)}
              control={<Switch />}
              label='WhatsApp'
            />
            <WhatsAppIcon className='notification_whatsapp_icon' />
          </span>

          <FormGroup className='ps-2'>
            {Object.entries(whatsappCheckboxStates).map(([label, checked], index) => (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    checked={checked as boolean}
                    onChange={() => handleCheckboxChange('whatsapp', label)}
                  />
                }
                label={label}
                className='pb-1 fs-14 notification-checklist'
              />
            ))}
          </FormGroup>

        </div>
      </div>
      <div className='row mt-5'>
        <div className='col'>
          <LoadingButton
            loading={loading}
            loadingPosition="start"
            className="btn btn-primary btn-mui"
            onClick={handleSaveButtonClick}
          >
            Save
          </LoadingButton>
        </div>
      </div>
    </div>
  );
};


const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserNotification);

export { connectToRedux as UserNotification };
