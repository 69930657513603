import React from 'react'
import { Loading } from '../../../redux/action';
import { connect } from 'react-redux';
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import Grid from '@mui/material/Grid';
interface Props{
    UserReducer: any;
    loading: (data: any) => void;
    subsGrowthStockChartData:any;
}
const FollowersGrowthChart:React.FC<Props> = (props: Props) => {
    const playsOptions = {
      chart: {
        type: "line",
      },
      title: {
        text: "Followers",
        align:'left'

      },
      xAxis: {
        type: "datetime",
        categories: props.subsGrowthStockChartData.map((entry: any) => entry.month),
      },
      yAxis: {
        title: {
          text: "Followers",
        },
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      series: [
        {
          name: "followers",
          data: props.subsGrowthStockChartData.map((entry: any) => entry.followers_count),
        },
      ],
      exporting: {
        enabled: false,
        buttons: {
          contextButton: {
            menuItems: [
              "viewFullscreen",
              "printChart",
              //'downloadPNG',
              "downloadJPEG",
              "downloadPDF",
              //'downloadSVG',
              "downloadCSV",
              "downloadXLS",
            ],
          },
        },
      },
    };
  return (
    <>
                  <Grid item xs={12} sm={12} md={12}>
              <div className="run-camp-card mt-5 mb-5">
                <div
                  className="section-header brand-chart sml"
                  style={{ paddingTop: 10, margin: 0 }}
                >
                </div>
                <div className="col-md-12">
                <HighchartsReact
                  highcharts={Highcharts}
                  options={playsOptions}
              />
                </div>
              </div>
            </Grid>
    </>
  )
}

const mapStateToProps = (state: any) => {
    return {
      UserReducer: state.UserReducer.UserData,
    };
  };
  
  const mapDispatchToProps = (dispatch: any) => {
    return {
      loading: (data: any) => dispatch(Loading(data)),
    };
  };
  
  const connectToRedux = connect(mapStateToProps, mapDispatchToProps)(FollowersGrowthChart);
  
  export { connectToRedux as  FollowersGrowthChart };
  
  