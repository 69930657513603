import React from 'react'
import { Loading } from "../../../redux/action";
import { connect } from "react-redux";
import "./brand-data.scss";
import { DefaultAccountLogo, DefaultUserProfile } from "../../../config/config";

interface Props{
    UserReducer: any;
    loading: (data: any) => void;
    topInfluencer:any;
    topCompetitors:any;
}

const TopInfluencer :React.FC<Props> = (props: Props)=> {
  return (
    <>
      <div className="top-section-influencer">
        <div className="section">
          <div className="section-header">
            <span>Top Influencers</span>
            <span className="arrow">&gt;</span>
          </div>
          <div className="avatars">
            {props.topInfluencer.slice(0,6).map((influencer:any) => (
              <img
                src={influencer.gcp_profile_pic_url.length > 0 ? influencer.gcp_profile_pic_url.length : DefaultUserProfile.influencer_unknown_default}
                alt= {influencer.username}
                className="avatar"
              />
            ))}
          </div>
        </div>
        <div className="section">
          <div className="section-header">
            <span>Top Competitors</span>
            <span className="arrow">&gt;</span>
          </div>
          <div className="avatars">
            {props.topCompetitors.map((competitor:any) => (
              <img
                key={competitor.id}
                src={competitor.gcp_profile_image_url}
                alt={competitor.insta_name}
                className="avatar"
              />
            ))}
          </div>
        </div> 
    </div>

    </>
  )
}

const mapStateToProps = (state: any) => {
    return {
      UserReducer: state.UserReducer.UserData,
    };
  };
  
  const mapDispatchToProps = (dispatch: any) => {
    return {
      loading: (data: any) => dispatch(Loading(data)),
    };
  };
  
  const connectToRedux = connect(mapStateToProps, mapDispatchToProps)(TopInfluencer);
  
  export { connectToRedux as TopInfluencer };
  
