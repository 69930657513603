type _StepperSteps = {
    title: string;
    value: number;
    status: 'active' | 'inactive' | Omit<string, 'active' | 'inactive'>;
};

export type DeliverableItemKeys = 'ig_reel' | 'ig_story' | 'ig_carousel_image' | 'yt_shorts' | 'yt_dedicated_video' | 'yt_integrated_video' |'available_for_exclusivity' | 'ig_static'|'digital_rights'| 'Package Cost 1'


export enum StepperStep {
    Proposal = 'Proposal',
    FirstCounteroffer = 'First counteroffer',
    InfluencerResponse = 'Influencer response',
    FinalCounteroffer = 'Final counteroffer',
    DealClosed = 'Deal closed'
}

export enum DeliveryStatus {
    Pending = "Pending",
    Received = "Received",
    NotReceived = "Not Received",
    ReceivedLate = "Received Late",
    Other = "Other"
}
  
export enum ProductCondition {
    Good = "Good",
    Broken = "Broken",
    Expired = "Expired"
}

export const StepperSteps: { title: StepperStep, value: number, status: string }[] = [
    { title: StepperStep.Proposal, value: 1, status: '' },
    { title: StepperStep.FirstCounteroffer, value: 2, status: '' },
    { title: StepperStep.InfluencerResponse, value: 3, status: '' },
    { title: StepperStep.FinalCounteroffer, value: 4, status: '' },
    { title: StepperStep.DealClosed, value: 5, status: '' }
];


export const getStepTitle = (stepIndex: number): StepperStep | undefined => {
    const step = StepperSteps.find(step => step.value === stepIndex);
    return step ? step.title : undefined;
}


export type InfluencerType =
    | 'Mega Plus'
    | 'Mega'
    | 'Macro Plus'
    | 'Macro'
    | 'Micro Plus'
    | 'Micro'
    | 'Nano'
    | 'All';