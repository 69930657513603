import React, { FormEvent, SyntheticEvent, useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { ChatService } from '../../../core/services';
import { ChatToggle, InfluencerChatComponent, CampaignList } from '../../../components';
import * as Util from '../../../core/utility';
import { CHATINTERVAL } from '../../../config/config';
import '../../../components/chat/scss/_dir.scss';
import { Slide } from '@mui/material';
import * as gtUtil from "../../../core/utility"


/**
 * ******************************About of Chat Component*********************************
 * This Component Required 
 *  campaign_detail: any; (all Active Campaign Detail Pass in this props)
    infDetails: influencerDetail | null; (Influencer Details Fetch Dynamic according to provide campaign Details)
    userData: any (Get User all User Information in Redux  )
 */

const chatService: ChatService = new ChatService();
const methods: Util.Methods = new Util.Methods();

export type CampaignListType = {
    campaign_id: number;
    proposal_id: number;
    brand_id: number;
    account_id: number;
    plan_id: number;
    influencer_id: number;
    sm_id: number;
    brand_account_id:number;
    brand_influencer_id:number;
    campaign_platform: string;
    campaign_title: string;
    campaign_description: string;
    message_count: number;
    campaign_cover_url: string;
    status: number | string;
};

type influencerDetail = {
    influencer_id: number;
    sm_id: number;
    proposal_id: number;
}

type ChatProps = {
    userData: any
}

const InfluencerProposalChat: React.FC<ChatProps> = ({ ...arg }: ChatProps) => {
    const [campaignList, setCampaignList] = useState<CampaignListType[]>([]);
    const [activeCampaign, setActiveCampaign] = useState<CampaignListType | null>(null);
    const [activeInfluencerChat, setActiveInfluencerChats] = useState({});
    const [toggle, setToggle] = useState(false);
    const [showActiveInfluencerChat, setShowActiveInfluencerChat] = useState(false);
    const [showInfluencerList, setShowInfluencerList] = useState(false);
    const [unreadMessagesCount, setUnreadMessageCount] = useState(0);
    const [intervals, setintervals] = useState<any>()
    const containerRef = React.useRef<HTMLDivElement>(null);



    // useEffect(() => {
    //     getUnreadMessage();
    //     setInterval(() => {
    //         getUnreadMessage();
    //     }, (1000 * 60))
    // }, []);

    useEffect(() => {
        setActiveInfluencerChats({});
        getActiveChat();
        getUnreadMessage();
        if (activeCampaign) {
            setintervals(setInterval(() => {
                getUnreadMessage();
                getActiveChat();
            }, CHATINTERVAL))
            setShowInfluencerList(true)
        } else if (!activeCampaign && intervals) {
            clearInterval(intervals);
        }
    }, [activeCampaign]);

    // useEffect(() => {


    //     console.log(intervals);
    //     console.log(activeCampaign)
    // }, [toggle, activeCampaign])

    /* GET CAMPAIGN LIST IF ACTIVE CAMPAIGN IN NULL AND TOGGLE IS TRUE */
    useEffect(() => {
        if (toggle && activeCampaign === null) {
            chatService.getChatCampaignList({
                platfrom_type: 'instagram',
                influencerID: arg.userData.influencer_id,
                accountId: arg.userData.account_id
            }).then((res) => {
                if (res.status === gtUtil.Constants.SUCCESS) {
                    setCampaignList(res.data.rows);
                    setShowInfluencerList(true)
                } else {
                    setCampaignList([]);
                }
            })
        } else {
            setCampaignList([]);
        }
    }, [toggle, activeCampaign]);

    function getUnreadMessage() {
        chatService.getUnReadMessage({ influencer_id: arg.userData.influencer_id }).then(res => {
            if (res.status === gtUtil.Constants.SUCCESS) {
                setUnreadMessageCount(res.data.rows[0].unread_message_count);
            } else {
                setUnreadMessageCount(0);
            }
        }).catch((error:any) => console.log(`message count fetch proposal :: ${error}`))
    }


    /**
     * it's get all conversion against campaign
     * @params proposal id, from (from set auto read msg)
     */
    function getActiveChat() {
        if (activeCampaign) {
            chatService.getInfluencerConversion({ proposal_id: activeCampaign.proposal_id, from: arg.userData.account_name, user_id: arg.userData.user_id, account_id: arg.userData.account_id, influencer_id: arg.userData.influencer_id, sm_id: activeCampaign.sm_id }).then(res => {

                if (res.status === gtUtil.Constants.SUCCESS) {
                    let alignData = methods.alignChat(res.data.rows);
                    setActiveInfluencerChats(alignData);
                    setShowActiveInfluencerChat(true)
                    // if (JSON.stringify(alignData) !== JSON.stringify(activeInfluencerChat)) {
                    // }
                }
            }).catch((err: any) => console.log(`active influencer chat error in useEffect :: ${err}`))
        }
        else {
            setShowActiveInfluencerChat(false)
        }
    }


    function handleToggle(value: boolean) {
        if (!value) {
            setActiveCampaign(null);
            setActiveInfluencerChats({});
            setShowActiveInfluencerChat(false)
        };

        setToggle(value)
    };

    /**
     * @param form event
     * INSERT INTO campaign_proposal_comments and return back latest comment
     */
    function handleSubmitChat(event: any) {
        try {
            event.preventDefault();
            let target: any = event.target;
            let values = Object.fromEntries(new FormData(target)) as { message: string };
            if (values.message.replace(/\s+/g, '').length > 0 && activeCampaign && (activeCampaign?.status != 'Rejected')) {
                chatService.insertProposalMessage({
                    proposal_id: activeCampaign?.proposal_id,
                    comments: values.message,
                    from: arg.userData.account_name,
                    user_id: arg.userData?.user_id ?? 0,
                    account_id: arg.userData?.account_id ?? 0,
                    influencer_id: arg.userData?.influencer_id ?? 0,
                    sm_id: activeCampaign?.sm_id ?? 0,
                    receiver_influencer_id: activeCampaign?.brand_influencer_id
                })
                    .then(res => {
                        getActiveChat();
                        event.target?.reset();
                    })
                    .catch(error => console.log(error))
                    .finally(() => {
                    })
            }

        } catch (err) {
            throw new Error(`handleSubmitChat`)
        }
    }

    return (
        <div className={`proposal-chat `}>
            <div className={`proposal-chat-container ${toggle ? "proposal-chat-active" : ""}`} ref={containerRef}>
                <div className={`${!activeCampaign ? "" : "d-none"}`}>
                    <CampaignList
                        campaignDetail={{ campaign_title: 'All Campaigns', campaign_id: 0 }}
                        campaignList={campaignList}
                        onChange={(value) => setActiveCampaign(value)}
                    />
                </div>
                {toggle && activeCampaign &&
                <Slide direction="left" in={showActiveInfluencerChat} mountOnEnter unmountOnExit container={containerRef.current}>
                    <Col className='col-auto'>
                        <InfluencerChatComponent
                            // campaignDetail={activeCampaign}
                            chats={activeInfluencerChat}
                            onClick={() => setActiveCampaign(null)}
                            influencer={null}
                            campaign={activeCampaign}
                            userData={arg.userData}
                            onSubmit={handleSubmitChat}
                        />
                    </Col>
                </Slide>
                }
            </div>

            <ChatToggle messageCount={unreadMessagesCount} onChange={(e) => handleToggle(e)} value={toggle} />
        </div>
    )
};


const mapToState = (state: any) => {

    return {
        userData: state.UserReducer.UserData
    }
};

const connectToRedux = connect(mapToState, null)(InfluencerProposalChat);
export { connectToRedux as InfluencerProposalChat }