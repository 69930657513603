import React from 'react'
import { Doughnut } from "react-chartjs-2";
import {BrandErVerticalChart} from './brand-er-vertical-chart';
import { connect } from "react-redux";
import { Loading } from "../../../redux/action";

interface Props 
{
    UserReducer: any;
    loading: (data: any) => void;
    data:any;
    
}

const EngagedInfluencers: React.FC<Props> = (props: Props)  => {
  const donutChartOptions = {
            responsive: true,
            plugins: {
              legend: {
                display: false, // Removes the legend
              },
            },
            cutout: '70%', // This will create a donut effect by cutting out the center
          };
        
          const amountSpentDonutChartOptions = {
            responsive: true,
            plugins: {
              legend: {
                display: false, // Removes the legend
              },
            },
            cutout: '30%', // This will create a donut effect by cutting out the center
          };

          const generateChartData = (engagement:any) => ({
            labels: ["Nano", "Micro", "Micro Plus","Macro","Macro Plus", "Mega","Mega Plus"],
            datasets: [
              {
                data: [
                  engagement["Nano"] ?? 0,
                  engagement["Micro"] ?? 0,
                  engagement["Micro Plus"] ?? 0,
                  engagement["Macro"] ?? 0,
                  engagement["Macro Plus"] ?? 0,
                  engagement["Mega"] ?? 0,
                  engagement["Mega Plus"] ?? 0,
                ],
                backgroundColor: ["#FFA5CB", "#FFD232", "#1CF7C2", "#6CDBF3","#F45DCE","#3DFD6G","#SDFGHG"],
                hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56", "#4BC0C0","#F45DCE","#3DFD6G","#SDFGHG"],
                hoverOffset: 10,
              },
            ],
          });
  return (
    <div className="d-flex flex-column container" style={{minHeight:'655px'}}>
        <div className="row col-12 p-0 m-0 " >
            <div className="col-6 p-0" style={{}}>
                <div className="" style={{ width: "100%", height: "",display:'flex',justifyContent:'center',paddingTop:'16px',borderRight:'1px solid #D2D2D2',borderBottom:'1px soild #D2D2D2' }}>
                    <div className="d-flex flex-column" style={{borderBottom:'1px solid #D2D2D2'}} >
                        <p className="text-center" style={{fontWeight:'600',fontSize:'12.44px',color:'#3B5763',lineHeight:'16.99px'}}>Influencers Engagement Ratio</p>
                        {props?.data?.influencerEngagement && Object.keys(props?.data?.influencerEngagement).length > 0 && <Doughnut data={generateChartData(props?.data?.influencerEngagement)} options={donutChartOptions} style={{width:'142px',height:'142px'}}/>}
                    </div>
               
                </div>
            </div>
            <div className="col-6 p-0">
            <div className="" style={{ width: "", height: "",display:'flex',justifyContent:'center',paddingTop:'16px',borderRight:'1px solid #D2D2D2',borderBottom:'1px soild #D2D2D2' }}>
                    <div className="d-flex flex-column" style={{height:'',paddingLeft:'5px',paddingRight:'5px'}}>
                        <p className="text-center" style={{fontWeight:'600',fontSize:'12.44px',color:'#3B5763',lineHeight:'16.99px'}}>ER</p>
                        {props?.data?.influencerEngagement && Object.keys(props?.data?.influencerEngagement).length > 0 && <BrandErVerticalChart engagement={props.data.influencerEngagement} />}
                    </div>
               
                </div>
            </div>
        </div>
        <div className="row col-12 p-0" style={{marginTop:'50px'}}>
            <div className="col-12 d-flex justify-content-center flex-column">
              <p className="text-center" style={{fontWeight:'600',fontSize:'12.44px',color:'#3B5763',lineHeight:'16.99px'}}>Amount spent </p>
              <div style={{width:'100%',height:'170px',display:"flex",justifyContent:'center'}}>
              {props?.data?.amountChart && Object.keys(props?.data?.amountChart).length > 0 &&  <Doughnut data={generateChartData(props.data.amountChart)} options={amountSpentDonutChartOptions} />}
              </div>
              
            </div>
          
        </div>
    </div>
  )
}
const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(mapStateToProps, mapDispatchToProps)(EngagedInfluencers);

export { connectToRedux as  EngagedInfluencers };

