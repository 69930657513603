import React, { useState, useEffect, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { NavLink, useNavigate } from "react-router-dom";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { CaptchaService } from "../../../core/services/captcha.service";
import { CAPTCHA_SITE_KEY } from "../../../config/config";
import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Tooltip,
} from "@mui/material";
import { FormValidation } from "../../../core/utility";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import SendIcon from "@mui/icons-material/Send";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
  PublicService,
  SignUpService,
  LocalService,
} from "../../../core/services";
import { connect } from "react-redux";
import { Loading } from "../../../redux/action";
import OtpField from "../../../components/otp/otp";
// import { toast } from 'react-toastify';
import HeadingStyle from "../../../components/heading-style/heading-style";
import useBodyClass from "../../../hooks/useBodyClass";
// import referEarn from '../../../assets/images/refer-earn.jpg';
import InfluencerContent from "../../../components/signup-right-sec/Influencer-content";
import LoadingButton from "@mui/lab/LoadingButton";
import { DefaultAccountLogo, DefaultUserProfile } from "../../../config/config";
import "./Talent_Patner.scss";
import Footer from "../../../components/footer/footer";
import { MuiOtpInput } from "mui-one-time-password-input";
import MaterialSymbol from "../../../components/icons/material-symbol";
import useConditionalFocus from "../../../hooks/useConditionalFocus";
import { BASE_URL_WEB } from '../../../config/config';
import * as gtUtil from "../../../core/utility";

const localService: LocalService = new LocalService();
const publicService: PublicService = new PublicService();
const signupService = new SignUpService();
const captchaService = new CaptchaService();

// const cryptoJS = new CryptoJS();

interface ErrorTypes {
  status: boolean;
  message: string;
}

interface Props {
  loading: (data: any) => void;
}

const Talent_Patner: React.FC<Props> = (props: Props) => {
  const formValidation = new FormValidation();
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showotpBox, setOtpBox] = useState(false);
  const [showEmailotpBox, setEmailOtpBox] = useState(false);
  const [whatsappnumber, setWhatsAppNumber] = useState("");
  const [disableWhatsappfield, setDisableWhatsAppField] = useState(true);
  const [captchaToken, setCaptchaToken] = useState<any>();
  const [terms, setTerms] = useState(false);
  const [disableEmailField, setDisableEmailField] = useState(true);
  const [email, setEmail] = useState("");
  const [pin, setPin] = useState("");
  const [isEditButtonDisabled, setIsEditButtonDisabled] = useState(false);
  const [isResendButtonDisabled, setIsResendButtonDisabled] = useState(true);
  const [timer, setTimer] = useState(60);
  const [isTimerDisabled, setIsTimerDisabled] = useState(true);
  const [showResendEdit, setShowResendEdit] = useState(false);
  const phoneRef = useRef<any>(null);
  const [isEditButtonEmailDisabled, setIsEditButtonEmailDisabled] = useState(false);
  const [isResendButtonEmailDisabled, setIsResendButtonEmailDisabled] = useState(true);
  const [Emailtimer, setEmailTimer] = useState(60);
  const [isEmailTimerDisabled, setIsEmailTimerDisabled] = useState(true);
  const [showEmailResendEdit, setShowEmailResendEdit] = useState(false);
  const emailRef = useRef<any>(null);
  const [phoneOtpVerifyloading, setPhoneOtpVerifyLoading] = useState(false);
  const [emailOtpVerifyloading, setEmailOtpVerifyLoading] = useState(false);
  const [passwordChecking, setPasswordChecking] = useState('');

  //validations
  const [errorcompanyname, setErrorCompanyName] = useState<ErrorTypes>();
  const [errorwhatsapp, setErrorWhatsapp] = useState<ErrorTypes>();
  const [erroremail, setErrorEmail] = useState<ErrorTypes>();
  const [errorPassword, setErrorPassword] = useState<ErrorTypes>();
  const [errorFull_name, setErrorFull_name] = useState<ErrorTypes>();
  const [errorPin, setErrorPin] = useState<ErrorTypes>();
  const [errorConfirmPassword, setErrorConfirmPassword] = useState<ErrorTypes>();

  // For Verification----------------------------------------------
  const [verifiedfield, setVerifiedfield] = useState(false)
  const [verifiedEmail, setVerifiedEmail] = useState(false)
  const [verifiedEmailfield, setVerifiedEmailfield] = useState(false);
  const [verifiedWAfield, setVerifiedWAfield] = useState(false);

  // Disable Input Field
  const [disableWhatsappfieldInput, setDisableWhatsAppFieldInput] = useState(false);
  const [disableEmailfieldInput, setDisableEmailfieldInput] = useState(false);
    

  const [phoneloading, setPhoneLoading] = useState(false);
  const [emailloading, setEmailLoading] = useState(false);

  const [otpWhatsAppValue, setOtpWhatsAppValue] = React.useState<string>('')
  const [otpEmailValue, setOtpEmailValue] = React.useState<string>('')
  const [otpPINValue, setOtpPINValue] = React.useState<string>('')
  const [emailOtpError, setEmailOtpError] = useState<boolean>(false);
  const [emailOtpErrorMsg, setEmailOtpErrorMsg] = useState<string>('Wrong One Time Password (OTP)');
  const [phoneOtpError, setPhoneOtpError] = useState<boolean>(false);
  const [phoneOtpErrorMsg, setPhoneOtpErrorMsg] = useState<string>('Wrong One Time Password (OTP)');

   // field activation
   const [isActiveField, setIsActiveField] = useState({
    name: false,
    fullname: false,
    email: false,
    mobile: false,
    password: false,
    confirmPassword: false,
    pin: false
  });

  const fullNameRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);

  useConditionalFocus(fullNameRef, isActiveField.name);
  useConditionalFocus(emailRef, isActiveField.fullname);
  useConditionalFocus(phoneRef, isActiveField.email);
  useConditionalFocus(passwordRef, isActiveField.mobile);
  useConditionalFocus(confirmPasswordRef, isActiveField.password);

  const navigate = useNavigate();

  useEffect(() => {
    let countdown: any;
    if (timer > 0) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setTimer(0);
      setIsResendButtonDisabled(false);
    }

    return () => clearInterval(countdown);
  }, [timer]);

  useEffect(() => {
    let countdown: any;
    if (Emailtimer > 0) {
      countdown = setInterval(() => {
        setEmailTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setEmailTimer(0);
      setIsResendButtonEmailDisabled(false);
    }

    return () => clearInterval(countdown);
  }, [Emailtimer]);

  //handles
  const handleEmailOtp = (otp: any) => {
    try{
    if (otp.length === 4) {
      setEmailOtpVerifyLoading(true);
      PublicService.emailVerifyOtp({ email: email, otp: otp })
        .then((res) => {
          if (res.status === gtUtil.Constants.SUCCESS) {
            setEmailOtpBox(false);
            setDisableEmailField(true);
            setVerifiedEmailfield(true);
            setVerifiedEmail(true);
            setShowEmailResendEdit(false);
            localService.toastify(res.info);
            setEmailOtpError(false);
            setIsActiveField({
              ...isActiveField,
              email: true
            })
          } else {
            setEmailOtpError(true);
            setEmailOtpErrorMsg(res.info);
            // localService.toastify(res.info);
          }
        })
        .catch((error) => {
          setEmailOtpVerifyLoading(false);
          throw new Error('VerifyOtp Error ::: ' + error);  
        });
    }}catch(error){
      console.log('handleEmailOtp Error',error)
    }finally{
      setEmailOtpVerifyLoading(false);
    }
  };

  const handleEmailSendOTP = async () => {
    try {
      if (!email) {
        setErrorEmail({ status: true, message: "Email is required." });
        return;
      }
      // Regular expression for email validation
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailRegex.test(email)) {
        setErrorEmail({
          status: true,
          message: "Invalid email format.",
        });
        return;
      }
      if (!erroremail?.status) {
        setErrorEmail(formValidation.GetEmailControlIsValid(email));
        return;
      }

      setEmailLoading(true)
      // Send email OTP
      const response = await PublicService.emailSendOtp({ email: email });
      setEmailLoading(false);
      setDisableEmailField(true);
      setDisableEmailfieldInput(true);

      if (response.status === gtUtil.Constants.SUCCESS) {
        setShowEmailResendEdit(true);
        setIsResendButtonEmailDisabled(true);
        setEmailTimer(30);
        setIsTimerDisabled(true);
        setIsEditButtonEmailDisabled(true);
        setEmailOtpBox(true);
        setDisableEmailField(true)
        // setVerifiedEmailfield(true);
        localService.toastify(response.info);
      } else {
        setErrorEmail(response.info);
        setDisableEmailField(false);
        localService.toastify(response.info);
      }
    } catch (error) {
      setDisableEmailField(false);
      console.warn(`Email OTP Response Error :: ${error}`);
    }
  };


  async function handleEmail(e: any) {
    const value = e?.target?.value ?? e;
    setEmail(e.target.value)
    let isValid: { status: boolean; message: string } =
      formValidation.GetEmailControlIsValid(value);
    setErrorEmail(isValid);
    if (isValid.status) {
      const response = await publicService.checkEmailIsRegister({
        email: value,
        user_type: "talent_partner",

      });
      if (response.data.status.message.length === 0) {
        setDisableEmailField(false);
      }
      else {
        setVerifiedEmailfield(false);
        setDisableEmailField(true)
      }
      if (response) {
        isValid = response.data.status;
        setErrorEmail(isValid);
      }
    }else{
      setDisableEmailField(true);
    }

    return isValid;
  }

  const handleOnSubmit = async (e: any) => {
    try {
      e.preventDefault();
      const form = new FormData(e.target);
      const formData = Object.fromEntries(form);
      let storeData: any = { ...formData };
      let isValidConfirmPassword;
      let {
        agency_name,
        email_id,
        mobile,
        full_name,
        // recaptcha,
        password,
        confirm_password
      } = storeData;
      // recaptcha = storeData["g-recaptcha-response"];
      storeData["logo"] = DefaultAccountLogo.talent_partner;
      storeData["photo"] = DefaultUserProfile.influencer_unknown_default;
      storeData["pin"] = pin
      setErrorEmail(formValidation.GetEmailControlIsValid(email_id));
      setErrorCompanyName(formValidation.GetNameValidate(agency_name));
      setErrorWhatsapp(formValidation.GetMobileControlIsValid(mobile));
      setErrorFull_name(formValidation.GetNameValidate(full_name));
      setErrorPassword(formValidation.GetPasswordValidate(password));
      setErrorPin(formValidation.GetPinIsValid(`${pin}`));

      const isValidEmail = formValidation.GetEmailControlIsValid(email_id).status
      const isValidCompany = formValidation.GetNameValidate(agency_name).status;
      const isValidFullName = formValidation.GetNameValidate(full_name).status
      const isValidPin = formValidation.GetPinIsValid(`${pin}`).status
      const isValidPassword = formValidation.GetPasswordValidate(password).status

      if(confirm_password == ''){
        setErrorConfirmPassword({
          status:false,
          message:'This field is required'
        })
        isValidConfirmPassword = false;
      }else{
        if(password == confirm_password){
          isValidConfirmPassword = true;
          setErrorConfirmPassword({
            status:true,
            message:''
          })
        }else{
          setErrorConfirmPassword({
            status:false,
            message:'Password does not match'
          })
          isValidConfirmPassword = false;
        }
      }

      //validation is Pending??

      if (!verifiedWAfield && whatsappnumber) {
        localService.toastify("WhatsApp No. Not Verified.", "error", 1000);
      }
      if (!verifiedEmailfield && email) {
        localService.toastify("Email Id. Not Verified.", "error", 1000);
      }

      const verifyCaptcha = async () => {
        try {
          const res = await captchaService.verifyCaptcha({ token: captchaToken });
          return res?.data?.rows?.message === "Captcha verified successfully!";
        } catch (error) {
          throw new Error(`verifyCaptcha Error :: ${JSON.stringify(error)}`);
        }
      };


      if (
        isValidEmail &&
        errorwhatsapp?.status &&
        isValidCompany &&
        isValidFullName &&
        terms &&
        isValidPin &&
        verifiedWAfield &&
        verifiedEmailfield &&
        isValidPassword &&
        isValidConfirmPassword
      ) {
        storeData["mobile_verified"] = "Yes";
        storeData["email_verified"] = "Yes";
        props.loading(true);
        if (await verifyCaptcha()) {
          try {
            const res = await signupService.createTalentPartner(storeData);
            if (res.status === gtUtil.Constants.SUCCESS) {
              // let date = new Date();
              // let id = encodeURI(
              //   JSON.stringify(cryptoJS.encrypt(date + "", "encryptKey"))
              // );
              localService.setStorageItem("login", `${email_id}`)
              localService.setStorageItem("password", `${password}`)
              navigate(`/welcome/talentpartner?id=${res.data?.rows[0]?.user_id}`);
            }
            props.loading(false);
          }
          catch (error) {
            console.warn("Captcha Verification (Talent Partner) Error in ", error)
          }
        }
        props.loading(false);
      } else {
        props.loading(false);
      }
    } catch (error) {
      props.loading(false);
      throw new Error(`handle On Submit in Talent Partner Error :: ${error}`);
    }
    props.loading(false);
  };

  const handleFullName = (e: any) => {
    const {
      target: { value },
    } = e;
    setErrorFull_name(formValidation.GetNameValidate(value));
    if(!isActiveField.fullname){
      setIsActiveField({
        ...isActiveField,
        fullname: formValidation.GetNameValidate(value).status,
      })
    }
  };

  const handlePassword = (e: any) => {
    const {
      target: { value },
    } = e;
    setPasswordChecking(value);
    setErrorPassword(formValidation.GetPasswordValidate(value));
    if(!isActiveField.password){
      setIsActiveField({
        ...isActiveField,
        password: formValidation.GetPasswordValidate(value).status,
      })
    }
  };

  const handleConfirmPassword = (e: any) => {
    const {
      target: { value },
    } = e;
    if(value !== passwordChecking) {
      setErrorConfirmPassword({status: false, message: 'Password does not match'});
    }else{
      setErrorConfirmPassword({status: true, message: ''});
      setIsActiveField({
        ...isActiveField,
        confirmPassword: true,
      })
    }
  };

  const handlePin = (pin: any) => {
    setPin(pin);
    setIsActiveField({
      ...isActiveField,
      pin: true
    })
  };


  const handleWhatsAppOtp = (otp: any) => {
    try{
    if (otp.length === 4) {
      setPhoneOtpVerifyLoading(true);
      PublicService.verifyOtp({ mobile: +whatsappnumber, otp: otp })
        .then((res) => {
          if (res.status === gtUtil.Constants.SUCCESS) {
            setOtpBox(false);
            setDisableWhatsAppField(true);
            setVerifiedWAfield(true);
            setShowResendEdit(false);
            localService.toastify(res.info, "success", 1000);
          setVerifiedfield(true)
          setPhoneOtpError(false);
          setIsActiveField({
            ...isActiveField,
            mobile: true
          })
          }else{
            setPhoneOtpError(true);
            setPhoneOtpErrorMsg(res.info);
            // localService.toastify(res.info, "error", 1000);
          }
        })
        .catch((error) => {
          setPhoneOtpVerifyLoading(false);
          throw new Error("VerifyOtp Error ::: " + error);
        });
    }}catch(error){
      console.log('handleWhatsAppOtp error',error);
    }finally{
      setPhoneOtpVerifyLoading(false);
    }
  };

  const handleSendOtpButton = () => {
    setDisableWhatsAppFieldInput(true)
    if (`${whatsappnumber}`.length === 10) {
      setPhoneLoading(true);
      PublicService.sendOtp({ mobile: +whatsappnumber })
        .then((res: any) => {
          if (res.status === gtUtil.Constants.SUCCESS) {
            setShowResendEdit(true);
            setIsResendButtonDisabled(true);
            setTimer(30);
            setIsTimerDisabled(true);
            setIsEditButtonDisabled(true);
            setOtpBox(res.status === gtUtil.Constants.SUCCESS);
            // setVerifiedWAfield(res.status === gtUtil.Constants.SUCCESS);
            setDisableWhatsAppField(res.status === gtUtil.Constants.SUCCESS);

          } 
          else {
            setVerifiedWAfield(false);
            setDisableWhatsAppFieldInput(false)
            setErrorWhatsapp(res.info);
          }
          const checkStatus = (res.status === gtUtil.Constants.SUCCESS) ? "success" : "error";
          localService.toastify(res.info, checkStatus, 1000);
          setPhoneLoading(false);
        })
        .catch((error: any) => {
          setPhoneLoading(false);
          setDisableWhatsAppFieldInput(false)
          setVerifiedWAfield(false);
          console.warn(`Otp Response Error :: ${error}`);
        });
    }
  };
  const handleWhatsApp = (e: any) => {
    try {
      if (e.target.value.length < 10) {
        setWhatsAppNumber((e.target.value));
        setErrorWhatsapp(formValidation.GetMobileControlIsValid(e.target.value));
        setDisableWhatsAppField(true)
      }
      else if (e.target.value.length == 10 && formValidation.GetMobileControlIsValid(e.target.value).status == true) {
        setWhatsAppNumber((e.target.value));
        setErrorWhatsapp(formValidation.GetMobileControlIsValid(e.target.value));
        setDisableWhatsAppField(false)
      }
      else if(e.target.value.length > 10) {
        setWhatsAppNumber(e.target.value.slice(0, 10));
        setErrorWhatsapp(formValidation.GetMobileControlIsValid(e.target.value.slice(0, 10)));

      }
    } catch (error) {
      throw new Error(`handleWhatsApp error :: ${error}`);
    }
  };

  const handleAgency = (e: any) => {
    try {
      const {
        target: { value },
      } = e;
      setErrorCompanyName(formValidation.GetNameValidate(value));
      if(!isActiveField.name){
        setIsActiveField({
          ...isActiveField,
          name: formValidation.GetNameValidate(value).status
        })
      }
    } catch (error) {
      throw new Error("handleCompanyName :: " + error);
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleClickShowConfirmPassword = () => setShowConfirmPassword((showConfirmPassword) => !showConfirmPassword);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleMouseDownConfirmPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  useBodyClass("common-signup-page");

  const handleChangePhone = () => {
    setVerifiedWAfield(false);
    setDisableWhatsAppFieldInput(false)
    setOtpBox(false);
    setShowResendEdit(false);
    if (phoneRef.current) {
      phoneRef.current.focus();
    }
  }

  const handleChangeEmail = () => {
    setVerifiedEmailfield(false);
    setEmailOtpBox(false);
    setShowEmailResendEdit(false);
    setDisableEmailfieldInput(false)
    if (emailRef.current) {
      emailRef.current.focus();
    }
  }
    
  const handlePINChange = (newValue: string) => {
    const filteredValue = newValue.replace(/\D/g, '');
    setOtpPINValue(filteredValue);
    setPin(filteredValue);
  }
  const handleWhatsAppChange = (newValue: string) => {
    const filteredValue = newValue.replace(/\D/g, '');
    setOtpWhatsAppValue(filteredValue);
  }
  const handleEmailChange = (newValue: string) => {
    const filteredValue = newValue.replace(/\D/g, '');
    setOtpEmailValue(filteredValue)
  }
  // Function to check if a value is numeric
  const matchIsNumeric = (text: any) => {
      return !isNaN(Number(text));
  };
  
  // Validation function for MuiOtpInput
  const validateChar = (value: string) => {
      // return matchIsNumeric(value);
      return /^\d*$/.test(value);
  };

  return (
    <>
    <div className="_influencer-container">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12 col-12">
            <p className="_influencer-login-btn">
              Already have an account?{" "}
              <span>
                <NavLink
                  to="/login"
                  className="_influencer-signup-navlink"
                >
                  Login
                </NavLink>
              </span>
            </p>
            <div className="_influencer-signup talent-signup">
              <div className="d-flex flex-wrap align-items-center">
                  <div className="d-flex">
                    <NavLink to="/" className="__navLink __backArrow_icon">
                     <MaterialSymbol iconName="arrow_back" className="_icon d-flex justify-content-center align-items-center" fontweight="200" />
                    </NavLink>
                     <HeadingStyle title="Talent Partner Signup" />
                    </div>
                    <span className="_mandatory ms-auto pt-2 pt-sm-0 ps-2">All fields are mandatory</span>
                    </div>
              <form onSubmit={handleOnSubmit} className="__signup_form">
                <Grid container >
                  <Grid item xs={12} className='mb-3'>
                    <TextField
                      className="w-100 input-common input-style"
                      size="small"
                      id="agency_name_id"
                      onBlur={handleAgency}
                      autoFocus
                      name="agency_name"
                      placeholder="Agency Name"
                      label="Agency Name"
                      variant="outlined"
                    />
                    <span className="error">{errorcompanyname?.message}</span>
                  </Grid>
                  <Grid item xs={12} className='mb-3'>
                    <TextField
                      className="w-100 input-common input-style"
                      size="small"
                      id="full_name_id"
                      inputRef={fullNameRef}
                      disabled={!isActiveField.name}
                      onBlur={handleFullName}
                      name="full_name"
                      placeholder="Your Full Name"
                      label="Full Name"
                      variant="outlined"
                    />
                    <span className="error">{errorFull_name?.message}</span>
                  </Grid>
                  <Grid item xs={12} className="__wa_column mb-3">
                    <TextField
                      className={`w-100 input-style input-common ${verifiedEmail ? "verified-email": ""}`}
                      size="small"
                      id="email_id"
                      disabled={!isActiveField.fullname}
                      value={email} 
                      inputRef={emailRef}
                      onChange={handleEmail}
                      name="email_id"
                      label="Your Email Id"
                      variant="outlined"
                      InputProps={{ readOnly: disableEmailfieldInput }}
                      onPaste={(e:any) => formValidation.GethandlePaste(e, setEmail,"email", (trimmedData) => handleEmail({ target: { value: trimmedData } }))}
                      onKeyDown={(e: any) => formValidation.GetHandleKeyDown(e, setEmail, email, "email")}
                    />
                    
                    {!verifiedEmail &&
                      <LoadingButton
                        className={`__button ${disableEmailField ? "btn-disabled" : ""}`}
                        color="success"
                        loading={emailloading}
                        loadingPosition="start"
                        variant="contained"
                        startIcon={<SendIcon />}
                        onClick={handleEmailSendOTP}
                        disabled={disableEmailField}
                      >
                       Send OTP
                      </LoadingButton>
                    }
                      {verifiedEmail &&
                        <Tooltip className="icon-verified" title="verified" arrow>
                          <IconButton size="small" className="p-0">
                            <MaterialSymbol iconName="verified" fontweight="200" />
                          </IconButton>
                        </Tooltip>
                      }
                    <span className="error">
                      {erroremail?.message.includes("Business") ? (
                        <>
                          {erroremail.message}{' '}
                          If you're an influencer, please sign up as an {' '}
                          <a href="/signup/influencer">influencer</a> 
                          . If you're a talent partner without a business email, feel free to{' '}
                          <a href={`${BASE_URL_WEB}/contact_us`}>contact us</a> 
                          {' '}for further assistance.
                        </>
                      ) : (
                        erroremail?.message
                      )}
                    </span>
                    {showEmailResendEdit &&
                      <div className="resend-otp d-flex align-items-center w-100 mb-2">
                        {isEditButtonEmailDisabled && <p className="change-phone fs-12 btn btn-text-link me-auto my-0" onClick={handleChangeEmail}>Change Email</p>}
                        <div className="resend-otp-container d-flex">
                          <button
                            className="otp-btn fs-12 btn btn-text-link"
                            onClick={handleEmailSendOTP}
                            type="button"
                            disabled={isResendButtonEmailDisabled}
                          >
                            Resend OTP
                          </button>
                          {isEmailTimerDisabled && <p className="otp-time fs-12 my-0">&nbsp; 00:{Emailtimer < 10 ? `0${Emailtimer}` : Emailtimer}</p>}
                        </div>

                      </div>
                    }
                      {showEmailotpBox && 
                        <div className="mobile-verification">
                        <MuiOtpInput
                          className="otp-fields"
                          value={otpEmailValue}
                          onChange={handleEmailChange}
                          length={4}
                          autoFocus
                          TextFieldsProps={{ size: 'small', placeholder: '0', type:"tel"  }}
                          validateChar={validateChar} 
                          />
                          
                          <LoadingButton
                          className="verify-button"
                          loading={emailOtpVerifyloading}
                          variant="contained"
                          color="success"
                          disabled={otpEmailValue.length == 4 ? false : true}
                          onClick={()=>handleEmailOtp(otpEmailValue)}
                        >
                          Verify
                        </LoadingButton>
                        </div>
                          }
                        {emailOtpError && <span className="error">{emailOtpErrorMsg}</span>}   
                  </Grid>
                  <Grid item xs={12} className="__wa_column mb-3">
                    <TextField
                      className="w-100 input-common input-style"
                      type="number"
                      size="small"
                      color="primary"
                      inputRef={phoneRef}
                      disabled={!isActiveField.email}
                      onChange={handleWhatsApp}
                      name="mobile"
                      id="whatsapp_number_id"
                      label="WhatsApp No."
                      value={whatsappnumber}
                      InputProps={{
                      readOnly: disableWhatsappfieldInput,
                      startAdornment: (<InputAdornment position="start"><span className="fs-14 font-weight-normal">+91</span></InputAdornment>),
                      }}
                      variant="outlined"
                      onKeyDown={(e: any) => formValidation.GetHandleKeyDown(e, setWhatsAppNumber, whatsappnumber, 'number', setErrorWhatsapp)}
                      onPaste={(e:any) => formValidation.GethandlePaste(e, setWhatsAppNumber,"phone", (trimmedData) => handleWhatsApp({ target: { value: trimmedData } }))}
                    />
                     {!verifiedfield &&
                    <LoadingButton
                      className={`__button ${disableWhatsappfield ? "btn-disabled" : ""}`}
                      color="success"
                      loading={phoneloading}
                      loadingPosition="start"
                      disabled={disableWhatsappfield}
                      onClick={handleSendOtpButton}
                      variant="contained"
                      startIcon={<WhatsAppIcon />}
                    >
                      Send OTP
                    </LoadingButton>
                    }
                     {verifiedfield && 
                        <Tooltip className="icon-verified" title="verified" arrow>
                          <IconButton size="small" className="p-0">
                            <MaterialSymbol iconName="verified" fontweight="200" />
                          </IconButton>
                        </Tooltip>
                      }
                    <span className="error">{errorwhatsapp?.message}</span>
                    {showResendEdit &&
                      <div className="resend-otp d-flex align-items-center w-100 mb-2">
                        {isEditButtonDisabled && <p className="change-phone fs-12 btn btn-text-link me-auto my-0" onClick={handleChangePhone}>Change Phone</p>}
                        <div className="resend-otp-container d-flex">
                          <button
                            className="otp-btn fs-12 btn btn-text-link"
                            onClick={handleSendOtpButton}
                            type="button"
                            disabled={isResendButtonDisabled}
                          >
                            Resend OTP
                          </button>
                          {isTimerDisabled && <p className="otp-time fs-12 my-0">&nbsp; 00:{timer < 10 ? `0${timer}` : timer}</p>}
                        </div>

                      </div>
                    }
                    {/* {showotpBox && <OtpField handleOtp={handleWhatsAppOtp} />} */}
                    {showotpBox && 
                        <div className="mobile-verification"> 
                        <MuiOtpInput
                        value={otpWhatsAppValue}
                        onChange={handleWhatsAppChange}
                        // onComplete={handleWhatsAppOtp}
                        length={4}
                        autoFocus
                        TextFieldsProps={{ size: 'small', placeholder: '0', type:"tel"  }}
                        validateChar={validateChar} 
                        />
                         
                         <LoadingButton
                          className="verify-button"
                          loading={phoneOtpVerifyloading}
                          variant="contained"
                          color="success"
                          disabled={otpWhatsAppValue.length == 4 ? false : true}
                          onClick={()=>handleWhatsAppOtp(otpWhatsAppValue)}
                        >
                          Verify
                        </LoadingButton>
                        </div>
                          }
                        {phoneOtpError && <span className="error">{phoneOtpErrorMsg}</span>}   
                  </Grid>
                  <Grid item xs={12} className='mb-3'>
                    <FormControl
                      variant="outlined"
                      className="w-100 input-common input-style"
                      size="small"
                      disabled={!isActiveField.mobile}
                    >
                      <InputLabel htmlFor="outlined-adornment-password">
                        Password
                      </InputLabel>
                      <OutlinedInput
                        id="password_id"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        inputRef={passwordRef}
                        onBlur={handlePassword}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                      />
                    </FormControl>
                    <span className="error">{errorPassword?.message}</span>
                  </Grid>

                  <Grid item xs={12} className='mb-3'>
                    <FormControl
                      variant="outlined"
                      className="w-100 input-common"
                      size="small"
                      disabled={!isActiveField.password}
                    >
                      <InputLabel htmlFor="outlined-adornment-password">
                        Confirm Password
                      </InputLabel>
                      <OutlinedInput
                        id="confirm_password_id"
                        type={showConfirmPassword ? "text" : "password"}
                        name="confirm_password"
                        inputRef={confirmPasswordRef}
                        onBlur={handleConfirmPassword}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowConfirmPassword}
                              onMouseDown={handleMouseDownConfirmPassword}
                              edge="end"
                            >
                              {showConfirmPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Confirm Password"
                      />
                    </FormControl>
                    {errorConfirmPassword && !errorConfirmPassword.status && <span className="error">{errorConfirmPassword?.message}</span>}
                    </Grid>

                  <Grid item xs={12}>
                    <div className="row __manually mb-3">
                      <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                        <span style={{ fontSize: "14px" }}> Create Login Pin  </span>
                      </div>
                      <div className="col">
                        <div className="__wa_column">
                          {/* {<OtpField handleOtp={handlePin} />} */}
                          <MuiOtpInput
                            value={otpPINValue}
                            onChange={handlePINChange}
                            onComplete={handlePin}
                            style={{ pointerEvents: isActiveField.confirmPassword ? 'auto' : 'none', opacity: isActiveField.confirmPassword ? 1 : 0.5 }}
                            length={4}
                            TextFieldsProps={{ size: 'small', placeholder: '0', type:"tel"  }}
                            validateChar={validateChar} 
                            />
                        </div>
                        <span className="error">
                          {errorPin?.message}
                        </span>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <ReCAPTCHA
                      sitekey={CAPTCHA_SITE_KEY}
                      onChange={(token: any) => setCaptchaToken(token)}
                    // onChange={onChange}
                    />
                  </Grid>
                  <Grid item xs={12} className="pt-2 mt-2 mb-5" style={{fontSize:'12px'}}>
                    <Checkbox
                      inputProps={{
                        "aria-label": "Checkbox",
                      }}
                      name="terms"
                      value={terms}
                      size="small"
                      className="p-0 pe-2"
                      onChange={(e, v) => setTerms(v)}
                      style={{
                          padding: "0",
                          marginRight: "5px",
                          verticalAlign: "middle",
                        }}
                    />
                    <span>
                      I agree to the{" "}
                      <NavLink
                        to="/terms_condition"
                         className="btn btn-text-link"
                      >
                        {" "}
                        Terms and Conditions
                      </NavLink>{" "}
                      <span className="px-1">and</span> <NavLink
                            to="/privacy_policy"
                            className="btn btn-text-link"
                          >Privacy Policy</NavLink>
                    </span>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    className="text-right d-flex justify-content-end"
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      className="btn btn-primary "
                      disabled={!isActiveField.pin}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
          <div className="col-12 col-lg-6 mt-4 mt-lg-0 _influencer-content-sec">
            <InfluencerContent />
          </div>
        </div>
      </div>
    </div>
    <Footer />
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(null, mapDispatchToProps)(Talent_Patner);
export { connectToRedux as Talent_Patner };
