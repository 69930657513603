import React, { useState, useEffect } from 'react';
import { Card } from '../card/card';
import './shipping-add-card.scss';
import { Button, FormControlLabel, Radio, RadioGroup } from '@mui/material';


interface Props {
    addressList: any;
    setDefaultAddress: (data: any) => void;
    enableEdit: boolean;
    onClickFunction?: (index: number) => void;
    onChange?: (e: any) => void;
    onDelete?: (index: number) => void;
    onEdit?: (index: number) => void;
}

const ShippingAddressCard: React.FC<Props> = (props: Props) => {
    const [selectAddress, setSelectAddress] = useState(0);

    useEffect(() => {
        if (props.addressList) {
            try {
                props.addressList.map((data: any, index: number) => {
                    if (data.default_address === 'true') {
                        setSelectAddress(index);
                    }
                });
            } catch (error) {
                throw new Error(`Shipping address component Error :: ${error}`);
            }
        }
    }, [props.addressList]);

    return (
        <div className='ship-add-container'>
            {props.addressList && (
                <>
                    {/* <RadioGroup
                        aria-labelledby='demo-radio-buttons-group-label'
                        name='radio-buttons-group'
                        className='row'
                        row
                        value={`${selectAddress}`}
                        onChange={(e) => {
                            if (props.onChange) {
                                props.onChange(e);
                            }
                        }}
                    // onClick={(e) => {
                    //     // props.setDefaultAddress(selectAddress);
                    //     if (props.onChange) {
                    //         props.onChange(e);
                    //     }
                    // }}
                    > */}
                    <div className='row'>
                        {props.addressList &&
                            props.addressList.map(
                                (data: any, index: number) => {
                                    return (
                                        <div className='col-md-6 col-lg-4 mb-3'>
                                            <Card
                                                key={index}
                                                className={
                                                    selectAddress === index
                                                        ? 'add-card add-default h-100'
                                                        : 'add-card h-100'
                                                }
                                            >
                                                <div className={selectAddress === index ? 'add-default d-flex flex-wrap' : 'ship-add-content d-flex flex-wrap'}>
                                                    {/* <div
                                                        className='ship-add-checkbox  m-0 p-0'
                                                        data-address_id={data.address_id}
                                                        data-index={index}
                                                    /> */}
                                                    {/* {selectAddress === index && (
                                                        <span className='resi_type'>
                                                            {
                                                                data.full_name
                                                            }
                                                        </span>
                                                    )} */}

                                                </div>
                                                <div className='d-flex justify-content-between align-items-baseline pt-2'>
                                                    <div className={selectAddress === index ? 'ship-add-content' : 'not-default'}>
                                                        <span className='fw-bold pb-2'>{data.full_name}</span>
                                                        <span>{data.house_no}</span>
                                                        <span>{data.landmark}</span>
                                                        <span>{data.area}</span>
                                                        {data.city.trim().toLowerCase() !== data.state.trim().toLowerCase() && (
                                                            <span>{data.city}</span>
                                                        )}
                                                        <span>{data.state}</span>
                                                        <span>{data.pincode}</span>
                                                        <span>{data.country}</span>
                                                        <span>{data.mobile_no}</span>
                                                    </div>
                                                    {selectAddress == index && (
                                                        <span className='resi_type fw-bold'>Default</span>
                                                    )}
                                                </div>

                                                {props.enableEdit && (
                                                    <div className='row px-3'>
                                                        {/* {selectAddress ===
                                                            index && ( */}
                                                        <div className='d-flex justify-content-between mt-2'>
                                                            <div
                                                                className='ship-add-checkbox'
                                                                id={`id_radio_${index}`}
                                                                data-address_id={data.address_id}
                                                                data-index={index}
                                                                onClick={(e) => {
                                                                    if (props.onChange) {
                                                                        props.onChange(e);
                                                                    }
                                                                }}
                                                            >
                                                                {selectAddress !== index && (
                                                                    <span className='setDefault  user-select-none'>Set as Default</span>
                                                                )}

                                                            </div>
                                                            {selectAddress == index && (
                                                                <span className='already_default fw-lighter pt-1'>Set as Default</span>
                                                            )}
                                                            <div className="col d-flex justify-content-end">
                                                                <span onClick={() => {

                                                                    // props.setDefaultAddress(selectAddress);
                                                                    if (props.onEdit) {
                                                                        props.onEdit(index);
                                                                    }

                                                                }} className='material-symbols-rounded edit-icons cursor-pointer'>edit</span>

                                                                <span className='influencer_table_del_icon material-symbols-rounded cursor-pointer'
                                                                    onClick={() => {
                                                                        if (props.onDelete) {
                                                                            props.onDelete(index);
                                                                        }
                                                                    }}
                                                                >
                                                                    delete
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {/* )} */}
                                                    </div>
                                                )}
                                            </Card>
                                        </div>
                                    );
                                }
                            )}
                    </div>
                    {!props.enableEdit && (
                        <div className='footerbtns text-end'>
                            <Button
                                variant='contained'
                                className='btn btn-primary'
                                onClick={() =>
                                    props.setDefaultAddress(selectAddress)
                                }
                            >
                                Send Address
                            </Button>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default ShippingAddressCard;
