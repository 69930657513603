import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { InfluencerProposalChat } from './influencer-chat';
import { createPortal } from 'react-dom';
import ConfirmPopup from '../../../components/confirm-popup/confirm-popup';

import {
    CreateCampaign,
    Loading,
    SelectedCampaign,
} from '../../../redux/action';
import { ListService, LocalService } from '../../../core/services';
import './my-campaign.scss';
import MyCampaignCard from '../../../components/my-campaigns-card/my-campaign-card';
import HeadingStyle from '../../../components/heading-style/heading-style';

interface Props {
    UserReducer: any;
    UserID: any;
    campaignReducer: any;
    loading: (data: any) => void;
    selectedInf?: (data: any) => void;
}

const localService = new LocalService();
const listService: ListService = new ListService();


const InfluencerMyCampaign: React.FC<Props> = (props: Props) => {
    const [campaignList, setCampaignList] = useState([]);
    const [campaign_id, setCampaign_id] = useState({
        campaign_id: 0,
        campaign_platform: "",
    });
    const navigate = useNavigate();
    const [url, setUrl] = useState('');
    const [selectedProposalId, setSelectedProposalId] = useState(0);
    const [popUps, setPopUps] = useState({
        markCompleteConfirmationPopup: false
    });
    const [confirmPopupInnerText, setConfirmPopupInnerText] = useState("Are you sure want to mark complete this campaign?");

    useEffect(() => {
        getSmId();
    }, [campaign_id.campaign_id, url]);

    async function getSmId() {
        try {
            let sm_id = 0;
            if (campaign_id.campaign_id && url) {
                if (campaign_id.campaign_id > 0) {
                    const response: any = await listService.getAppliedSm_id({
                        influencerId: props?.UserReducer?.influencer_id,
                        campaign_id: campaign_id.campaign_id
                    })

                    if (response.status === 1 && response.data.length > 0) {
                        sm_id = response.data[0].sm_id;
                    }
                }
                navigate(`${url}?campaignId=${campaign_id.campaign_id}&smId=${sm_id}`);
            }
        } catch (error) {
            console.log('getSmId Error :: ', error);
        }
    }

    function setCampaignListFunc() {
        ListService.influencer_mycampaign_List({
            influencerId: props?.UserReducer?.influencer_id
        })
            .then((res: any) => {
                if (res.status === 1) {
                    setCampaignList(res.data.rows);
                } else {
                    localService.toastify(res.data.status.message, 'info');
                }
            })
            .catch((error) =>
                console.warn('Influencer campaign List Error :: ' + error)
            )
            .finally(() => props.loading(false));
    }

    useEffect(() => {
        props.loading(true);
        setCampaignListFunc();
    }, [props]);

    function markProposalComplete(proposal_id: number, campaign_title: string) {
        setConfirmPopupInnerText(`Are you sure you want to mark campaign <strong>${campaign_title}</strong> as completed`);
        setSelectedProposalId(proposal_id);
        setPopUps({ ...popUps, markCompleteConfirmationPopup: true })
        document
            .getElementsByTagName('body')[0]
            .classList.add('custom-drawer-active');
    }

    function handleCloseMarkCompletePopup() {
        setPopUps({ ...popUps, markCompleteConfirmationPopup: false })
        document
            .getElementsByTagName('body')[0]
            .classList.remove('custom-drawer-active');
    }

    function updateProposalCompleted(proposal_id: number) {
        props.loading(true);
        ListService.influencer_proposal_mark_complete({
            proposal_id: proposal_id,
        })
            .then((res: any) => {
                if (res.data.status === true) {
                    setCampaignListFunc();
                } else {
                    localService.toastify(res.data.status.message, 'info');
                }
            })
            .catch((error) =>
                console.warn('Influencer updateProposalCompleted Error :: ' + error)
            )
            .finally(() => {
                handleCloseMarkCompletePopup();
            });
    }

    return (
        <div className='dashboard-infl'>
            <div className='container'>
                <div className='d-flex flex-column flex-md-row me-auto mb-3'>
                    <HeadingStyle title='My Campaigns' />
                    {/* <ul className="campaign-legend justify-md-content-end mb-2">
                        <li className="campaign-legend_accepted">Accepted/Approved</li>
                        <li className="campaign-legend_pending">Pending</li>
                        <li className="campaign-legend_rejected">Rejected</li>
                        <li className="campaign-legend_negotiating">Negotiating</li>
                    </ul> */}
                </div>


                <div className='my-campaign-card-container'>
                    {campaignList?.length > 0 &&
                        campaignList?.map((res: any, index) => {
                            return (
                                <MyCampaignCard
                                    key={res.proposal_id}
                                    proposalId={res.proposal_id}
                                    campaignId={res.campaign_id}
                                    completed={res.completed}
                                    completedAt={res.completed_at}
                                    campaignPlatform={res.campaign_platform}
                                    campaignImage={res.campaign_cover_url}
                                    campaignTitle={res.campaign_title}
                                    campaignType={res.campaign_type}
                                    campaignStatus={res.campaign_status}
                                    proposalStatus={res.status}
                                    creativeApproveCount={res?.approved_count}
                                    creativePendingCount={res?.pending_count}
                                    creativeRejectedCount={res?.rejected_count}
                                    AppliedDate={res.created_at}
                                    handleCampaign={(url, campaign_id, campaign_platform) => {
                                        if (url !== '') {
                                            setUrl(url); setCampaign_id({
                                                campaign_id: campaign_id,
                                                campaign_platform: campaign_platform,
                                            })
                                        }
                                    }}
                                    markProposalComplete={markProposalComplete}
                                />
                            );
                        })}
                    {campaignList.length === 0 && (
                        <p className="no-list-comment" style={{ marginTop: "200px" }}>Great opportunities await! Start exploring and apply to your first campaign today.</p>
                    )}
                </div>
            </div>
            <InfluencerProposalChat />

            {createPortal(<ConfirmPopup confirmPopupInnerText={confirmPopupInnerText} openPopup={popUps.markCompleteConfirmationPopup} confirmClosePopup={handleCloseMarkCompletePopup} confirmClickAction={() => updateProposalCompleted(selectedProposalId)} drawerTitle="" />, document.body)}
        </div>
    );
};

const mapStateToProps = (state: any) => {
    return {
        UserReducer: state.UserReducer.UserData,
        campaignReducer: state.CreateCampaignReducer,
        UserID: state.UserID.userID,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        CreateCampaignReducer: (data: any) => dispatch(CreateCampaign(data)),
        loading: (data: any) => dispatch(Loading(data)),
        selectedInf: (data: any) => dispatch(SelectedCampaign(data)),
    };
};

const connectToRedux = connect(
    mapStateToProps,
    mapDispatchToProps
)(InfluencerMyCampaign);

export { connectToRedux as InfluencerMyCampaign };
